import React, { Component } from "react";
import { withTranslate } from "react-redux-multilingual";
import MainHeader from "../components/layouts/header/main_header";
import MainFooter from "./layouts/footer/main_footer";
import NotificationList from "./layouts/notifications/notification-list";
import { Drawer } from "@material-ui/core";
import { connect } from "react-redux";
import { getCommonCounter, getNotificationCounter } from "../actions";
import "react-notifications/lib/notifications.css";
import { NotificationContainer } from "react-notifications";
import ThemeSettings from "./common/theme-settings";
import { withRouter } from "react-router-dom";
import { getValueFromLanguageObject } from "../store";
import "react-image-lightbox/style.css";
import { clearLocalStorageReload } from "../utils/utils";
import { getTenantInfoUrl, getMasterDetailsIdUrl } from "../services";
import { getCall } from "../api/post";

const notificationHeaderStyle = {
  textTransform: "uppercase",
  color: "#222222",
  fontSize: "20px",
  padding: "15px",
  backgroundColor: "#DCDCDC",
  fontWeight: "400",
};

class App extends Component {
  state = {
    isDrawerVisible: false,
    isShowXEModule: false,
    masterDetailsObj: {},
  };

  componentDidMount() {
    document.querySelector(".loader-wrapper-splash").style = "display: none";
    // this.initMasterDetailsId().then(() => {
    //   this.initTenantInfo();
    // });
  }

  // initMasterDetailsId = async () => {
  //   let masterDetailsObj = {};
  //   const publicUrl = window.location.pathname.split("/")[1];
  //   const masterDetailsIdUrl = getMasterDetailsIdUrl(publicUrl);
  //   const masterDetails = await getCall(masterDetailsIdUrl, []).then();
  //   if (masterDetails.error) {
  //     clearLocalStorageReload({ response: masterDetails });
  //   } else {
  //     if (masterDetails) {
  //       masterDetailsObj = masterDetails;
  //     }
  //   }
  //   this.setState({ masterDetailsObj: masterDetailsObj });
  // };

  handleNotificicationDrawer = () => {
    const { isDrawerVisible } = this.state;
    this.setState({ isDrawerVisible: !isDrawerVisible });
  };

  onClickOnSettingsIcon = () => {
    this.props.history.push(`/settings`);
  };

  onCloseofNotificationDrawer = () => {
    this.setState({ isDrawerVisible: false });
    this.props.notificationCounter();
  };

  // initTenantInfo = async () => {

  //   const { masterDetailsObj } = this.state;
  //   const { id } = masterDetailsObj || {};
  //   if (!id) {
  //     return;
  //   }
  //   const tenantInfoUrl = getTenantInfoUrl(id);
  //   const tenantInfo = await getCall(tenantInfoUrl, []);
  //   if (tenantInfo.error) {
  //     clearLocalStorageReload({ response: tenantInfo });
  //     // this.loadErrorMessageAnimation(tenantInfo.error.message);
  //   } else {
  //     if (tenantInfo && tenantInfo.length > 0) {
  //       const tenantItem = tenantInfo[0];
  //       const { text } = tenantItem || {};
  //       const tenantSettings = JSON.parse(text);

  //       if ("isShowXEModule" in tenantSettings) {
  //         this.setState({
  //           isShowXEModule: tenantSettings.isShowXEModule,
  //         });
  //       } else {
  //         this.setState({
  //           isShowXEModule: false,
  //         });
  //       }
  //     }
  //   }
  //   this.setState({ isLoading: true });
  // };

  render() {
    const { isDrawerVisible, isShowXEModule } = this.state;
    return (
      <div>
        <NotificationContainer />
        {/* {!isShowXEModule && ( */}
          <MainHeader
            handleNotificicationDrawer={this.handleNotificicationDrawer}
            onClickOnSettingsIcon={this.onClickOnSettingsIcon}
          />
        {/* )} */}

        <Drawer
          anchor="right"
          open={isDrawerVisible}
          onClose={this.onCloseofNotificationDrawer}
        >
          <h3 style={notificationHeaderStyle}>
            {getValueFromLanguageObject("str_notification")
              ? getValueFromLanguageObject("str_notification")
              : "NOTIFICATIONS"}
          </h3>
          <NotificationList closeDrawer={this.onCloseofNotificationDrawer} />
        </Drawer>
        {this.props.children}
        {/* {!isShowXEModule &&  */}
        <MainFooter />
        {/* } */}

        <ThemeSettings />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, {
  counter: getCommonCounter,
  notificationCounter: getNotificationCounter,
})(withTranslate(withRouter(App)));
