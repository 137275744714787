import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";

import {
  getValueFromLanguageObject,
  getColorCodeBasedOnKey,
  loadUserDetailsFromLocalStorage,
} from "../../../store";
import { isInquiryOrCart } from "../../../actions";
class NavBar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      navClose: { right: "0px", ActiveMenu: "" },
    };
  }

  componentWillMount() {
    if (window.innerWidth < 750) {
      this.setState({ navClose: { right: "-410px" } });
    }
    if (window.innerWidth < 1199) {
      this.setState({ navClose: { right: "-300px" } });
    }
  }

  openNav() {
    this.setState({ navClose: { right: "0px" } });
  }
  closeNav() {
    this.setState({ navClose: { right: "-410px" } });
  }

  onMouseEnterHandler() {
    if (window.innerWidth > 1199) {
      document.querySelector("#main-menu").classList.add("hover-unset");
    }
  }

  // handleSubmenu = (event) => {
  //   if (event.target.classList.contains("sub-arrow")) return;

  //   if (event.target.nextElementSibling.classList.contains("opensubmenu"))
  //     event.target.nextElementSibling.classList.remove("opensubmenu");
  //   else {
  //     document.querySelectorAll(".nav-submenu").forEach(function(value) {
  //       value.classList.remove("opensubmenu");
  //     });
  //     document
  //       .querySelector(".mega-menu-container")
  //       .classList.remove("opensubmenu");
  //     event.target.nextElementSibling.classList.add("opensubmenu");
  //   }
  // };

  handleMegaSubmenu = (event) => {
    if (event.target.classList.contains("sub-arrow")) return;

    if (
      event.target.parentNode.nextElementSibling.classList.contains(
        "opensubmegamenu"
      )
    )
      event.target.parentNode.nextElementSibling.classList.remove(
        "opensubmegamenu"
      );
    else {
      document.querySelectorAll(".menu-content").forEach(function(value) {
        value.classList.remove("opensubmegamenu");
      });
      event.target.parentNode.nextElementSibling.classList.add(
        "opensubmegamenu"
      );
    }
  };

  isCurrentMenu = (path) => {
    let isActive = false;
    const publicUrlDomain = window.location.pathname.split("/")[1];
    const publicUrlURL = window.location.pathname.split("/")[2];
    if (publicUrlDomain === "" && path === "home") {
      isActive = true;
    } else if (publicUrlURL === "" && path === "home") {
      isActive = true;
    } else if (publicUrlDomain === "products" && path === "products") {
      isActive = true;
    } else if (publicUrlDomain === "p" && path === "products") {
      isActive = true;
    } else if (publicUrlDomain === "c" && path === "products") {
      isActive = true;
    } else if (publicUrlDomain === "s" && path === "products") {
      isActive = true;
    } 
    // else if (publicUrlDomain === "collection" && path === "collection") {
    //   isActive = true;
    // } 
    else if (publicUrlDomain === "singleproduct" && path === "products") {
      isActive = true;
    } else if (publicUrlDomain === "contactus" && path === "contactus") {
      isActive = true;
    } else if (publicUrlDomain === "aboutus" && path === "aboutus") {
      isActive = true;
    } else if (publicUrlDomain === "collection" && path === "collection") {
      isActive = true;
    } else if (publicUrlURL === "products" && path === "products") {
      isActive = true;
    } else if (publicUrlURL === "p" && path === "products") {
      isActive = true;
    } else if (publicUrlURL === "c" && path === "products") {
      isActive = true;
    } else if (publicUrlURL === "s" && path === "products") {
      isActive = true;
    } else if (publicUrlURL === "singleproduct" && path === "products") {
      isActive = true;
    } else if (publicUrlURL === "contactus" && path === "contactus") {
      isActive = true;
    } 
    // else if (publicUrlURL === "aboutus" && path === "aboutus") {
    //   isActive = true;
    // } else if (publicUrlURL === "collection" && path === "collection") {
    //   isActive = true;
    // }

    return isActive;
  };

  render() {
    const userDetails = loadUserDetailsFromLocalStorage();
    return (
      <div>
        <div className="">
          <div id="mainnav">
            {/* <div className="toggle-nav" onClick={this.openNav.bind(this)}>
              <i className="fa fa-bars sidebar-bar" />
            </div> */}
            <ul className="nav-menu" style={this.state.navClose}>
              {/* <li className="back-btn" onClick={this.closeNav.bind(this)}>
                <div className="mobile-back text-right">
                  <span>
                    {getValueFromLanguageObject("str_back")
                      ? getValueFromLanguageObject("str_back")
                      : "Back"}
                  </span>
                  <i className="fa fa-angle-right pl-2" aria-hidden="true" />
                </div>
              </li>
              <li>
                <Link
                  to={``}
                  className={`nav-link ${
                    this.isCurrentMenu("home") ? "selected-active" : ""
                  }`}
                >
                  {getValueFromLanguageObject("str_home")
                    ? getValueFromLanguageObject("str_home")
                    : "Home"}
                </Link>
              </li>
              <li>
                <Link
                  to={`/products`}
                  className={`nav-link ${
                    this.isCurrentMenu("products") ? "selected-active" : ""
                  }`}
                >
                  {getValueFromLanguageObject("str_products")
                    ? getValueFromLanguageObject("str_products")
                    : "Products"}
                </Link>
              </li>

              {userDetails && (
                <li>
                  <Link
                    to={`/requestproduct`}
                    className={`nav-link ${
                      this.isCurrentMenu("requestproduct")
                        ? "selected-active"
                        : ""
                    }`}
                    onClick={(e) => this.handleSubmenu(e)}
                  >
                    {getValueFromLanguageObject("str_request_product")
                      ? getValueFromLanguageObject("str_request_product")
                      : "Request Product"}
                  </Link>
                </li>
              )}

              <li
                style={{
                  color: getColorCodeBasedOnKey("text_menu_header"),
                }}
              >
                <Link
                  to={`/collection`}
                  className={`nav-link ${
                    this.isCurrentMenu("collection") ? "selected-active" : ""
                  }`}
                >
                  {getValueFromLanguageObject("str_collections")
                    ? getValueFromLanguageObject("str_collections")
                    : "Collections"}
                </Link>
              </li> */}
            
              <li
                style={{
                  color: getColorCodeBasedOnKey("text_menu_header"),
                }}
              >
                <Link
                  to={`/aboutus`}
                  className={`nav-link ${
                    this.isCurrentMenu("aboutus") ? "selected-active" : ""
                  }`}
                >
                  {getValueFromLanguageObject("str_about_us")
                    ? getValueFromLanguageObject("str_about_us")
                    : "About Us"}
                </Link>
              </li>

              <li>
                <Link
                  to={`/contactus`}
                  className={`nav-link ${
                    this.isCurrentMenu("contactus") ? "selected-active" : ""
                  }`}
                >
                  {getValueFromLanguageObject("str_contact_us")
                    ? getValueFromLanguageObject("str_contact_us")
                    : "Contact Us"}
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  isInquiry: state.settings.isInquiry,
});

export default connect(mapStateToProps, { isInquiryOrCart: isInquiryOrCart })(
  NavBar
);
