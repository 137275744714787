import React, { useEffect, useState, Component } from "react";
import { connect } from "react-redux";
import { setBaseCurrency } from "../../actions";
import { toAbsoluteUrl } from "../xeUtils/xeUtils";
import SVG from "react-inlinesvg";
import { confirmAlert } from "react-confirm-alert";
import ConfirmAlertPopup from "../../Alert/ConfirmAlertPopup";
import LogoutAnim from "../../animation/AnimFiles/LogoutAnim";
import { postCall } from "../../api/post";
import {
  clearLocalStorageReload,
  removeUserDetailAndOtherDetails,
  navigateTo,
  isOpenStoreIdExist,
} from "../../utils/utils";
import ErrorMessageAnimationModal from "../../animation/ErrorMessageAnimationModal";
import SuccessMessageAnimationModal from "../../animation/SuccessMessageAnimationModal";
import {
  loadUserDetailsFromLocalStorage,
  getValueFromLanguageObject,
} from "../../store";
import { getCall } from "../../api/post";
import { Link, withRouter } from "react-router-dom";
import {
  getBaseCurrencyList,
  getMerchantInformationUrl,
  getMasterDetailsIdUrl,
} from "../../services";
import { Helmet } from "react-helmet";

class XeHeader extends Component {
  state = {
    selectedClient: "INR",
    showErrorMessageModal: false,
    errorMessage: "",
    showSuccessMessageModal: false,
    successMessage: "",
    userDetails: {},
    userName: "",
    cellNumber: "",
    baseCurrencyList: [],
    AppTitle: "",
    masterDetailsObj: {},
  };

  componentDidMount = () => {
    const userDetails = loadUserDetailsFromLocalStorage();
    this.initUserDetails().then(() => {
      this.setState({ isLoading: false });
      this.initBaseCurrencyList();
      this.initMasterDetailsId().then(() => {
        this.initMerchantInfo();
      });
    });
  };

  initMasterDetailsId = async () => {
    let masterDetailsObj = {};
    const publicUrl = window.location.pathname.split("/")[1];
    const masterDetailsIdUrl = getMasterDetailsIdUrl(publicUrl);
    const masterDetails = await getCall(masterDetailsIdUrl, []).then();
    if (masterDetails.error) {
      clearLocalStorageReload({ response: masterDetails });
    } else {
      if (masterDetails) {
        masterDetailsObj = masterDetails;
      }
    }
    this.setState({ masterDetailsObj: masterDetailsObj });
  };

  initMerchantInfo = async () => {
    const { masterDetailsObj } = this.state;
    const merchantInfoUrl = getMerchantInformationUrl(masterDetailsObj);
    const merchantInfo = await getCall(merchantInfoUrl, []);
    if (merchantInfo.error) {
      clearLocalStorageReload({ response: merchantInfo });
      this.setState({
        AppTitle: "",
      });
    } else {
      this.setState({
        AppTitle: merchantInfo.companyname,
      });
    }
  };

  handleSelectChange = (event) => {
    const selectedOptions = event.target.value;
    this.setState({ selectedClient: selectedOptions });
    this.props.setBaseCurrency(selectedOptions);
  };

  logout = async () => {
    const logoutUrl = "users/logout";
    const logOut = await postCall(logoutUrl, {});

    if (logOut.error) {
      clearLocalStorageReload({ response: logOut });
      this.loadErrorMessageAnimation(logOut.error.message);
    } else {
      removeUserDetailAndOtherDetails();
      navigateTo(this.props, `/login`);
      this.loadSuccessMessageAnimation("You are successfully logged out!");
      setTimeout(() => {
        window.location.reload();
      }, 700);
    }
  };

  onClickOfLogOut = async () => {
    const submitClick = (props) => {
      this.setState({ isLoading: true }, () => {
        this.logout().then(() => {
          this.setState({ isLoading: false });
        });
        // onClose();
      });
    };
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <>
            <ConfirmAlertPopup
              title={"Log Out"}
              message={"Are you sure you want to logout?"}
              animation={<LogoutAnim />}
              submit={"Yes, Logout"}
              submitClick={submitClick}
              cancelClick={onClose}
              cancel={"No"}
            />
          </>
        );
      },
    });
  };

  initUserDetails = async () => {
    const userDetails = loadUserDetailsFromLocalStorage();
    if (userDetails === undefined) {
      navigateTo(this.props, `/login`);
      return;
    }
    const userDetailUrl = `users/${userDetails.id}`;
    const userDetailsResponse = await getCall(userDetailUrl, []);
    if (userDetailsResponse.error) {
      clearLocalStorageReload({ response: userDetailsResponse });
      this.loadErrorMessageAnimation(userDetailsResponse.error.message);
      if (userDetailsResponse.error.statusCode === 401) {
        removeUserDetailAndOtherDetails();
        navigateTo(this.props, `/login`);
        return;
      }
    } else {
      this.setState({
        userDetails: userDetailsResponse,
        userName: userDetailsResponse.username,
        cellNumber: userDetailsResponse.cellnumber,
      });
    }
  };

  initBaseCurrencyList = async () => {
    const baseCurrencyListUrl = getBaseCurrencyList();
    const baseCurrencyListResponse = await getCall(baseCurrencyListUrl, []);
    if (baseCurrencyListResponse.error) {
      clearLocalStorageReload({ response: baseCurrencyListResponse });
    }
    this.setState({ baseCurrencyList: baseCurrencyListResponse });
  };

  loadErrorMessageAnimation = (message) => {
    this.setState({ errorMessage: message, showErrorMessageModal: true });
  };

  loadSuccessMessageAnimation = (message) => {
    this.setState({ successMessage: message, showSuccessMessageModal: true });
  };

  render() {
    const {
      selectedClient,
      showErrorMessageModal,
      errorMessage,
      showSuccessMessageModal,
      successMessage,
      userName,
      cellNumber,
      baseCurrencyList,
      AppTitle,
    } = this.state;
    return (
      <>
        <Helmet>
          <title>
            {getValueFromLanguageObject("str_welcome_to")
              ? getValueFromLanguageObject("str_welcome_to")
              : "Welcome To"}{" "}
            {AppTitle}
          </title>
        </Helmet>
        <div className="xe-header pt-3 pb-3 pt-lg-0 pb-lg-0 d-flex flex-column">
          {/* begin:mobile */}
          <div className="container d-md-none d-flex align-items-center flex-grow-1 justify-content-between">
            <a href="#">
              <img src={toAbsoluteUrl("/media/logos/logo-1-dark.svg")}></img>
            </a>
            <button className="btn btn-primary">Sign Out</button>
          </div>
          {/* end:mobile */}
          {/* Begin:Desktop */}
          <div className="container header-container container-xxl flex-grow-1 d-block d-md-flex align-items-center justify-content-between flex-wrap">
            <div className="d-block d-md-flex flex-row align-items-stretch justify-content-between flex-lg-grow-1">
              {/* begin:left side */}
              <div className="d-flex align-items-stretch profile-menu">
                <div className="header-menu align-items-stretch">
                  <div className="page-title d-flex flex-column align-items-start justify-content-center flex-wrap me-lg-20 py-3 py-lg-0 me-3">
                    {/* <a href="#" className="mb-2"> */}
                    <div
                      onClick={() => {
                        navigateTo(this.props, "/dashboard");
                      }}
                    >
                      <img
                        src={toAbsoluteUrl("/media/logos/logo-1-dark.svg")}
                      ></img>
                    </div>
                    {/* </a> */}
                    <div className="d-flex flex-column text-dark font-weight-bolde my-1">
                      <span className="text-white h3 font-weight-bold fs-3 mb-0">
                        Dashboard
                      </span>
                      <small className="text-gray-600 fs-8 fw-normal pt-2">
                        Currency Exchange
                      </small>
                    </div>
                  </div>
                </div>
              </div>
              {/* end:left side */}
              {/* begin:Right side */}
              <div className="d-md-flex align-items-stretch flex-shrink-0">
                <div className="d-flex justify-content-between align-items-end py-3 py-lg-0 flex-row flex-md-row-reverse">
                  {/* signout button */}
                  <button
                    className="btn btn-primary d-none d-md-flex"
                    onClick={() => {
                      this.onClickOfLogOut();
                    }}
                  >
                    Sign Out
                  </button>
                  {/* profilename */}
                  <div className="d-flex align-items-center mr-3 ml-0 ml-md-4 mb-3 mb-md-0">
                    <div className="d-flex align-items-start flex-column">
                      <span className="text-white font-weight-bold fs-4">
                        {userName ? userName : ""}
                      </span>
                      <small className="text-gray-600">
                        {cellNumber ? cellNumber : "+91 9825098250"}
                      </small>
                    </div>
                  </div>
                  {/* base currency dropdown */}
                  <div className="align-items-center me-md-5 notlast-child">
                    <div className="d-flex align-items-center flex-row">
                      <small className="text-white ml-2 mr-2 my-0 fs-7">
                        Base Currency
                      </small>
                      <select
                        className="base-currency form-control form-select-transparent"
                        data-kt-select2="true"
                        data-placeholder="Select option"
                        data-allow-clear="true"
                        aria-label="Select example"
                        defaultValue={"INR"}
                        value={selectedClient}
                        onChange={(event) => this.handleSelectChange(event)}
                      >
                        {baseCurrencyList.map((baseCurency, index) => (
                          <>
                            {baseCurency.currencyStatus && (
                              <option
                                key={index}
                                value={baseCurency.currencyLabel}
                              >
                                {baseCurency.currencyLabel}
                              </option>
                            )}
                          </>
                        ))}
                      </select>
                    </div>
                  </div>
                  {/*  */}
                </div>
              </div>
              {/* end:Right side */}
            </div>
          </div>
          {/* End:Desktop */}
          {/*  */}
          <div
            className="header-offset d-none d-sm-flex"
            style={{ minHeight: "70px" }}
          ></div>
        </div>
        {showErrorMessageModal && (
          <ErrorMessageAnimationModal
            show={showErrorMessageModal}
            message={errorMessage}
            onClose={() => {
              this.setState({ showErrorMessageModal: false });
            }}
          />
        )}
        {showSuccessMessageModal && (
          <SuccessMessageAnimationModal
            show={showSuccessMessageModal}
            message={successMessage}
            onClose={() => {
              this.setState({ showSuccessMessageModal: false });
            }}
          />
        )}
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  selectedBaseCurrency: state.settings.baseCurrency,
});
export default connect(mapStateToProps, {
  setBaseCurrency: setBaseCurrency,
})(withRouter(XeHeader));
