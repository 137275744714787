import React, { Component } from "react";
import Breadcrumb from "../../common/breadcrumb";
import { getCall, postCall } from "../../../api/post";
import {
  loadUserDetailsFromLocalStorage,
  getValueFromLanguageObject,
  getColorCodeBasedOnKey,
} from "../../../store";
import { getNotificationCounter } from "../../../actions";
import { connect } from "react-redux";
import {
  getMasterDetailsIdUrl,
  getMerchantInformationUrl,
} from "../../../services";
import { Helmet } from "react-helmet";
import MainLoader from "../../../animation/MainLoader";
import ErrorMessageAnimationModal from "../../../animation/ErrorMessageAnimationModal";
import SuccessMessageAnimationModal from "../../../animation/SuccessMessageAnimationModal";
import {
  clearLocalStorageReload,
  isOpenStoreIdExist,
  navigateTo,
  removeUserDetailAndOtherDetails,
} from "../../../utils/utils";
import { withRouter } from "react-router-dom";

class ContactUs extends Component {
  state = {
    contactUs: {},
    isLoading: true,
    AppTitle: "",
    showErrorMessageModal: false,
    errorMessage: "",
    masterDetailsObj: {},
    userName: "",
    email: "",
    contactNumber: "",
    inquiryMsg: "",
    showSuccessMessageModal: false,
    successMessage: "",
  };
  componentDidMount() {
    const userDetails = loadUserDetailsFromLocalStorage();
    if (!isOpenStoreIdExist() && userDetails === undefined) {
      navigateTo(this.props, `/login`);
      return;
    }

    this.initMasterDetailsId().then(() => {
      this.initMerchantInfo();
      this.initContactUs().then(() => {
        this.setState({ isLoading: false });
      });
      this.props.notificationCounter();
    });
  }

  initMasterDetailsId = async () => {
    let masterDetailsObj = {};
    const publicUrl = window.location.pathname.split("/")[1];
    const masterDetailsIdUrl = getMasterDetailsIdUrl(publicUrl);
    const masterDetails = await getCall(masterDetailsIdUrl, []).then();
    if (masterDetails.error) {
      clearLocalStorageReload({ response: masterDetails });
    } else {
      if (masterDetails) {
        masterDetailsObj = masterDetails;
      }
    }
    this.setState({ masterDetailsObj: masterDetailsObj });
  };

  initContactUs = async () => {
    const { masterDetailsObj } = this.state;
    const userDetails = loadUserDetailsFromLocalStorage();
    if (!isOpenStoreIdExist() && userDetails === undefined) {
      navigateTo(this.props, `/login`);
      return;
    }

    const contantusUrl = getMerchantInformationUrl(masterDetailsObj);
    const contactUs = await getCall(contantusUrl, []);
    if (contactUs.error) {
      clearLocalStorageReload({ response: contactUs });
      this.loadErrorMessageAnimation(contactUs.error.message);
      if (contactUs.error.statusCode === 401) {
        removeUserDetailAndOtherDetails();
        navigateTo(this.props, `/login`);
        return;
      }
    } else {
      this.setState({ contactUs });
    }
  };

  initMerchantInfo = async () => {
    const { masterDetailsObj } = this.state;
    const merchantInfoUrl = getMerchantInformationUrl(masterDetailsObj);
    const merchantInfo = await getCall(merchantInfoUrl, []);
    if (merchantInfo.error) {
      clearLocalStorageReload({ response: merchantInfo });
      this.setState({
        AppTitle: "",
      });
    } else {
      this.setState({
        AppTitle: merchantInfo.companyname,
      });
    }
  };

  loadErrorMessageAnimation = (message) => {
    this.setState({ errorMessage: message, showErrorMessageModal: true });
  };

  loadSuccessMessageAnimation = (message) => {
    this.setState({ successMessage: message, showSuccessMessageModal: true });
  };

  handleChangeOfUserName = (event) => {
    this.setState({ userName: event.target.value });
  };
  handleChangeOfEmail = (event) => {
    this.setState({ email: event.target.value });
  };
  handleChangeOfinquiryMsg = (event) => {
    this.setState({ inquiryMsg: event.target.value });
  };

  onClickOfSubmit = () => {
    const { email, inquiryMsg } = this.state;
    if (email.length === 0) {
      this.loadErrorMessageAnimation("Please enter email address");
      return;
    }

    if (email.length > 0) {
      const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (!re.test(email)) {
        this.loadErrorMessageAnimation(
          getValueFromLanguageObject("str_please_enter_valid_email_address")
            ? getValueFromLanguageObject("str_please_enter_valid_email_address")
            : "Please Enter Valid Email Address"
        );
        return;
      }
    }

    if (inquiryMsg.length === 0) {
      this.loadErrorMessageAnimation("Please enter description");
      return;
    }

    this.setState({ isLoading: true }, async () => {
      const contactUsFeedback = await postCall("feedbacks", {
        description: inquiryMsg,
        email: email,
      });
      this.setState({ isLoading: false });
      if (contactUsFeedback.error) {
        clearLocalStorageReload({ response: contactUsFeedback });
        this.loadErrorMessageAnimation(contactUsFeedback.error.message);
        if (contactUsFeedback.error.statusCode === 401) {
          removeUserDetailAndOtherDetails();
          navigateTo(this.props, `/login`);
          return;
        }
      } else {
        this.loadSuccessMessageAnimation(
          getValueFromLanguageObject(
            "thank_you_for_ your_message_You_will_ receive_an_email_response_by_our_team"
          )
            ? getValueFromLanguageObject(
                "thank_you_for_ your_message_You_will_ receive_an_email_response_by_our_team"
              )
            : "Thank you for your message. You will receive an email response by our team"
        );
        this.setState({ email: "", inquiryMsg: "" });
      }
    });
  };

  render() {
    const {
      contactUs,
      isLoading,
      AppTitle,
      showErrorMessageModal,
      errorMessage,
      showSuccessMessageModal,
      successMessage,
    } = this.state;
    return (
      <>
        <div>
          <Helmet>
            <title>
              {AppTitle} |{" "}
              {getValueFromLanguageObject("str_contact_us")
                ? getValueFromLanguageObject("str_contact_us")
                : "Contact Us"}
            </title>
            <meta
              name="description"
              content="Multikart – Multipurpose eCommerce React Template is a multi-use React template. It is designed to go well with multi-purpose websites. Multikart Bootstrap 4 Template will help you run multiple businesses."
            />
          </Helmet>
          <Breadcrumb
            title={
              getValueFromLanguageObject("str_contact_us")
                ? getValueFromLanguageObject("str_contact_us")
                : "Contact Us"
            }
          />

          <section className=" contact-page section-b-space">
            <div className="container">
              <h2 className="mb-4">
                {" "}
                {getValueFromLanguageObject("str_how_can_we_help_you")
                  ? getValueFromLanguageObject("str_how_can_we_help_you")
                  : "HOW CAN WE HELP YOU?"}
              </h2>
              <div className="row section-b-space">
                <div
                  className="col-lg-7 map"
                  style={{ border: "2px solid #ddd" }}
                >
                  <form className="theme-form" style={{ margin: "20px" }}>
                    <div className="form-row">
                      <div className="col-md-12">
                        <label htmlFor="emailId">
                          {getValueFromLanguageObject("str_email_address")
                            ? getValueFromLanguageObject("str_email_address")
                            : "Email Address"}
                        </label>
                        <input
                          type="email"
                          className="form-control"
                          id="emailId"
                          placeholder={
                            getValueFromLanguageObject(
                              "str_enter_email_address"
                            )
                              ? getValueFromLanguageObject(
                                  "str_enter_email_address"
                                )
                              : "Enter Email Address"
                          }
                          minLength="5"
                          pattern="/^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/"
                          maxLength="64"
                          value={this.state.email}
                          onChange={this.handleChangeOfEmail}
                        />
                      </div>
                      <div className="col-md-12">
                        <label htmlFor="inquiryMsg">
                          {getValueFromLanguageObject("leave_your_message")
                            ? getValueFromLanguageObject("leave_your_message")
                            : "Leave Your Message"}
                        </label>

                        <textarea
                          // type="email"
                          className="form-control"
                          id="inquiryMsg"
                          placeholder={
                            getValueFromLanguageObject("str_enter_your_message")
                              ? getValueFromLanguageObject(
                                  "str_enter_your_message"
                                )
                              : "Enter Your Message"
                          }
                          rows="6"
                          minLength="5"
                          maxLength="512"
                          value={this.state.inquiryMsg}
                          onChange={this.handleChangeOfinquiryMsg}
                        />
                      </div>
                    </div>

                    <div className="form-row">
                      <div
                        onClick={() => {
                          this.onClickOfSubmit();
                        }}
                        className="btn btn-solid"
                      >
                        {getValueFromLanguageObject("submit")
                          ? getValueFromLanguageObject("submit")
                          : "Submit"}
                      </div>
                    </div>
                  </form>
                </div>

                <div className="col-lg-5">
                  <div className="contact-right">
                    <ul>
                      {contactUs.cellnumber && (
                        <li>
                          <div className="contact-icon-content">
                            <i className="fa fa-phone"></i>
                            <h6>
                              {getValueFromLanguageObject("str_contact_us")
                                ? getValueFromLanguageObject("str_contact_us")
                                : "Contact Us"}
                            </h6>
                            <p>{contactUs.cellnumber}</p>
                          </div>
                        </li>
                      )}

                      {contactUs.address1 && (
                        <li>
                          <div className="contact-icon-content">
                            <i
                              className="fa fa-map-marker"
                              aria-hidden="true"
                            />
                            <h6>
                              {getValueFromLanguageObject("str_address")
                                ? getValueFromLanguageObject("str_address")
                                : "Address"}
                            </h6>
                            <p>{contactUs.address1}</p>
                            <p>{contactUs.address2}</p>
                          </div>
                        </li>
                      )}

                      {contactUs.email && (
                        <li>
                          <div className="contact-icon-content">
                          <i className="fa fa-envelope" aria-hidden="true"></i>
                            <h6>
                              {getValueFromLanguageObject("str_email")
                                ? getValueFromLanguageObject("str_email")
                                : "Email"}
                            </h6>
                            <p>{contactUs.email}</p>{" "}
                          </div>
                        </li>
                      )}

                      {contactUs.bankdetails && contactUs.bankdetails.isShow && (
                        <li>
                          <div className="contact-icon-content">
                            <i className="fa fa-bank" aria-hidden="true" />
                            <h6>Bank Details</h6>

                            {contactUs.bankdetails.accountnumber && (
                              <p>
                                A/c No :{" "}
                                <span>
                                  {contactUs.bankdetails.accountnumber}
                                </span>
                              </p>
                            )}
                            {contactUs.bankdetails.accountholdername && (
                              <p>
                                A/c Holder Name :{" "}
                                {contactUs.bankdetails.accountholdername}
                              </p>
                            )}
                          </div>
                        </li>
                      )}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </section>
          {isLoading && <MainLoader />}
        </div>
        {showErrorMessageModal && (
          <ErrorMessageAnimationModal
            show={showErrorMessageModal}
            message={errorMessage}
            onClose={() => {
              this.setState({ showErrorMessageModal: false });
            }}
          />
        )}
        {showSuccessMessageModal && (
          <SuccessMessageAnimationModal
            show={showSuccessMessageModal}
            message={successMessage}
            onClose={() => {
              this.setState({ showSuccessMessageModal: false });
            }}
          />
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => ({});

export default connect(
  mapStateToProps,
  {
    notificationCounter: getNotificationCounter,
  }
)(withRouter(ContactUs));
