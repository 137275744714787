/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import DashboardPage from "./DashboardPage";
import { Footer } from "../components/Footer";
import XeHeader from "../header/XeHeader";

const DashboardWrapper = () => {
  return (
    <>
      <XeHeader />
      <DashboardPage />
      <Footer />
    </>
  );
};
export default DashboardWrapper;
