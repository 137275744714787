import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { withTranslate } from "react-redux-multilingual";
import {
  loadUserDetailsFromLocalStorage,
  getValueFromLanguageObject,
  getColorCodeBasedOnKey,
  getCurrentLanguage,
  setCurrentLanguage,
  setCurrencySymbol,
} from "../../../store";
import { postCall, getCall } from "../../../api/post";
import { confirmAlert } from "react-confirm-alert";
import {
  getMerchantInformationUrl,
  getMasterDetailsIdUrl,
  getTenantInfoUrl,
  getProductSearchUrl,
} from "../../../services";
import {
  changeCurrency,
  isInquiryOrCart,
  isUserAllowedToRegister,
} from "../../../actions";
import { connect } from "react-redux";
import ConfirmAlertPopup from "../../../Alert/ConfirmAlertPopup";
import ErrorMessageAnimationModal from "../../../animation/ErrorMessageAnimationModal";
import SuccessMessageAnimationModal from "../../../animation/SuccessMessageAnimationModal";
import LogoutAnim from "../../../animation/AnimFiles/LogoutAnim";
import {
  clearLocalStorageReload,
  isOpenStoreIdExist,
  navigateTo,
  removeUserDetailAndOtherDetails,
} from "../../../utils/utils";
import CartContainer from "../../../containers/CartContainer";
import LogoImage from "../header/logo";

const imageStyle = {
  padding: "10px",
  height: "45px",
  width: "45px",
};

const pStyle = {
  display: "contents",
  textAlign: "center",
  fontSize: "20px",
  padding: "30px",
};

class TopBar extends Component {
  constructor(props) {
    super(props);
    this.timeout = 0;
  }
  state = {
    isMyAccountVisible: false,
    contactUs: {},
    AppTitle: "",
    showErrorMessageModal: false,
    errorMessage: "",
    showSuccessMessageModal: false,
    successMessage: "",
    masterDetailsObj: {},
    isShowChangeLanguageModalPopUp: false,
    divName: getCurrentLanguage() === "ar" ? "RTL" : "LTR",
    currentLanguage: getCurrentLanguage(),
    isSearchOptionVisible: false,
    productList: null,
  };

  componentDidMount() {
    const userDetails = loadUserDetailsFromLocalStorage();
    if (userDetails === undefined) {
      this.setState({ isMyAccountVisible: true });
    } else {
      this.setState({ isMyAccountVisible: false });
    }
    if (this.state.currentLanguage === "ar") {
      document.body.classList.add("rtl");
    } else {
      document.body.classList.remove("rtl");
    }
    // const userDetails = loadUserDetailsFromLocalStorage();
    this.setState({ userDetails });
    this.initMasterDetailsId().then(() => {
      const { masterDetailsObj } = this.state;
      this.initContactUs();
      this.initTenantInfo();
      this.props.isUserAllowedToRegister(masterDetailsObj);
    });
  }

  initMasterDetailsId = async () => {
    let masterDetailsObj = {};
    const publicUrl = window.location.pathname.split("/")[1];
    const masterDetailsIdUrl = getMasterDetailsIdUrl(publicUrl);
    const masterDetails = await getCall(masterDetailsIdUrl, []).then();
    if (masterDetails.error) {
      clearLocalStorageReload({ response: masterDetails });
    } else {
      if (masterDetails) {
        masterDetailsObj = masterDetails;
      }
    }
    this.setState({ masterDetailsObj: masterDetailsObj });
  };

  initTenantInfo = async () => {
    const { masterDetailsObj } = this.state;
    const { id } = masterDetailsObj || {};
    if (!id) {
      return;
    }
    const tenantInfoUrl = getTenantInfoUrl(id);
    const tenantInfo = await getCall(tenantInfoUrl, []);
    if (tenantInfo.error) {
      clearLocalStorageReload({ response: tenantInfo });
      this.loadErrorMessageAnimation(tenantInfo.error.message);
    } else {
      const [tenantConfig] = tenantInfo || [];
      const { text } = tenantConfig || {};
      let config = JSON.parse(text);
      const { isShowChangeLanguageModalPopUp } = config || {};
      this.setState({ isShowChangeLanguageModalPopUp });
    }
  };

  onClickOfMyProfile = () => {
    this.props.history.push("/myprofile");
  };

  ChangeRtl(divName) {
    if (divName === "RTL") {
      document.body.classList.add("rtl");
      this.setState({ divName: "LTR" });
    } else {
      document.body.classList.remove("rtl");
      this.setState({ divName: "RTL" });
    }
  }

  setLanguage = (locale) => {
    setCurrentLanguage(locale);
    setCurrencySymbol();
    if (locale === "ar") {
      changeCurrency("د.إ");
    } else if (locale === "zh") {
      changeCurrency("¥");
    } else {
      changeCurrency("₹");
    }

    if (locale === "ar") {
      this.ChangeRtl("RTL");
    } else {
      this.ChangeRtl("LTR");
    }
    this.setState({ currentLanguage: locale });

    window.location.reload();
  };

  onClickOfLanguageButton = () => {
    const { currentLanguage } = this.state;
    const border = {
      border: "2px #000 solid",
      background: "#F0F0F0",
    };
    const noBorder = { border: "0" };
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div>
            <i
              style={{
                float: "right",
                paddingTop: "10px",
                paddingRight: "10px",
              }}
              onClick={onClose}
              className="fa fa-times fa-2x modal-close__icon"
            />
            <div style={{ padding: "40px" }}>
              <h3>
                {" "}
                {getValueFromLanguageObject("str_language_selection")
                  ? getValueFromLanguageObject("str_language_selection")
                  : "Language Selection"}
              </h3>
              <h4>
                {" "}
                {getValueFromLanguageObject("str_choose_your_language")
                  ? getValueFromLanguageObject("str_choose_your_language")
                  : "Choose Your Language"}{" "}
              </h4>
              <br />
              <div
                className="english-language"
                style={currentLanguage === "en" ? border : noBorder}
                onClick={() => {
                  this.setLanguage("en");
                  onClose();
                }}
              >
                <img
                  style={imageStyle}
                  src={`${
                    process.env.PUBLIC_URL
                  }/assets/images/united-states.png`}
                  alt=""
                />
                <p style={pStyle}>English</p>
              </div>
              <br />
              <div
                className="arabic-language"
                style={currentLanguage === "ar" ? border : noBorder}
                onClick={() => {
                  this.setLanguage("ar");
                  onClose();
                }}
              >
                <img
                  style={imageStyle}
                  src={`${
                    process.env.PUBLIC_URL
                  }/assets/images/united-arab-emirates.png`}
                  alt=""
                />
                <p style={pStyle}>Arabic</p>
              </div>
              <br />
              {/* <div
                style={currentLanguage === "zh" ? border : noBorder}
                onClick={() => {
                  this.setLanguage("zh");
                  onClose();
                }}
              >
                <img
                  style={imageStyle}
                  src={`${process.env.PUBLIC_URL}/assets/images/china.png`}
                  alt=""
                />
                <p style={pStyle}>Chinese(中文)</p>
              </div> */}
              {/* <br />
              <br />
              <button className="btn btn-solid" onClick={onClose}>
                {getValueFromLanguageObject("str_close")
                  ? getValueFromLanguageObject("str_close")
                  : "Close"}
              </button> */}
            </div>
          </div>
        );
      },
    });
  };

  onClickOfLogOut = async () => {
    const submitClick = (props) => {
      this.setState({ isLoading: true }, () => {
        this.logout().then(() => {
          this.setState({ isLoading: false });
        });
        // onClose();
      });
    };
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <>
            <ConfirmAlertPopup
              title={
                getValueFromLanguageObject("str_logout")
                  ? getValueFromLanguageObject("str_logout")
                  : "Log Out"
              }
              message={
                getValueFromLanguageObject("msg_logout")
                  ? getValueFromLanguageObject("msg_logout")
                  : "Are you sure you want to logout?"
              }
              animation={<LogoutAnim />}
              submit={
                getValueFromLanguageObject("str_yes_logout")
                  ? getValueFromLanguageObject("str_yes_logout")
                  : "Yes, Logout"
              }
              submitClick={submitClick}
              cancelClick={onClose}
              cancel={
                getValueFromLanguageObject("str_no")
                  ? getValueFromLanguageObject("str_no")
                  : "No"
              }
            />
          </>
        );
      },
    });
  };

  logout = async () => {
    const logoutUrl = "users/logout";
    const logOut = await postCall(logoutUrl, {});

    if (logOut.error) {
      clearLocalStorageReload({ response: logOut });
      this.loadErrorMessageAnimation(logOut.error.message);
    } else {
      removeUserDetailAndOtherDetails();
      this.loadSuccessMessageAnimation(
        getValueFromLanguageObject("str_you_are_successfully_logged_out")
          ? getValueFromLanguageObject("str_you_are_successfully_logged_out")
          : "You are successfully logged out!"
      );
      setTimeout(() => {
        this.props.history.push("/login");
        window.location.reload();
      }, 100);
    }
  };

  initContactUs = async () => {
    const { masterDetailsObj } = this.state;
    const contantusUrl = getMerchantInformationUrl(masterDetailsObj);
    const contactUs = await getCall(contantusUrl, []);
    if (contactUs.error) {
      clearLocalStorageReload({ response: contactUs });
      this.loadErrorMessageAnimation(contactUs.error.message);
      if (contactUs.error.statusCode === 401) {
        removeUserDetailAndOtherDetails();
        // this.props.history.push("/login");
        return;
      }
    } else {
      this.setState({ contactUs, AppTitle: contactUs.companyname });
    }
  };

  loadErrorMessageAnimation = (message) => {
    this.setState({ errorMessage: message, showErrorMessageModal: true });
  };

  loadSuccessMessageAnimation = (message) => {
    this.setState({ successMessage: message, showSuccessMessageModal: true });
  };

  renderNotificationIcon = () => {
    const {
      notificationCounter,
      handleNotificicationDrawer,
      isInquiry,
    } = this.props;

    return (
      <li
        className={`cart_sign_in mobile-menu mobile-notification ${
          !isInquiry ? "" : "hide-settings-notification"
        }`}
        onClick={() => {
          handleNotificicationDrawer();
        }}
      >
        <div>
          {/* <img
            src={`${process.env.PUBLIC_URL}/assets/images/icon/bell.png`}
            className="img-fluid"
            alt=""
          /> */}
          <i class="fa fa-bell" aria-hidden="true" />
        </div>
        <div className="cart-qty-cls">{notificationCounter}</div>
      </li>
    );
  };

  renderSearchBar = () => {
    const { isProductListDropDownVisible, productList, searchValue } =
      this.state || {};
    // let isSearchOptionVisible = false;
    // const pageNameFromUrl = window.location.pathname.split("/")[2];
    // if (pageNameFromUrl === "") {
    //   isSearchOptionVisible = true;
    // } else if (pageNameFromUrl === "products") {
    //   isSearchOptionVisible = true;
    // } else if (pageNameFromUrl === "requestproduct") {
    //   isSearchOptionVisible = true;
    // } else if (pageNameFromUrl === "collection") {
    //   isSearchOptionVisible = true;
    // } else if (pageNameFromUrl === "cart") {
    //   isSearchOptionVisible = true;
    // }
    return (
      <>
        {/* {isSearchOptionVisible && ( */}
        <div class="search_bar_top">
          <div class="header_search_bar">
            <div className="search-bar-group">
              <span className="search-addon">
                <i class="fa fa-search" aria-hidden="true" />
              </span>
              <input
                class="form-control mr-sm-2"
                type="What are you looking for?"
                placeholder={
                  getValueFromLanguageObject(
                    "str_which_product_you_are_looking_for"
                  )
                    ? getValueFromLanguageObject(
                        "str_which_product_you_are_looking_for"
                      )
                    : "which product you are looking for?"
                }
                aria-label="What are you looking for?"
                value={searchValue}
                onChange={(e) => {
                  this.setState({ productList: null });
                  this.onSearchProduct(e.target.value);
                }}
              />
            </div>
            {searchValue && productList && isProductListDropDownVisible && (
              <div className="typehead-search">
                <div className="inner-search">
                  {productList &&
                    productList.map((product, index) => (
                      <Link
                        to={`/singleproduct/${product.id}`}
                        onClick={() => {
                          this.setState({
                            isProductListDropDownVisible: false,
                            searchValue: product.name,
                          });
                        }}
                      >
                        <div className="search-item">{product.name}</div>
                      </Link>
                    ))}
                </div>
              </div>
            )}
            {searchValue &&
              productList &&
              productList.length === 0 &&
              !isProductListDropDownVisible && (
                <div className="typehead-search">
                  <div className="inner-search">
                    <div className="search-item">
                      {getValueFromLanguageObject("str_no_products_found")
                        ? getValueFromLanguageObject("str_no_products_found")
                        : "No Products Found"}
                    </div>
                  </div>
                </div>
              )}
          </div>
        </div>
        {/* )} */}
      </>
    );
  };

  onSearchProduct = (value) => {
    this.setState({ searchValue: value });
    if (this.timeout) clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      this.initProductList();
    }, 1000);
  };

  initProductList = async () => {
    try {
      const { searchValue } = this.state;
      const productListUrl = getProductSearchUrl(searchValue);
      const products = await getCall(productListUrl, []);

      if (products.error) {
        clearLocalStorageReload({ response: products });
        this.loadErrorMessageAnimation(products.error.message);
        if (products.error.statusCode === 401) {
          removeUserDetailAndOtherDetails();
          navigateTo(this.props, `/login`);
          return;
        }
      } else {
        if (products && products.length > 0) {
          this.setState({ isProductListDropDownVisible: true });
        } else {
          this.setState({ isProductListDropDownVisible: false });
        }
        this.setState({ productList: products });
      }
    } catch (error) {
      console.log("catch");
    }
  };

  render() {
    const {
      contactUs,
      AppTitle,
      showErrorMessageModal,
      errorMessage,
      showSuccessMessageModal,
      successMessage,
      userDetails,
      isShowChangeLanguageModalPopUp,
      currentLanguage,
    } = this.state;
    const { handleNotificicationDrawer, isRegisterAllow } = this.props;
    const { isInquiry } = this.props;
    const border = {
      border: "2px #000 solid",
      background: "#F0F0F0",
    };
    const noBorder = { border: "0" };
    return (
      <>
        <div
          style={{ backgroundColor: getColorCodeBasedOnKey("bg_top_header") }}
          className="top-header"
        >
          <div class="header_container">
            <div class="logo-brand">
              <a href="#">
                <LogoImage />
              </a>
            </div>
            {this.renderSearchBar()}

            <div class="header_right">
              <ul className="header-dropdown">
                {!isOpenStoreIdExist() &&
                  userDetails &&
                  this.renderNotificationIcon()}
                <li class="cart_sign_in langauge mobile-menu">
                  <div
                    // href="#"
                    // class="dropdown-toggle"
                    type="button"
                    id="dropdownMenuButton"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                    onClick={() => {
                      console.log(currentLanguage);
                      currentLanguage === "ar"
                        ? this.setLanguage("en")
                        : this.setLanguage("ar");
                    }}
                  >
                    {currentLanguage === "ar" ? "English" : "Arabic(عربى)"}
                    <i
                      class="fa fa-language d-block d-md-none"
                      aria-hidden="true"
                    />
                  </div>
                  {/* <div
                    class="usesr_dropsowdropdown dropdown-menu"
                    aria-labelledby="dropdownMenuButton"
                  >
                    <a class="dropdown-item" href="#">
                      Hindi
                    </a>
                    <a class="dropdown-item" href="#">
                      Tamil
                    </a>
                  </div> */}
                </li>
                <li class="cart_sign_in mobile-menu account">
                  <div
                    href="#"
                    class="dropdown-toggle"
                    type="button"
                    id="dropdownMenuButton"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    {getValueFromLanguageObject("str_my_account")
                      ? getValueFromLanguageObject("str_my_account")
                      : "My Account"}
                    <i
                      class="fa fa-user-circle d-block d-md-none"
                      aria-hidden="true"
                    />
                  </div>

                  {this.state.isMyAccountVisible && (
                    <div
                      class="usesr_dropsowdropdown dropdown-menu"
                      aria-labelledby="dropdownMenuButton"
                    >
                      <Link to={`/login`} data-lng="en" class="dropdown-item">
                        {getValueFromLanguageObject("str_login")
                          ? getValueFromLanguageObject("str_login")
                          : "Login"}
                      </Link>
                      {isRegisterAllow && (
                        <Link
                          to={`/signup`}
                          data-lng="en"
                          class="dropdown-item"
                        >
                          {getValueFromLanguageObject("str_register")
                            ? getValueFromLanguageObject("str_register")
                            : "Register"}
                        </Link>
                      )}
                    </div>
                  )}
                  {!this.state.isMyAccountVisible && (
                    <div
                      class="usesr_dropsowdropdown dropdown-menu"
                      aria-labelledby="dropdownMenuButton"
                    >
                      <Link to={`/orders`} class="dropdown-item">
                        <i className="fa fa-list-alt mr-2" aria-hidden="true" />
                        {!isInquiry
                          ? getValueFromLanguageObject("my_orders")
                            ? getValueFromLanguageObject("my_orders")
                            : "My Orders"
                          : getValueFromLanguageObject("str_my_inquiries")
                          ? getValueFromLanguageObject("str_my_inquiries")
                          : "My Inquiries"}
                      </Link>
                      <div
                        onClick={() => {
                          this.onClickOfMyProfile();
                        }}
                      >
                        <Link to="#" class="dropdown-item">
                          <i
                            class="fa fa-user-circle-o mr-2"
                            aria-hidden="true"
                          />
                          {getValueFromLanguageObject("str_my_profile")
                            ? getValueFromLanguageObject("str_my_profile")
                            : "My Profile"}
                        </Link>
                      </div>
                      <Link to={`/requestproduct`} class="dropdown-item">
                        <i class="fa fa-shopping-bag mr-2" aria-hidden="true" />
                        {getValueFromLanguageObject("str_request_product")
                          ? getValueFromLanguageObject("str_request_product")
                          : "Request Product"}
                      </Link>
                      <div
                        onClick={() => {
                          this.onClickOfLogOut();
                        }}
                      >
                        <Link
                          to="#"
                          class="dropdown-item text-center logout-item"
                        >
                          {getValueFromLanguageObject("str_logout")
                            ? getValueFromLanguageObject("str_logout")
                            : "Logout"}
                        </Link>
                      </div>
                    </div>
                  )}
                </li>

                {!isOpenStoreIdExist() && userDetails && (
                  <li class="cart_sign_in mobile-menu cart">
                    {/* <a href="#" class="" id=""> */}
                    <CartContainer />
                    {/* </a> */}
                  </li>
                )}
                {isOpenStoreIdExist() && (
                  <li class="cart_sign_in mobile-menu cart">
                    {/* <a href="#" class="" id=""> */}

                    <CartContainer />
                    {/* </a> */}
                  </li>
                )}
              </ul>
            </div>
          </div>
        </div>
        {showErrorMessageModal && (
          <ErrorMessageAnimationModal
            show={showErrorMessageModal}
            message={errorMessage}
            onClose={() => {
              this.setState({ showErrorMessageModal: false });
            }}
          />
        )}
        {showSuccessMessageModal && (
          <SuccessMessageAnimationModal
            show={showSuccessMessageModal}
            message={successMessage}
            onClose={() => {
              this.setState({ showSuccessMessageModal: false });
            }}
          />
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  isRegisterAllow: state.settings.isRegisterAllow,
  isInquiry: state.settings.isInquiry,
  notificationCounter: state.cartCounter.notificationCounter,
});
export default connect(
  mapStateToProps,
  {
    isUserAllowedToRegister: isUserAllowedToRegister,
    isInquiryOrCart: isInquiryOrCart,
  }
)(withTranslate(withRouter(TopBar)));
