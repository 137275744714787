import React from "react";
function ConfirmAlertPopup(props) {
  return (
    <div>
      <h3>{props.title}</h3>
      <center>
        <div className="anim-container">{props.animation}</div>
        <p>{props.message}</p>
        {props.messageDescription && (
          <p className="text-danger">{props.messageDescription}</p>
        )}
      </center>
      <div className="align-button">
        <button className="btn btn-solid" onClick={props.submitClick}>
          {props.submit}
        </button>
        <div className="divider" />
        <button className="btn btn-solid" onClick={props.cancelClick}>
          {props.cancel}
        </button>
      </div>
    </div>
  );
}
export default ConfirmAlertPopup;
