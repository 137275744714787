import React, { Component } from "react";
import { Helmet } from "react-helmet";
import Slider from "react-slick";
import "../../common/index.scss";
import { connect } from "react-redux";
import Breadcrumb from "../../common/breadcrumb";
import { getCommonCounter, getNotificationCounter } from "../../../actions";
import DetailsWithPrice from "../../products/common/product/details-price";
import SmallImages from "../../products/common/product/small-image";
import { getCall } from "../../../api/post";
import {
  loadUserDetailsFromLocalStorage,
  getValueFromLanguageObject,
  getCurrencySymbol,
} from "../../../store";
import {
  getImageUrl,
  getPlaceHolderImage,
  getSettingUrl,
  getMerchantInformationUrl,
  getMasterDetailsIdUrl,
  getSingleProductUrl,
} from "../../../services";

import ProductDetailsTab from "./product-detail-tab";
import MainLoader from "../../../animation/MainLoader";
import ErrorMessageAnimationModal from "../../../animation/ErrorMessageAnimationModal";
import {
  clearLocalStorageReload,
  isOpenStoreIdExist,
  navigateTo,
  removeUserDetailAndOtherDetails,
} from "../../../utils/utils";
import { withRouter } from "react-router-dom";
import Lightbox from "react-image-lightbox";
import YoutubeEmbed from "./YoutubeEmbed";

class SingleProduct extends Component {
  constructor() {
    super();
    this.state = {
      open: false,
      nav1: null,
      nav2: null,
      newArrival: [],
      isLoading: true,
      isMerchantGSTEnable: false,
      merchantInfo: {},
      shippingCharges: [],
      AppTitle: "",
      currentCatelogueType: {},
      showErrorMessageModal: false,
      errorMessage: "",
      masterDetailsObj: {},
      categories: {},
      isFullScreenImage: false,
      fullScreenImagePath: "",
      currentSliderIndex: 0,
    };
  }

  componentDidMount() {
    const userDetails = loadUserDetailsFromLocalStorage();
    if (!isOpenStoreIdExist() && userDetails === undefined) {
      navigateTo(this.props, `/login`);
      return;
    }
    this.initialApiCalls();
  }

  initialApiCalls = () => {
    this.initMasterDetailsId().then(() => {
      this.initMerchantInfo();
      this.initProduct().then(() => {
        this.setState({ isLoading: false });
        this.setState({
          nav1: this.slider1,
          nav2: this.slider2,
        });
        this.initParentCategory();
      });
      this.initCatelogueSettings();
      this.initProductListNewProduct();
      this.initCategory();
      this.props.notificationCounter();
    });
  };

  UNSAFE_componentWillUpdate(prevProps, prevState) {
    const { productId } = this.props;
    const { match } = prevProps || {};
    const { params } = match || {};
    const { id } = params || {};
    if (id !== productId) {
      this.setState({ isLoading: true });
      this.initialApiCalls();
    }
  }

  initMasterDetailsId = async () => {
    let masterDetailsObj = {};
    const publicUrl = window.location.pathname.split("/")[1];
    const masterDetailsIdUrl = getMasterDetailsIdUrl(publicUrl);
    const masterDetails = await getCall(masterDetailsIdUrl, []).then();
    if (masterDetails.error) {
      clearLocalStorageReload({ response: masterDetails });
    } else {
      if (masterDetails) {
        masterDetailsObj = masterDetails;
      }
    }
    this.setState({ masterDetailsObj: masterDetailsObj });
  };

  initProduct = async () => {
    const { productId } = this.props;
    const productUrl = getSingleProductUrl(productId);
    const product = await getCall(productUrl, []);
    if (product.error) {
      clearLocalStorageReload({ response: product });
      this.loadErrorMessageAnimation(product.error.message);
      if (product.error.statusCode === 401) {
        removeUserDetailAndOtherDetails();
        navigateTo(this.props, `/login`);
        return;
      }
    } else {
      this.setState({ product });
    }
  };

  initMerchantInfo = async () => {
    const { masterDetailsObj } = this.state;
    const merchantInfoUrl = getMerchantInformationUrl(masterDetailsObj);
    const merchantInfo = await getCall(merchantInfoUrl, []);
    if (merchantInfo.error) {
      clearLocalStorageReload({ response: merchantInfo });
      this.setState({
        isMerchantGSTEnable: false,
        merchantInfo: {},
        AppTitle: "",
      });
    } else {
      this.setState({
        merchantInfo,
        AppTitle: merchantInfo.companyname,
        isMerchantGSTEnable: merchantInfo.enablegst === 0 ? false : true,
      });
    }
  };

  initCatelogueSettings = async () => {
    const { masterDetailsObj } = this.state;
    const settingUrl = getSettingUrl(masterDetailsObj);
    const settingInfo = await getCall(settingUrl, []);
    if (settingInfo.error) {
      clearLocalStorageReload({ response: settingInfo });
    } else {
      let settings = {};
      settingInfo.forEach((setting) => {
        if (
          (setting.registerallow === "Catalogue_FMCG" ||
            setting.registerallow === "Catalogue_Jewellary" ||
            setting.registerallow === "Catalogue_Electronics" ||
            setting.registerallow === "Catalogue_FABRIC") &&
          setting.status === 1
        ) {
          settings = setting;
        }
      });
      this.setState({ currentCatelogueType: settings });
    }
  };

  initProductListNewProduct = async () => {
    const productListUrl =
      "products?filter[include]=productbrand&filter[include]=productmedia&filter[include]=category&filter[where][productstatus]=1&filter[where][availablequantity][gt]=0&filter[order][0]=created DESC&filter[skip]=0&filter[limit]=20";

    const newArrival = await getCall(productListUrl, []);
    if (newArrival.error) {
      clearLocalStorageReload({ response: newArrival });
      this.loadErrorMessageAnimation(newArrival.error.message);
      if (newArrival.error.statusCode === 401) {
        removeUserDetailAndOtherDetails();
        navigateTo(this.props, `/login`);
        return;
      }
    } else {
      this.setState({ newArrival });
    }
  };

  filterClick() {
    document.getElementById("filter").style.left = "-15px";
  }
  backClick() {
    document.getElementById("filter").style.left = "-365px";
  }

  handleAddToCart = () => {
    this.props.counter();
    this.setState({ isLoading: false }, () => {
      this.setState({ isLoading: true }, () => {
        this.initProduct().then(() => {
          this.setState({ isLoading: false });
        });
        this.initProductListNewProduct();
      });
    });
  };

  renderBundlePrice = (productvariation) => {
    let bundlePrice = 0;
    const variation = JSON.parse(productvariation);
    if (variation && variation.length > 0) {
      for (let index = 0; index < variation.length; index++) {
        const element = variation[index];
        if (element.price && element.quantity) {
          bundlePrice = bundlePrice + element.price * element.quantity;
        }
      }
    }
    return bundlePrice;
  };

  loadErrorMessageAnimation = (message) => {
    this.setState({ errorMessage: message, showErrorMessageModal: true });
  };

  initCategory = async () => {
    const categoryUrl = "categories?filter[where][isCategoryData]=1";
    const categories = await getCall(categoryUrl, []);

    if (categories.error) {
      clearLocalStorageReload({ response: categories });
      this.loadErrorMessageAnimation(categories.error.message);
    } else {
      this.setState({ categories: categories.data });
    }
  };

  initParentCategory = async () => {
    const { product } = this.state;
    const { category } = product;
    const { parentId } = category;
    if (parentId !== null) {
      const singleCategoryUrl = "categories/" + parentId;
      const singleCategory = await getCall(singleCategoryUrl, []);
      if (singleCategory.error) {
        clearLocalStorageReload({ response: singleCategory });
        this.loadErrorMessageAnimation(singleCategory.error.message);
        if (singleCategory.error.statusCode === 401) {
          removeUserDetailAndOtherDetails();
          navigateTo(this.props, `/login`);
          return;
        }
      } else {
        this.setState({ parentCategory: singleCategory });
      }
    }
  };

  getId = (url) => {
    const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
    const match = url.match(regExp);

    return match && match[2].length === 11 ? match[2] : null;
  };

  rendervideourllink = (videourllink) => {
    this.getId(videourllink);
    return (
      <tr>
        <div className="App">
          <YoutubeEmbed embedId={this.getId(videourllink)} />
        </div>
      </tr>
    );
  };

  renderProductImageSliderWithYoutubeVideo = (product) => {
    const { currentSliderIndex, masterDetailsObj } = this.state;

    return product.productmedia && product.productmedia.length > 0 ? (
      product.productmedia.map((vari, index) => (
        <div
          key={index}
          onClick={() => {
            const path = vari.productname
              ? getImageUrl(
                  product.productmedia[currentSliderIndex].productname,
                  masterDetailsObj
                )
              : getPlaceHolderImage();
            this.setState({
              isFullScreenImage: true,
              fullScreenImagePath: path,
            });
          }}
        >
          <img
            alt=""
            style={product.availablequantity === 0 ? { opacity: "0.7" } : {}}
            src={getImageUrl(vari.productname, masterDetailsObj)}
            className="img-product-slider"
            onError={(e) => {
              e.target.onerror = null;
              e.target.src = getPlaceHolderImage();
            }}
          />
        </div>
      ))
    ) : (
      <img src={getPlaceHolderImage()} className="img-fluid" alt="" />
    );
  };

  render() {
    const { symbol } = this.props;
    var products = {
      slidesToShow: 1,
      slidesToScroll: 1,
      dots: true,
      arrows: true,
      fade: true,
      afterChange: (current) => this.setState({ currentSliderIndex: current }),
    };
    var productsnav = {
      slidesToShow: 3,
      swipeToSlide: true,
      arrows: false,
      dots: false,
      focusOnSelect: true,
      infinite: false,
    };

    const {
      product,
      // newArrival,
      isLoading,
      AppTitle,
      showErrorMessageModal,
      errorMessage,
      masterDetailsObj,
      parentCategory,
      isFullScreenImage,
      fullScreenImagePath,
      currentSliderIndex,
    } = this.state;
    const { category = {} } = product || {};

    return (
      <div>
        {/*SEO Support*/}
        <Helmet>
          <title>
            {AppTitle} | {product ? product.category.name : ""} |{" "}
            {product ? product.name : ""}
          </title>
          <meta
            name="description"
            content="Multikart – Multipurpose eCommerce React Template is a multi-use React template. It is designed to go well with multi-purpose websites. Multikart Bootstrap 4 Template will help you run multiple businesses."
          />
        </Helmet>
        {/*SEO Support End */}

        <Breadcrumb
          title={
            getValueFromLanguageObject("str_products")
              ? getValueFromLanguageObject("str_products")
              : "Products"
          }
          parentCategory={parentCategory} //parent category
          subCategory={category} //subcategory
          productName={product && product.name} //productname
        />

        {/*Section Start*/}
        {product ? (
          <section className="section-b-space">
            <div className="collection-wrapper">
              <div className="container">
                <div className="row">
                  <div className="col-sm-3 collection-filter" id="filter">
                    <div className="collection-mobile-back pl-5">
                      <span onClick={this.backClick} className="filter-back">
                        <i className="fa fa-angle-left" aria-hidden="true" />{" "}
                        {getValueFromLanguageObject("str_back")}
                      </span>
                    </div>

                    {/* <BrandBlock/> */}
                    {/* <Service /> */}
                    {/*side-bar single product slider start*/}
                    {/* <NewProduct newProducts={newArrival} /> */}
                    {/*side-bar single product slider end*/}
                  </div>
                  <div className="col-lg-12 col-sm-12 col-xs-12">
                    <div className="">
                      <div className="row">
                        <div className="col-xl-12">
                          <div className="filter-main-btn mb-2">
                            <span
                              onClick={this.filterClick}
                              className="filter-btn"
                            >
                              {/* <i className="fa fa-filter" aria-hidden="true" />{" "}
                              {getValueFromLanguageObject("str_filter")
                                ? getValueFromLanguageObject("str_filter")
                                : "Filter"} */}
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-lg-6 product-thumbnail">
                          <Slider
                            {...products}
                            asNavFor={this.state.nav2}
                            ref={(slider) => (this.slider1 = slider)}
                            className="product-slick"
                          >
                            {this.renderProductImageSliderWithYoutubeVideo(
                              product
                            )}
                            {product.videourllink &&
                              this.rendervideourllink(product.videourllink)}
                          </Slider>
                          <SmallImages
                            item={product}
                            settings={productsnav}
                            navOne={this.state.nav1}
                          />
                        </div>
                        <DetailsWithPrice
                          symbol={symbol}
                          item={product}
                          navOne={this.state.nav1}
                          afterAddToCartClicked={this.handleAddToCart}
                        />
                      </div>
                    </div>
                    <ProductDetailsTab item={product} />
                  </div>
                </div>
              </div>
            </div>
          </section>
        ) : (
          ""
        )}
        {/*Section End*/}
        {isLoading && <MainLoader />}
        {showErrorMessageModal && (
          <ErrorMessageAnimationModal
            show={showErrorMessageModal}
            message={errorMessage}
            onClose={() => {
              this.setState({ showErrorMessageModal: false });
            }}
          />
        )}
        {isFullScreenImage && (
          <Lightbox
            mainSrc={fullScreenImagePath}
            onCloseRequest={() =>
              this.setState({
                isFullScreenImage: false,
                fullScreenImagePath: "",
              })
            }
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  let productId = ownProps.match.params.id;
  return {
    productId,
    symbol: getCurrencySymbol(),
  };
};

export default connect(
  mapStateToProps,
  {
    counter: getCommonCounter,
    notificationCounter: getNotificationCounter,
  }
)(withRouter(SingleProduct));
