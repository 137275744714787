import {
  COMMON_COUNTER,
  NOTIFICATION_COUNTER,
  UPDATE_QTY,
} from "../constants/ActionTypes";
const initialState = {
  cartCounter: 0,
  notificationCounter: 0,
  updateQuantity: 0,
};

const cartReducer = (state = initialState, action) => {
  switch (action.type) {
    case COMMON_COUNTER:
      return { ...state, cartCounter: action.cartCounter };
    case NOTIFICATION_COUNTER:
      return { ...state, notificationCounter: action.notificationCounter };
    case UPDATE_QTY:
      return { ...state, updateQuantity: action.updateQuantity };
    default:
      return state;
  }
};
export default cartReducer;
