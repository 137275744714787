import React, { Component } from "react";
import { connect } from "react-redux";
import "react-input-range/lib/css/index.css";
import { SlideToggle } from "react-slide-toggle";
import { filterBrand, filterColor, filterPrice } from "../../../actions";
import {
  getValueFromLanguageObject,
  getColorCodeBasedOnKey,
} from "../../../store";
import { withRouter } from "react-router-dom";

class Filter extends Component {
  componentDidMount() {}

  closeFilter = () => {
    document.querySelector(".collection-filter").style = "left: -365px";
  };

  // colorHandle(event, color) {
  //   var elems = document.querySelectorAll(".color-selector ul li");
  //   [].forEach.call(elems, function(el) {
  //     el.classList.remove("active");
  //   });
  //   event.target.classList.add("active");
  //   this.props.filterColor(color);
  // }

  render() {
    let styles = {
      Padding: "0",
      border: "none",
      background: "none",
    };

    const {
      categories,
      subCategories,
      categoryId,
      subCategoryId,
      onClickOfParentCategory,
      onClickOfSubCategory,
      clearFilterOnClickOfAll,
    } = this.props;

    return (
      <>
        {categories && categories.length > 0 && (
          <div className="collection-filter-block">
            {/*brand filter start*/}
            <div className="collection-mobile-back">
              <span
                className="filter-back"
                onClick={(e) => this.closeFilter(e)}
              >
                <i className="fa fa-angle-left" aria-hidden="true" />{" "}
                {getValueFromLanguageObject("str_back")}
              </span>
            </div>

            {/* // Category */}

            <SlideToggle>
              {({ onToggle, setCollapsibleElement }) => (
                <div className="collection-collapse-block">
                  <h3 className="collapse-block-title" onClick={onToggle}>
                    {getValueFromLanguageObject("str_category")
                      ? getValueFromLanguageObject("str_category")
                      : "category"}
                  </h3>
                  <div
                    className="collection-collapse-block-content"
                    ref={setCollapsibleElement}
                  >
                    <div className="collection-brand-filter">
                      <div value={categoryId} style={styles}>
                        <div className="custom-control custom-checkbox collection-filter-checkbox pl-0 subcategory-style table-style ">
                          <div
                            style={styles}
                            className={
                              categoryId === "" ? "td-style" : "category-style"
                            }
                            onClick={() => {
                              clearFilterOnClickOfAll();
                            }}
                          >
                            {getValueFromLanguageObject("str_all")
                              ? getValueFromLanguageObject("str_all")
                              : "ALL"}
                          </div>
                        </div>
                        {categories.map((category, index) => {
                          return (
                            <div
                              key={index}
                              className="custom-control custom-checkbox collection-filter-checkbox pl-0 "
                            >
                              <div className="category-style">
                                <table className="table-style">
                                  <tr
                                    onClick={() => {
                                      onClickOfParentCategory(category);
                                    }}
                                    style={{ cursor: "pointer" }}
                                  >
                                    <td
                                      style={styles}
                                      className={
                                        categoryId === category.id
                                          ? "td-style"
                                          : ""
                                      }
                                    >
                                      {category.name}
                                    </td>
                                    <td className="th-style">
                                      <i
                                        class="fa fa-angle-right"
                                        aria-hidden="true"
                                      />
                                    </td>
                                  </tr>
                                </table>
                              </div>
                              {subCategories.length > 0 && (
                                <div
                                  defaultValue={subCategoryId}
                                  style={styles}
                                >
                                  {subCategories
                                    .filter((subcategory) => {
                                      return (
                                        subcategory.parentId === category.id
                                      );
                                    })
                                    .map((subcategory, index) => {
                                      return (
                                        <div className="custom-control custom-checkbox  pl-0 ">
                                          <div className="subcategory-style">
                                            <table className="table-style">
                                              <tr>
                                                <td
                                                  style={styles}
                                                  className={
                                                    subCategoryId ===
                                                    subcategory.id
                                                      ? "td-style subActive-style"
                                                      : ""
                                                  }
                                                  onClick={() => {
                                                    onClickOfSubCategory(
                                                      subcategory
                                                    );
                                                  }}
                                                >
                                                  {subcategory.name}
                                                </td>
                                              </tr>
                                            </table>
                                          </div>
                                        </div>
                                      );
                                    })}
                                </div>
                              )}
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </SlideToggle>
          </div>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, {
  filterBrand,
  filterColor,
  filterPrice,
})(withRouter(Filter));
