import React, { Component } from "react";
import Breadcrumb from "../../common/breadcrumb";
import { getCall, deleteCall } from "../../../api/post";
import {
  loadUserDetailsFromLocalStorage,
  getValueFromLanguageObject,
  getColorCodeBasedOnKey,
  getOpenStoreIdApp,
  isStoreOpen,
} from "../../../store";
import { getNotificationCounter } from "../../../actions";
import { connect } from "react-redux";
import {
  getMasterDetailsIdUrl,
  getMerchantInformationUrl,
  getCategoriesUrl,
  getSubCategoriesOfParentCategoryUrl,
  getProductListingUrl,
  getRequestProductListingUrl,
} from "../../../services";
import MainLoader from "../../../animation/MainLoader";
import ErrorMessageAnimationModal from "../../../animation/ErrorMessageAnimationModal";
import {
  clearLocalStorageReload,
  isOpenStoreIdExist,
  navigateTo,
  removeUserDetailAndOtherDetails,
} from "../../../utils/utils";
import { withRouter } from "react-router-dom";
import RequestPaymentModal from "./RequestPaymentModal";
import RequestProductList from "./RequestProductList";
import ProductLoaderMain from "../contentloader/product-loader";
import NoProductFoundProductAnim from "../../../animation/AnimFiles/NoProductFoundProductAnim";
import { confirmAlert } from "react-confirm-alert";
import ConfirmAlertPopup from "../../../Alert/ConfirmAlertPopup";
import DeleteAnim from "../../../animation/AnimFiles/DeleteAnim";
import SuccessMessageAnimationModal from "../../../animation/SuccessMessageAnimationModal";
import FilterBar from "../../collection/common/request-product-filter-bar";
import { Helmet } from "react-helmet";

class RequestProduct extends Component {
  state = {
    isLoading: true,
    AppTitle: "",
    showErrorMessageModal: false,
    errorMessage: "",
    masterDetailsObj: {},
    showSuccessMessageModal: false,
    successMessage: "",
    layoutColumns: 2,
    productList: [],
    categories: [],
    skip: 0,
    limit: 20,
    isPaginationEnableForProductList: false,
    sortingOrder: "1",
  };

  LayoutViewClicked(colums) {
    this.setState({
      layoutColumns: colums,
    });
  }

  componentDidMount() {
    const userDetails = loadUserDetailsFromLocalStorage();
    if (!isOpenStoreIdExist() && userDetails === undefined) {
      navigateTo(this.props, `/login`);
      return;
    }
    this.initMasterDetailsId().then(() => {
      this.setState({ isLoading: true }, () => {
        this.initProductList().then(() => {
          this.setState({ isLoading: false });
        });
      });
      this.initMerchantInfo();
      this.initCategory();
    });
  }

  initMasterDetailsId = async () => {
    let masterDetailsObj = {};
    const publicUrl = window.location.pathname.split("/")[1];
    const masterDetailsIdUrl = getMasterDetailsIdUrl(publicUrl);
    const masterDetails = await getCall(masterDetailsIdUrl, []).then();
    if (masterDetails.error) {
      clearLocalStorageReload({ response: masterDetails });
    } else {
      if (masterDetails) {
        masterDetailsObj = masterDetails;
      }
    }
    this.setState({ masterDetailsObj: masterDetailsObj });
  };

  initMerchantInfo = async () => {
    const { masterDetailsObj } = this.state;
    const merchantInfoUrl = getMerchantInformationUrl(masterDetailsObj);
    const merchantInfo = await getCall(merchantInfoUrl, []);
    if (merchantInfo.error) {
      clearLocalStorageReload({ response: merchantInfo });
      this.setState({
        isMerchantGSTEnable: false,
        merchantInfo: {},
        AppTitle: "",
      });
    } else {
      this.setState({
        merchantInfo,
        AppTitle: merchantInfo.companyname,
        isMerchantGSTEnable: merchantInfo.enablegst === 0 ? false : true,
      });
    }
  };

  initCategory = async () => {
    const categoryUrl = getCategoriesUrl();
    const categories = await getCall(categoryUrl, []);
    if (categories.error) {
      clearLocalStorageReload({ response: categories });
      this.loadErrorMessageAnimation(categories.error.message);
    } else {
      this.setState({ categories: categories.data });
    }
  };

  openFilter = () => {
    document.querySelector(".collection-filter").style = "left: -15px";
  };

  initProductList = async () => {
    try {
      const { sortingOrder, skip, productList, limit } = this.state;
      let userDetails = loadUserDetailsFromLocalStorage();
      const { id: userId } = userDetails;

      const productListUrl = getRequestProductListingUrl(
        userId,
        limit,
        // searchValue,
        skip,
        sortingOrder
        // limit
      );
      const products = await getCall(productListUrl, []);
      this.setState({ isLoading: false });
      this.setState({ isLoaderVisible: false });
      if (products.error) {
        clearLocalStorageReload({ response: products });
        this.loadErrorMessageAnimation(products.error.message);
        if (products.error.statusCode === 401) {
          removeUserDetailAndOtherDetails();
          navigateTo(this.props, `/login`);
          return;
        }
      } else {
        if (products && limit <= products.length) {
          this.setState({ isPaginationEnableForProductList: true });
        } else {
          this.setState({ isPaginationEnableForProductList: false });
        }
        if (skip > 0) {
          const pl = productList.concat(products);
          this.setState({ productList: pl });
        } else {
          this.setState({ productList: products });
        }
      }
    } catch (error) {
      this.setState({ isLoading: false });
    }
  };

  handlePagination = (skip) => {
    this.setState({ skip }, () => {
      this.initProductList();
    });
  };

  onSearchProduct = (value) => {
    this.setState({ searchValue: value, skip: 0 }, () => {
      this.initProductList();
    });
  };

  handleLoader = (isLoading) => {
    this.setState({ isLoading });
  };

  loadErrorMessageAnimation = (message) => {
    this.setState({ errorMessage: message, showErrorMessageModal: true });
  };

  handleOnClickOfParentCategory = (category) => {
    const { id } = category;
    this.setState(
      {
        selectedCategory: category,
        categoryId: id,
        subCategoryId: "",
        selectedSubCategory: {},
      },
      () => {
        this.props.history.replace(`/c/${id}`);
        this.handleCategoryWiseProductListFilter();
      }
    );
  };

  handleOnClickOfSubCategory = (subCategory) => {
    const { id } = subCategory || {};
    this.setState(
      { selectedSubCategory: subCategory, subCategoryId: id },
      () => {
        this.props.history.replace(`/s/${id}`);
        this.handleSubCategoryWiseProductListFilter();
      }
    );
  };

  handleSortingProductFilter = (order) => {
    this.setState({ sortingOrder: order, skip: 0, isLoading: true }, () => {
      this.initProductList().then(() => {
        this.setState({ isLoading: false });
      });
    });
  };

  deleteProduct = async (product, index) => {
    const { id } = product || {};
    const deleteProductUrl = `orders/${id}`;
    const deleteProduct = await deleteCall(deleteProductUrl);

    if (deleteProduct.error) {
      clearLocalStorageReload({ response: deleteProduct });
      this.loadErrorMessageAnimation(deleteProduct.error.message);
      if (deleteProduct.error.statusCode === 401) {
        removeUserDetailAndOtherDetails();
        navigateTo(this.props, `/login`);
        return;
      }
    } else {
      this.loadSuccessMessageAnimation(
        getValueFromLanguageObject("str_product_has_been_removed_successfully")
          ? getValueFromLanguageObject(
              "str_product_has_been_removed_successfully"
            )
          : "Product has been removed successfully!"
      );
      const { productList } = this.state;

      productList.splice(index, 1);

      this.setState({ productList: [] }, () => {
        this.setState({ productList: productList });
      });
    }
  };

  onClickOfDeleteIcon = async (product, index) => {
    confirmAlert({
      customUI: ({ onClose }) => {
        const submitClick = () => {
          this.setState({ isLoading: true }, () => {
            this.deleteProduct(product, index).then(() => {
              this.setState({ isLoading: false });
            });
            onClose();
          });
        };

        return (
          <>
            <ConfirmAlertPopup
              title={
                getValueFromLanguageObject("str_title_remove_product")
                  ? getValueFromLanguageObject("str_title_remove_product")
                  : "Remove Product"
              }
              message={
                getValueFromLanguageObject("str_remove_product_cart")
                  ? getValueFromLanguageObject("str_remove_product_cart")
                  : "Are you sure you want to remove this product?"
              }
              animation={<DeleteAnim />}
              submit={
                getValueFromLanguageObject("str_yes_remove_it")
                  ? getValueFromLanguageObject("str_yes_remove_it")
                  : "Yes, Remove it!"
              }
              submitClick={submitClick}
              cancelClick={onClose}
              cancel={
                getValueFromLanguageObject("str_no")
                  ? getValueFromLanguageObject("str_no")
                  : "No"
              }
            />
          </>
        );
      },
    });
  };

  updateProductList = () => {
    const { skip } = this.state;
    this.setState({ skip: 0 }, () => {
      this.initProductList();
    });
  };

  loadErrorMessageAnimation = (message) => {
    this.setState({ errorMessage: message, showErrorMessageModal: true });
  };

  loadSuccessMessageAnimation = (message) => {
    this.setState({ successMessage: message, showSuccessMessageModal: true });
  };

  render() {
    const {
      isAddRequestProductModalVisible,
      productList,
      isLoading,
      showErrorMessageModal,
      errorMessage,
      isPaginationEnableForProductList,
      limit,
      categories,
      layoutColumns,
      successMessage,
      showSuccessMessageModal,
      AppTitle,
    } = this.state;

    const strRequestProduct = getValueFromLanguageObject("str_request_product")
      ? getValueFromLanguageObject("str_request_product")
      : "Request Product";

    return (
      <div>
        {/*SEO Support*/}
        <Helmet>
          <title>
            {AppTitle} | {strRequestProduct}
          </title>
          <meta
            name="description"
            content="Multikart – Multipurpose eCommerce React Template is a multi-use React template. It is designed to go well with multi-purpose websites. Multikart Bootstrap 4 Template will help you run multiple businesses."
          />
        </Helmet>
        {/*SEO Support End */}
        <Breadcrumb
          title={
            getValueFromLanguageObject("str_request_product")
              ? getValueFromLanguageObject("str_request_product")
              : "Request Product"
          }
        />
        <section className="section-b-space">
          <div className="collection-wrapper">
            <div className="container">
              <div className="row">
                <div className="collection-content col">
                  <div className="page-main-content ">
                    <div className="">
                      <div className="row">
                        <div className="col-sm-12">
                          <div className="top-banner-wrapper" />

                          <div className="row mb-3 search-product">
                            <div className="col-md-6 col-sm-0 " />
                            <div className="col-md-6 col-sm-12 search-form subscribe-form d-flex justify-content-end">
                              <div className="form-group mb-0 ml-0 mr-0 d-flex">
                                <div
                                  onClick={() => {
                                    this.setState({
                                      isAddRequestProductModalVisible: true,
                                    });
                                  }}
                                  className="btn btn-solid"
                                >
                                  <i
                                    className="fa fa-plus-square mr-2"
                                    aria-hidden="true"
                                  />
                                  {strRequestProduct}
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="collection-product-wrapper">
                            <div className="product-top-filter">
                              <div className="container-fluid p-0">
                                <div className="row">
                                  <div className="col-xl-12">
                                    <div className="filter-main-btn">
                                      <span
                                        onClick={this.openFilter}
                                        className="filter-btn btn btn-theme"
                                      >
                                        <i
                                          className="fa fa-filter"
                                          aria-hidden="true"
                                        />
                                        {getValueFromLanguageObject(
                                          "str_filter"
                                        )
                                          ? getValueFromLanguageObject(
                                              "str_filter"
                                            )
                                          : "Filter"}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="col-12">
                                    <FilterBar
                                      productList={productList}
                                      handleSortingProductFilter={
                                        this.handleSortingProductFilter
                                      }
                                      onLayoutViewClicked={(colmuns) =>
                                        this.LayoutViewClicked(colmuns)
                                      }
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>

                            {productList.length !== 0 && (
                              <RequestProductList
                                productList={productList}
                                colSize={4}
                                handlePagination={this.handlePagination}
                                handleLoader={this.handleLoader}
                                limit={limit}
                                isPaginationEnableForProductList={
                                  isPaginationEnableForProductList
                                }
                                onClickOfDeleteIcon={this.onClickOfDeleteIcon}
                              />
                            )}
                            {productList.length === 0 && (
                              <NoProductFoundProductAnim />
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <RequestPaymentModal
          isAddRequestProductModalVisible={isAddRequestProductModalVisible}
          categories={categories}
          productDesc={this.state.productDesc}
          onCloseAddRequestProductModal={() => {
            this.setState({
              isAddRequestProductModalVisible: false,
            });
          }}
          onClickOfSubmit={this.onClickOfSubmit}
          initProductList={this.initProductList}
          masterDetailsObj={this.state.masterDetailsObj}
          updateProductList={this.updateProductList}
        />
        {isLoading && <MainLoader />}
        {showErrorMessageModal && (
          <ErrorMessageAnimationModal
            show={showErrorMessageModal}
            message={errorMessage}
            onClose={() => {
              this.setState({ showErrorMessageModal: false });
            }}
          />
        )}
        {showSuccessMessageModal && (
          <SuccessMessageAnimationModal
            show={showSuccessMessageModal}
            message={successMessage}
            onClose={() => {
              this.setState({ showSuccessMessageModal: false });
            }}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, {
  notificationCounter: getNotificationCounter,
})(withRouter(RequestProduct));
