import React, { Component } from "react";
import Lottie from "react-lottie";
import { getValueFromLanguageObject } from "../store";
import notificationContentLoaderAnimJSON from "./notificationContentLoaderAnimJSON.json";

class NotificationContentLoaderAnim extends Component {
  defaultOptions = {};

  constructor(props) {
    super(props);
    this.defaultOptions = {
      loop: true,
      autoplay: true,
      animationData: notificationContentLoaderAnimJSON,
    };
  }

  render() {
    return (
      <>
        <div
          className="col-12 d-flex justify-content-center align-items-center"
          style={{ height: "80vh" }}
        >
          <section className="cart-section section-b-space">
            <div className="container">
              <div className="row">
                <div className="col-sm-12 animationSize-200-200">
                  <div>
                    <div>
                      <Lottie options={this.defaultOptions} />
                      <h3 className="mt-4 text-center">
                        <strong>
                          {getValueFromLanguageObject(
                            "str_please_wait_with_mark"
                          )
                            ? getValueFromLanguageObject(
                                "str_please_wait_with_mark"
                              )
                            : "Please Wait...!"}
                        </strong>
                      </h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </>
    );
  }
}

export default NotificationContentLoaderAnim;
