import React, { Component } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { connect } from "react-redux";
import ProductItem from "./product-item";
import {
  loadUserDetailsFromLocalStorage,
  getValueFromLanguageObject,
  getCurrencySymbol,
} from "../../../store";
import { getCall } from "../../../api/post";
import { getCommonCounter, isInquiryOrCart } from "../../../actions";
import { Link, withRouter } from "react-router-dom";
import { getPincodeDeliveryUrl } from "../../../services";
import ErrorMessageAnimationModal from "../../../animation/ErrorMessageAnimationModal";
import SuccessMessageAnimationModal from "../../../animation/SuccessMessageAnimationModal";
import NoSpecialProductFoundAnim from "../../../animation/NoSpecialProductFoundAnim";

import {
  clearLocalStorageReload,
  isOpenStoreIdExist,
  navigateTo,
} from "../../../utils/utils";
import { debuglogger } from "../../../utils/logger";

class SpecialProducts extends Component {
  state = {
    isPincodeDeliveryEnable: false,
    showErrorMessageModal: false,
    errorMessage: "",
    showSuccessMessageModal: false,
    successMessage: "",
  };

  componentDidMount() {
    // this.initMasterDetailsId().then(() => {
    // });
    const { masterDetailsObj } = this.props;
    this.props.isInquiryOrCart(masterDetailsObj);
    this.initPincodeDelivery();
  }

  initPincodeDelivery = async () => {
    const { masterDetailsObj } = this.props;

    const userDetails = loadUserDetailsFromLocalStorage();
    if (!isOpenStoreIdExist() && userDetails === undefined) {
      navigateTo(this.props, `/login`);
      return;
    }
    const pincodeDeliveryUrl = getPincodeDeliveryUrl(masterDetailsObj);
    debuglogger("pincodeDeliveryUrl", pincodeDeliveryUrl);
    const pincodeDelivery = await getCall(pincodeDeliveryUrl, []);
    if (pincodeDelivery.error) {
      clearLocalStorageReload({ response: pincodeDelivery });
      this.loadErrorMessageAnimation(pincodeDelivery.error.message);
    } else {
      if (pincodeDelivery.length > 0) {
        const status = pincodeDelivery[0].status;
        this.setState({ isPincodeDeliveryEnable: status === 1 ? true : false });
      }
    }
  };

  loadErrorMessageAnimation = (message) => {
    this.setState({ errorMessage: message, showErrorMessageModal: true });
  };

  loadSuccessMessageAnimation = (message) => {
    this.setState({ successMessage: message, showSuccessMessageModal: true });
  };

  render() {
    const {
      symbol,
      productsNewArrival,
      productsBestSelling,
      masterDetailsObj,
      handleClickOfQuickView,
    } = this.props;
    const {
      isPincodeDeliveryEnable,
      showErrorMessageModal,
      errorMessage,
      showSuccessMessageModal,
      successMessage,
    } = this.state;

    return (
      <>
        <section className="section-b-space ratio_square layout-8">
          <div className="container-fluid">
            <div className="title2">
              <h4>
                {getValueFromLanguageObject("str_new_collection")
                  ? getValueFromLanguageObject("str_new_collection")
                  : "New Collection"}
              </h4>
              <h2 className="title-inner2">
                <span>
                  {getValueFromLanguageObject("str_trending_products")
                    ? getValueFromLanguageObject("str_trending_products")
                    : "Trending Products"}
                </span>
              </h2>
            </div>
            <div className="row">
              <div className="col">
                <div className="theme-tab">
                  <Tabs className="theme-tab">
                    <TabList className="tabs tab-title">
                      <Tab>
                        <span>
                          {" "}
                          {getValueFromLanguageObject("str_new_arrival")
                            ? getValueFromLanguageObject("str_new_arrival")
                            : "NEW ARRIVAL"}
                        </span>
                      </Tab>
                      <Tab>
                        <span>
                          {getValueFromLanguageObject("str_best_selling")
                            ? getValueFromLanguageObject("str_best_selling")
                            : "BEST SELLING"}
                        </span>
                      </Tab>
                      {/* <Tab>
                      {getValueFromLanguageObject("str_recently_viewed")
                        ? getValueFromLanguageObject("str_recently_viewed")
                        : "RECENTLY VIEWED"}
                    </Tab> */}
                    </TabList>

                    <TabPanel>
                      <div className="no-slider row">
                        {productsNewArrival
                          .slice(0, 8)
                          .map((product, index) => (
                            <ProductItem
                              product={product}
                              symbol={symbol}
                              onAddToCartClicked={(product, qty) => {
                                this.props.addToCart(product, qty);
                              }}
                              isPincodeDeliveryEnable={isPincodeDeliveryEnable}
                              key={index}
                              masterDetailsObj={masterDetailsObj}
                              handleClickOfQuickView={handleClickOfQuickView}
                            />
                          ))}

                        {/* {productsNewArrival.length === 0 &&
                           (
                            <SpecialProductContentLoaderAnim />
                          )} */}

                        {productsNewArrival.length === 0 && (
                          <NoSpecialProductFoundAnim />
                        )}
                      </div>
                    </TabPanel>
                    <TabPanel>
                      <div className="no-slider row">
                        {productsBestSelling
                          .slice(0, 8)
                          .map((product, index) => (
                            <ProductItem
                              product={product}
                              symbol={symbol}
                              onAddToCartClicked={(product, qty) =>
                                this.props.addToCart(product, qty)
                              }
                              isPincodeDeliveryEnable={isPincodeDeliveryEnable}
                              key={index}
                              masterDetailsObj={masterDetailsObj}
                              handleClickOfQuickView={handleClickOfQuickView}
                            />
                          ))}
                        {/* {productsBestSelling.length === 0 &&
                          isContentLoaderVisible && (
                            <SpecialProductContentLoaderAnim />
                          )} */}

                        {productsNewArrival.length === 0 && (
                          <NoSpecialProductFoundAnim />
                        )}
                      </div>
                    </TabPanel>

                    {/* <TabPanel>
                    <div className=" no-slider row">
                      {productsRecentlyViewed.slice(0, 8).map((product, index) => (
                        <ProductItem
                          product={product}
                          symbol={symbol}
                          onAddToCartClicked={(product, qty) => this.addToCart(product, qty, this.props.isInquiry)}
                          key={index}
                        />
                      ))}
                      {productsRecentlyViewed.length === 0 && <ContentLoaderMain />}
                    </div>
                  </TabPanel> */}
                    <div className="action-item">
                      <Link to={`/products`} className="btn btn-solid">
                        {getValueFromLanguageObject("str_view_all_products")
                          ? getValueFromLanguageObject("str_view_all_products")
                          : "View All Products"}
                      </Link>
                    </div>
                  </Tabs>
                </div>
              </div>
            </div>
          </div>
        </section>
        {showErrorMessageModal && (
          <ErrorMessageAnimationModal
            show={showErrorMessageModal}
            message={errorMessage}
            onClose={() => {
              this.setState({ showErrorMessageModal: false });
            }}
          />
        )}
        {showSuccessMessageModal && (
          <SuccessMessageAnimationModal
            show={showSuccessMessageModal}
            message={successMessage}
            onClose={() => {
              this.setState({ showSuccessMessageModal: false });
            }}
          />
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  isInquiry: state.settings.isInquiry,
  symbol: getCurrencySymbol(),
});

export default connect(
  mapStateToProps,
  {
    counter: getCommonCounter,
    isInquiryOrCart: isInquiryOrCart,
  }
)(withRouter(SpecialProducts));
