import { getInstancedomain, getInstanceurl } from "../utils/utils";
import download from "downloadjs";

const env = process.env.NODE_ENV;

if (env === "production") {
  require("isomorphic-fetch");
  require("es6-promise").polyfill();
}

const SERVER_URL = `${process.env.SERVER_URL}/api/`;
const INSTANCE_DOMAIN = getInstancedomain();
const INSTANCE_URL = getInstanceurl();

export const postCall = (url, data = {}) =>
  new Promise((resolve) => {
    const authToken = localStorage.getItem("apiToken");
    const openStoreId = localStorage.getItem("openStoreId");
    const masterDetailsId = localStorage.getItem("masterDetailsId");
    fetch(`${SERVER_URL}${url}`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        version: "1.0",
        ...(authToken && { Authorization: authToken }),
        ...(!authToken && openStoreId && { openStoreId }),
        ...{ masterdetailId: masterDetailsId },
        ...{ instancedomain: INSTANCE_DOMAIN },
        ...{ instanceurl: INSTANCE_URL },
      },
      credentials: "include",
      method: "POST",
      body: JSON.stringify(data),
    })
      .then((res) => {
        return res.status === 204 ? {} : res.json();
      })
      .then((json) => resolve(json));
  });

export const patchCall = (url, data = {}) =>
  new Promise((resolve) => {
    const authToken = localStorage.getItem("apiToken");
    const openStoreId = localStorage.getItem("openStoreId");
    const masterDetailsId = localStorage.getItem("masterDetailsId");
    fetch(`${SERVER_URL}${url}`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        version: "1.0",
        ...(authToken && { Authorization: authToken }),
        ...(!authToken && openStoreId && { openStoreId }),
        ...{ masterdetailId: masterDetailsId },
        ...{ instancedomain: INSTANCE_DOMAIN },
        ...{ instanceurl: INSTANCE_URL },
      },
      credentials: "include",
      method: "PATCH",
      body: JSON.stringify(data),
    })
      .then((res) => {
        return res.status === 204 ? {} : res.json();
      })
      .then((json) => resolve(json));
  });

export const getCall = (url, data = []) =>
  new Promise((resolve) => {
    const authToken = localStorage.getItem("apiToken");
    const openStoreId = localStorage.getItem("openStoreId");
    const masterDetailsId = localStorage.getItem("masterDetailsId");
    fetch(`${SERVER_URL}${url}`, {
      headers: {
        Accept: "application/json",
        version: "1.0",
        ...(authToken && { Authorization: authToken }),
        ...(!authToken && openStoreId && { openStoreId }),

        ...{ masterdetailId: masterDetailsId },
        "Content-Type": "application/json",
        ...{ instancedomain: INSTANCE_DOMAIN },
        ...{ instanceurl: INSTANCE_URL },
      },
      method: "GET",
      credentials: "include",
    })
      .then((res) => (res.status === 204 ? {} : res.json()))
      .then((json) => resolve(json));
  });

export const postCallWithMedia = (url, data = {}, containerKey) =>
  new Promise((resolve) => {
    const authToken = localStorage.getItem("apiToken");
    const openStoreId = localStorage.getItem("openStoreId");
    const masterDetailsId = localStorage.getItem("masterDetailsId");
    const formData = new FormData();
    // data.forEach((file) => {
    //   formData.append("files[]", file);
    // });
    formData.append(containerKey, data);

    // Object.keys(data).forEach((d) => {
    //   formData.append(d, data[d]);
    // });

    fetch(`${SERVER_URL}${url}`, {
      headers: {
        Accept: "application/json",
        version: "1.0",
        ...(authToken && { Authorization: authToken }),
        ...(!authToken && { openStoreId }),
        ...{ masterdetailId: masterDetailsId },
        ...{ instancedomain: INSTANCE_DOMAIN },
        ...{ instanceurl: INSTANCE_URL },
      },
      credentials: "include",
      method: "POST",
      body: formData,
    })
      .then((res) => {
        return res.status === 204 ? {} : res.json();
      })
      .then((json) => resolve(json));
  });

export const customPostCall = (url, data = {}, secretKey) =>
  new Promise((resolve) => {
    fetch(`${url}`, {
      headers: {
        Accept: "application/json",
        secretkey: secretKey,
        "Content-Type": "application/json",
      },
      credentials: "include",
      method: "POST",
      body: JSON.stringify(data),
    })
      .then((res) => {
        return res.status === 204 ? {} : res.json();
      })
      .then((json) => resolve(json));
  });

export const deleteCall = (url, data = {}) =>
  new Promise((resolve) => {
    const authToken = localStorage.getItem("apiToken");
    const openStoreId = localStorage.getItem("openStoreId");
    const masterDetailsId = localStorage.getItem("masterDetailsId");
    fetch(`${SERVER_URL}${url}`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        version: "1.0",
        ...(authToken && { Authorization: authToken }),
        ...(!authToken && openStoreId && { openStoreId }),
        ...{ masterdetailId: masterDetailsId },
        ...{ instancedomain: INSTANCE_DOMAIN },
        ...{ instanceurl: INSTANCE_URL },
      },
      credentials: "include",
      method: "DELETE",
    })
      .then((res) => {
        return res.status === 204 ? {} : res.json();
      })
      .then((json) => resolve(json));
  });

export const getDownloadPDF = (url, { orderId }) =>
  new Promise((resolve) => {
    const authToken = localStorage.getItem("apiToken");
    const openStoreId = localStorage.getItem("openStoreId");
    const masterDetailsId = localStorage.getItem("masterDetailsId");
    fetch(`${SERVER_URL}${url}`, {
      headers: {
        Accept: "application/json",
        version: "1.0",
        ...(authToken && { Authorization: authToken }),
        ...(!authToken && openStoreId && { openStoreId }),

        ...{ masterdetailId: masterDetailsId },
        "Content-Type": "application/json",
        ...{ instancedomain: INSTANCE_DOMAIN },
        ...{ instanceurl: INSTANCE_URL },
      },
      method: "GET",
      credentials: "include",
      responseType: "blob",
    })
      .then((response) => {
        if (response.status === 200) {
          return response.blob();
        } else {
          return;
        }
      })
      .then((body) => {
        download(
          body,
          "order-invoice-" + orderId + ".pdf",
          "application/octet-stream"
        );
        resolve({});
      });
  });

// export const postAuth = (url, data = {}) =>
//   new Promise(resolve => {
//     if (url.indexOf("undefined") === -1) {
//       fetch(authUrl(url), {
//         headers: {
//           Accept: "application/json",
//           "Content-Type": "application/json"
//         },
//         credentials: "include",
//         method: "POST",
//         body: JSON.stringify(data)
//       })
//         .then(res => res.json())
//         .then(json => resolve(json));
//     }
//   });

// export const postAuthWithMedia = (url, data = {}) =>
//   new Promise((resolve) => {
//     const formData = new FormData();
//     data.mediaList.forEach((file) => {
//       formData.append("files[]", file);
//     });

//     Object.keys(data.data).forEach((d) => {
//       formData.append(d, data.data[d]);
//     });

//     fetch(authUrl(url), {
//       headers: {
//         Accept: "application/json",
//       },
//       credentials: "include",
//       method: "POST",
//       body: formData,
//     })
//       .then((res) => res.json())
//       .then((json) => resolve(json));
//   });
