import React, { Component } from "react";
import { withTranslate } from "react-redux-multilingual";

import { SlideUpDown } from "../../services/script";
import { ToastContainer } from "react-toastify";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import { connect } from "react-redux";
import { changeCurrency } from "../../actions";
import {
  getCurrentLanguage,
  setCurrentLanguage,
  getValueFromLanguageObject,
  setCurrencySymbol,
  getCurrentTheme,
  setCurrentTheme,
} from "../../store";
import { getTenantInfoUrl, getMasterDetailsIdUrl } from "../../services";
import { getCall } from "../../api/post";
import { clearLocalStorageReload } from "../../utils/utils";
import MainLoader from "../../animation/MainLoader";

const imageStyle = {
  padding: "10px",
  height: "100px",
  width: "110px",
};

const pStyle = {
  display: "inline-block",
  textAlign: "center",
  fontSize: "20px",
  padding: "20px",
};

class ThemeSettings extends Component {
  constructor(props) {
    super(props);

    const theme = getCurrentTheme();

    this.state = {
      divName: getCurrentLanguage() === "ar" ? "RTL" : "LTR",
      themeLayout: theme,
      currentLanguage: getCurrentLanguage(),
      masterDetailsObj: {},
      isLoading: true,
      isDarkMode: false,
    };
  }

  /*=====================
                             Tap on Top
                             ==========================*/
  componentWillMount() {
    window.addEventListener("scroll", this.handleScroll);
  }
  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }
  handleScroll = () => {
    if (document.documentElement.scrollTop > 600) {
      document.querySelector(".tap-top").style = "display: block";
    } else {
      document.querySelector(".tap-top").style = "display: none";
    }
  };
  clickToTop() {
    window.scroll({ top: 0, left: 0, behavior: "smooth" });
  }

  componentDidMount() {
    SlideUpDown("setting-title");
    if (this.state.currentLanguage === "ar") {
      document.body.classList.add("rtl");
    } else {
      document.body.classList.remove("rtl");
    }
    this.initMasterDetailsId().then(() => {
      this.setState({ isLoading: true }, () => {
        this.initTenantInfo().then(() => {
          this.setState({ isLoading: false });
        });
      });
    });
  }

  initMasterDetailsId = async () => {
    let masterDetailsObj = {};
    const publicUrl = window.location.pathname.split("/")[1];
    const masterDetailsIdUrl = getMasterDetailsIdUrl(publicUrl);
    const masterDetails = await getCall(masterDetailsIdUrl, []).then();
    if (masterDetails.error) {
      clearLocalStorageReload({ response: masterDetails });
    } else {
      if (masterDetails) {
        masterDetailsObj = masterDetails;
      }
    }
    this.setState({ masterDetailsObj: masterDetailsObj });
  };

  initTenantInfo = async () => {
    const { masterDetailsObj } = this.state;
    const { id } = masterDetailsObj || {};
    if (!id) {
      return;
    }
    const tenantInfoUrl = getTenantInfoUrl(id);
    const tenantInfo = await getCall(tenantInfoUrl, []);
    if (tenantInfo.error) {
      clearLocalStorageReload({ response: tenantInfo });
      this.loadErrorMessageAnimation(tenantInfo.error.message);
    } else {
      if (tenantInfo && tenantInfo.length > 0) {
        const tenantItem = tenantInfo[0];
        const { text } = tenantItem || {};
        const tenantSettings = JSON.parse(text);
        const { isDarkMode } = tenantSettings || {};
        if (isDarkMode) {
          this.setState({
            themeLayout: "Dark",
            isDarkMode: true,
          });
          document.body.classList.add("default-dark");
          setCurrentTheme("Dark");
        } else {
          this.setState({
            themeLayout: "Light",
          });
          setCurrentTheme("Light");
        }
      }
    }
  };

  loadErrorMessageAnimation = (message) => {
    this.setState({ errorMessage: message, showErrorMessageModal: true });
  };

  loadSuccessMessageAnimation = (message) => {
    this.setState({ successMessage: message, showSuccessMessageModal: true });
  };

  openSetting = () => {
    document.getElementById("setting_box").classList.add("open-setting");
    document.getElementById("setting-icon").classList.add("open-icon");
  };
  closeSetting = () => {
    document.getElementById("setting_box").classList.remove("open-setting");
    document.getElementById("setting-icon").classList.remove("open-icon");
  };

  // Color Picker
  changeColor(event, color) {
    var elems = document.querySelectorAll(".color-box li");
    [].forEach.call(elems, function(elemt) {
      elemt.classList.remove("active");
    });

    event.target.classList.add("active");
    document
      .getElementById("color")
      .setAttribute(
        "href",
        `${process.env.PUBLIC_URL}/assets/css/` + color + `.css`
      );
  }

  ChangeRtl(divName) {
    if (divName === "RTL") {
      document.body.classList.add("rtl");
      this.setState({ divName: "LTR" });
    } else {
      document.body.classList.remove("rtl");
      this.setState({ divName: "RTL" });
    }
  }

  changeThemeLayout() {
    const { themeLayout } = this.state;
    if (themeLayout === "Light") {
      this.setState({
        themeLayout: "Dark",
      });
      setCurrentTheme("Dark");
    } else {
      this.setState({
        themeLayout: "Light",
      });
      setCurrentTheme("Light");
    }
  }
  setLanguage = (locale) => {
    setCurrentLanguage(locale);
    setCurrencySymbol();
    if (locale === "ar") {
      changeCurrency("د.إ");
    } else if (locale === "zh") {
      changeCurrency("¥");
    } else {
      changeCurrency("₹");
    }

    if (locale === "ar") {
      this.ChangeRtl("RTL");
    } else {
      this.ChangeRtl("LTR");
    }
    this.setState({ currentLanguage: locale });

    window.location.reload();
  };

  onClickOfLanguageButton = () => {
    const { currentLanguage } = this.state;
    const border = {
      border: "2px #000 solid",
      background: "#F0F0F0",
    };
    const noBorder = { border: "0" };
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div>
            <i
              style={{
                float: "right",
                paddingTop: "10px",
                paddingRight: "10px",
              }}
              onClick={onClose}
              className="fa fa-times fa-2x"
            />
            <div style={{ padding: "40px" }}>
              <h3>
                {" "}
                {getValueFromLanguageObject("str_language_selection")
                  ? getValueFromLanguageObject("str_language_selection")
                  : "Language Selection"}
              </h3>
              <h4>
                {" "}
                {getValueFromLanguageObject("str_choose_your_language")
                  ? getValueFromLanguageObject("str_choose_your_language")
                  : "Choose Your Language"}{" "}
              </h4>
              <br />
              <div
                className="english-language"
                style={currentLanguage === "en" ? border : noBorder}
                onClick={() => {
                  this.setLanguage("en");
                  onClose();
                }}
              >
                <img
                  style={imageStyle}
                  src={`${process.env.PUBLIC_URL}/assets/images/united-states.png`}
                  alt=""
                />
                <p style={pStyle}>English</p>
              </div>
              <br />
              <div
                className="arabic-language"
                style={currentLanguage === "ar" ? border : noBorder}
                onClick={() => {
                  this.setLanguage("ar");
                  onClose();
                }}
              >
                <img
                  style={imageStyle}
                  src={`${process.env.PUBLIC_URL}/assets/images/united-arab-emirates.png`}
                  alt=""
                />
                <p style={pStyle}>Arabic(عربى)</p>
              </div>
              <br />
              {/* <div
                style={currentLanguage === "zh" ? border : noBorder}
                onClick={() => {
                  this.setLanguage("zh");
                  onClose();
                }}
              >
                <img
                  style={imageStyle}
                  src={`${process.env.PUBLIC_URL}/assets/images/china.png`}
                  alt=""
                />
                <p style={pStyle}>Chinese(中文)</p>
              </div> */}
              {/* <br />
              <br />
              <button className="btn btn-solid" onClick={onClose}>
                {getValueFromLanguageObject("str_close")
                  ? getValueFromLanguageObject("str_close")
                  : "Close"}
              </button> */}
            </div>
          </div>
        );
      },
    });
  };

  render() {
    const { isLoading, isDarkMode } = this.state;

    if (this.state.themeLayout === "Dark") {
      document.body.classList.add("dark");
    } else {
      document.body.classList.remove("dark");
    }
    let tap_to_top = { display: "none" };

    return (
      <>
        <div>
          {/* <div onClick={() => this.onClickOfLanguageButton()}>
          <div className="language_btn" id="setting-icon">
            <div>
              <i className="fa fa-language" aria-hidden="true" />
            </div>
          </div>
        </div> */}
          <div
            className={`sidebar-btn dark-light-btn ${
              isDarkMode ? "d-none" : ""
            }`}
            onClick={() => this.changeThemeLayout()}
          >
            <div className="dark-light">
              <div className="theme-layout-version">
                {this.state.themeLayout === "Dark"
                  ? `${
                      getValueFromLanguageObject("str_light")
                        ? getValueFromLanguageObject("str_light")
                        : "Light"
                    }`
                  : `${
                      getValueFromLanguageObject("str_dark")
                        ? getValueFromLanguageObject("str_dark")
                        : "Dark"
                    } `}
              </div>
            </div>
          </div>
          <div className="tap-top" onClick={this.clickToTop} style={tap_to_top}>
            <div>
              <i className="fa fa-angle-double-up" />
            </div>
          </div>

          <ToastContainer />
        </div>
        {isLoading && <MainLoader />}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  //   currentLanguage: state.language.currentLanguage,
});

export default connect(mapStateToProps, {
  // initLanguage: initLanguage,
})(withTranslate(ThemeSettings));
// export default withTranslate(ThemeSettings);
