/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
import KTSVG from "../components/KTSVG";
import { getCurrentDate } from "../xeUtils/xeUtils";
import { connect } from "react-redux";

class CurrencyMatrixTable extends Component {
  renderCurrencyList = () => {
    const { currencyList } = this.props;
    const { selectedBaseCurrency } = this.props;
    const [currencies] = currencyList.filter(
      (currency) => currency.currencyLabel === selectedBaseCurrency
    );

    const { currencyValue = [] } = currencies || {};

    return currencyValue
      .filter((currency) => currency.currencyLabel !== selectedBaseCurrency)
      .map((currency, index) => {
        const {
          currencyLabel,
          currencyName,
          currencySymbol,
          buy,
          sale,
          currencyStatus,
        } = currency || {};
        return (
          <>
            {currencyStatus && (
              <tr key={index}>
                <td>
                  <div className="d-flex flex-row flex-grow-1">
                    <div className="currency-name">
                      <div className="flag-icon mr-3">
                        <KTSVG
                          path={`/media/flags/${currencyLabel}.svg`}
                          className="svg-icon-4 rounded"
                        />
                      </div>
                    </div>

                    <div className="currency-country d-flex align-items-md-center flex-grow-1 justify-content-between flex-column flex-md-row">
                      <span className="text-dark font-weight-bold text-hover-primary fs-6">
                        {currencyLabel}
                      </span>
                      <span className="text-muted font-weight-normal text-muted d-block fs-8 fs-md-7">
                        {currencyName}
                      </span>
                    </div>
                  </div>
                </td>
                <td className="text-center">
                  <span className="text-dark fw-bolder text-hover-primary fs-6">
                    {currencySymbol}
                    {buy}
                  </span>
                </td>
                <td className="text-center">
                  <span className="text-dark fw-bolder text-hover-primary fs-6">
                    {currencySymbol}
                    {sale}
                  </span>
                </td>
              </tr>
            )}{" "}
          </>
        );
      });
  };

  render() {
    const { className } = this.props;
    return (
      <>
        <div className={`card ${className}`}>
          {/* begin::Header */}
          <div className="card-header border-0 pt-5 align-items-center">
            <h3 className="card-title align-items-end">
              <span className="card-label font-weight-bold fs-3 mb-1">
                Currency Exchange Rate
              </span>
            </h3>
            <div className="card-toolbar">
              Last updated on {getCurrentDate()} IST
            </div>
          </div>
          {/* end::Header */}
          {/* begin::Body */}
          <div className="card-body py-3 mobile-table">
            {/* begin::Table container */}
            <div className="table-responsive">
              {/* begin::Table */}
              <table className="theme-table table table-row-dashed table-row-gray-300 align-middle gy-4">
                {/* begin::Table head */}
                <thead>
                  <tr className="fw-bolder text-muted">
                    <th className="min-w-150px">Currency</th>
                    <th className="min-w-120px text-center">Buy Rate</th>
                    <th className="min-w-120px text-center">Sell Rate</th>
                  </tr>
                </thead>
                {/* end::Table head */}
                {/* begin::Table body */}
                <tbody>{this.renderCurrencyList()}</tbody>
                {/* end::Table body */}
              </table>
              {/* end::Table */}
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  selectedBaseCurrency: state.settings.baseCurrency,
});
export default connect(mapStateToProps, {})(CurrencyMatrixTable);
