import React, { Component } from "react";
import { Helmet } from "react-helmet";
import "../../common/index.scss";
import Breadcrumb from "../../common/breadcrumb";
import { withRouter } from "react-router-dom";
import { customPostCall, getCall, patchCall } from "../../../api/post";
import {
  getMasterDetailsIdUrl,
  getPaymentDetailsUrl,
  getSadadPaymentChecksumValidateUrl,
} from "../../../services";
import queryString from "query-string";
import {
  clearLocalStorageReload,
  isOpenStoreIdExist,
  navigateTo,
  removeUserDetailAndOtherDetails,
} from "../../../utils/utils";
import ErrorMessageAnimationModal from "../../../animation/ErrorMessageAnimationModal";
import SuccessMessageAnimationModal from "../../../animation/SuccessMessageAnimationModal";
import {
  getValueFromLanguageObject,
  loadUserDetailsFromLocalStorage,
} from "../../../store";

class SadadPaymentResponse extends Component {
  constructor() {
    super();
    this.state = {
      sadadPaymentChecksum: "",
      showErrorMessageModal: false,
      errorMessage: "",
      showSuccessMessageModal: "",
      successMessage: "",
    };
  }

  componentDidMount() {
    const userDetails = loadUserDetailsFromLocalStorage();
    if (!isOpenStoreIdExist() && userDetails === undefined) {
      navigateTo(this.props, `/login`);
      return;
    }

    this.initMasterDetailsId().then(() => {
      this.initPaymentDetails().then(() => {
        this.initValidateSadadPaymentChecksum();
      });
    });
  }

  initMasterDetailsId = async () => {
    let masterDetailsObj = {};
    const publicUrl = window.location.pathname.split("/")[1];
    const masterDetailsIdUrl = getMasterDetailsIdUrl(publicUrl);
    const masterDetails = await getCall(masterDetailsIdUrl, []).then();
    if (masterDetails.error) {
      clearLocalStorageReload({ response: masterDetails });
    } else {
      if (masterDetails) {
        masterDetailsObj = masterDetails;
      }
    }
    this.setState({ masterDetailsObj: masterDetailsObj });
  };

  initPaymentDetails = async () => {
    const { masterDetailsObj } = this.state;
    const paymentDetailsUrl = getPaymentDetailsUrl(masterDetailsObj);
    const paymentDetails = await getCall(paymentDetailsUrl, []);
    if (paymentDetails.error) {
      clearLocalStorageReload({ response: paymentDetails });
      this.loadErrorMessageAnimation(paymentDetails.error.message);
    } else {
      this.setState({ paymentDetails });
    }
  };

  // http://localhost:3001/nutland/sadad-response?website_ref_no=&transaction_status=3&transaction_number=SD2510885266759&MID=3766251&RESPCODE=1&RESPMSG=Txn+Success&ORDERID=SD3214578995&STATUS=TXN_SUCCESS&TXNAMOUNT=6&checksumhash=gZsgi96JD59vQsoFEvbOE6P2CGNcfFvu2UIYiA%2FT0oVl7LnIpBR9bTg1FO9xA2eTRL%2BM4%2FZdAnjqmozFKzIDtbDcd%2BVsv1Vh6yiXFPCfYhI%3D
  initValidateSadadPaymentChecksum = async () => {
    const { paymentDetails } = this.state;
    let params = queryString.parse(this.props.location.search);
    const {
      website_ref_no,
      transaction_status,
      transaction_number,
      MID,
      RESPCODE,
      RESPMSG,
      ORDERID,
      STATUS,
      TXNAMOUNT,
      checksumhash,
      orderId,
    } = params;

    const paytmPaymentMethodDetails = paymentDetails[0].text.filter(
      (paymentMethods) => {
        return paymentMethods.name === "Sadad";
      }
    );
    const secretKey = paytmPaymentMethodDetails[0].config.MERCHANT_KEY;

    let checksumObj = {
      website_ref_no,
      transaction_status,
      transaction_number,
      MID,
      RESPCODE,
      RESPMSG,
      ORDERID,
      STATUS,
      TXNAMOUNT,
      checksumhash,
    };

    const sadadPaymentChecksumValidateUrl = getSadadPaymentChecksumValidateUrl();
    const checksumResponse = await customPostCall(
      sadadPaymentChecksumValidateUrl,
      checksumObj,
      secretKey
    );
    if (transaction_status !== "3") {
      this.loadErrorMessageAnimation(
        getValueFromLanguageObject(
          "str_payment_is_failed_please_try_again_later!"
        )
          ? getValueFromLanguageObject(
              "str_payment_is_failed_please_try_again_later!"
            )
          : "Payment is failed, Please try again later!"
      );
    } else {
      this.loadSuccessMessageAnimation(
        getValueFromLanguageObject("str_payment_successfull")
          ? getValueFromLanguageObject("str_payment_successful")
          : "Payment Successfull!"
      );
    }
    this.setState({ sadadPaymentChecksum: checksumResponse.checksum });

    if (checksumResponse && checksumResponse.result) {
      this.updatePaymentDetailsAfterPayment(2);
    } else {
      this.updatePaymentDetailsAfterPayment(3);
    }
  };

  updatePaymentDetailsAfterPayment = async (paymentStatus) => {
    let params = queryString.parse(this.props.location.search);
    const {
      website_ref_no,
      transaction_status,
      transaction_number,
      MID,
      RESPCODE,
      RESPMSG,
      ORDERID,
      STATUS,
      TXNAMOUNT,
      checksumhash,
      orderId,
    } = params;

    const updatePaymentStatusUrl = `orders/${orderId}`;
    // TODO Add Transaction Status doubt

    const updatePaymentStatusObj = {
      paymentDetail: {
        transaction_no: transaction_number,
        other_transaction_details: params,
        pay_via: "Sadad",
      },
      order_id: ORDERID,
      paymentstatus: paymentStatus,
    };
    const updatePaymentStatus = await patchCall(
      updatePaymentStatusUrl,
      updatePaymentStatusObj
    );

    if (updatePaymentStatus.error) {
      clearLocalStorageReload({ response: updatePaymentStatus });
      this.loadErrorMessageAnimation(updatePaymentStatus.error.message);
      if (updatePaymentStatus.error.statusCode === 401) {
        removeUserDetailAndOtherDetails();
        navigateTo(this.props, `/login`);
      }
      return;
    }

    setTimeout(() => {
      this.props.history.push(`/orderdetails/${orderId}`);
    }, 750);
  };

  loadErrorMessageAnimation = (message) => {
    this.setState({ errorMessage: message, showErrorMessageModal: true });
  };

  loadSuccessMessageAnimation = (message) => {
    this.setState({ successMessage: message, showSuccessMessageModal: true });
  };

  render() {
    const {
      showErrorMessageModal,
      errorMessage,
      showSuccessMessageModal,
      successMessage,
    } = this.state;
    return (
      <div>
        {/*SEO Support*/}
        <Helmet>
          <title>
            {getValueFromLanguageObject("str_payment_response")
              ? getValueFromLanguageObject("str_payment_response")
              : "Payment Response"}
          </title>
          <meta
            name="description"
            content="Multikart – Multipurpose eCommerce React Template is a multi-use React template. It is designed to go well with multi-purpose websites. Multikart Bootstrap 4 Template will help you run multiple businesses."
          />
        </Helmet>
        {/*SEO Support End */}

        <Breadcrumb parent={"HOME"} title={"Sample Sadad Page"} />
        {showErrorMessageModal && (
          <ErrorMessageAnimationModal
            show={showErrorMessageModal}
            message={errorMessage}
            onClose={() => {
              this.setState({ showErrorMessageModal: false });
            }}
          />
        )}
        {showSuccessMessageModal && (
          <SuccessMessageAnimationModal
            show={showSuccessMessageModal}
            message={successMessage}
            onClose={() => {
              this.setState({ showSuccessMessageModal: false });
            }}
          />
        )}
      </div>
    );
  }
}

export default withRouter(SadadPaymentResponse);
