import React, { Component } from "react";
import { Helmet } from "react-helmet";
import FilterBar from "../../collection/common/filter-bar";
import ProductListing from "../../collection/common/product-listing";
import StickyBox from "react-sticky-box";
import Breadcrumb from "../../common/breadcrumb";
import NewProduct from "../../common/new-product";
import Filter from "../../collection/common/filter";
import { getCall, postCall } from "../../../api/post";
import {
  loadUserDetailsFromLocalStorage,
  getValueFromLanguageObject,
  getOpenStoreIdApp,
} from "../../../store";
import ProductLoaderMain from "../contentloader/product-loader";

import { connect } from "react-redux";
import {
  getCommonCounter,
  getNotificationCounter,
  getUpdateQuantity,
} from "../../../actions";
import {
  getMerchantInformationUrl,
  getMasterDetailsIdUrl,
  getProductListingUrl,
  getCategoriesUrl,
  getSubCategoriesOfParentCategoryUrl,
} from "../../../services";
import ErrorMessageAnimationModal from "../../../animation/ErrorMessageAnimationModal";
import MainLoader from "../../../animation/MainLoader";
import {
  clearLocalStorageReload,
  isOpenStoreIdExist,
  navigateTo,
  removeUserDetailAndOtherDetails,
} from "../../../utils/utils";
import { withRouter } from "react-router-dom";
import { debuglogger } from "../../../utils/logger";
import AddToCartAnim from "../../../animation/AddToCartAnim";
import NoSpecialProductFoundAnim from "../../../animation/NoSpecialProductFoundAnim";
import QuickViewProductDetailsModal from "../dashboard/quick-view-product-details-modal";

class Products extends Component {
  state = {
    layoutColumns: 3,
    productList: [],
    newArrival: [],
    skip: 0,
    subCategoryId: "",
    categoryId: "",
    searchValue: "",
    isLoading: false,
    sortingOrder: 0,
    offerBanners: [],
    isLoaderVisible: true,
    AppTitle: "",
    showErrorMessageModal: false,
    errorMessage: "",
    masterDetailsObj: {},
    categories: [],
    limit: 20,
    isPaginationEnableForProductList: false,
    selectedCategory: {},
    subCategories: [],
    selectedSubCategory: {},
    showAddToCartMessageModal: false,
    addToCartMessage: "",
    searchMaxPrice: "",
    searchMinPrice: "",
    isContentLoaderVisible: false,
    isQuickViewProductDetailsModalShow: false,
    category: {},
  };

  LayoutViewClicked(colums) {
    this.setState({
      layoutColumns: colums,
    });
  }

  componentDidMount() {
    const userDetails = loadUserDetailsFromLocalStorage();
    if (!isOpenStoreIdExist() && userDetails === undefined) {
      navigateTo(this.props, `/login`);
      return;
    }
    this.setState({ isLoading: true }, () => {
      this.initMasterDetailsId().then(() => {
        const { path, params } = this.props.match || {};
        const { id } = params || {};
        if (path && path === "/c/:id") {
          this.setState({ categoryId: id, productList: [], skip: 0 }, () => {
            this.initSingleCategry().then(() => {
              this.initSubCategory();
            });
            this.initProductList().then(() => {
              this.setState({ isLoading: false });
            });
          });
        } else if (path && path === "/s/:id") {
          this.setState({ subCategoryId: id, productList: [], skip: 0 }, () => {
            this.initSingleSubCategry(id).then(() => {
              const { selectedSubCategory } = this.state;
              const { parentId } = selectedSubCategory;
              this.setState({ categoryId: parentId }, () => {
                this.initSingleCategry().then(() => {
                  this.initSubCategory();
                });
              });
            });

            this.setState({ isLoading: true }, () => {
              this.initProductList().then(() => {
                this.setState({ isLoading: false });
              });
            });
          });
        } else {
          this.setState({ isLoading: true }, () => {
            this.initProductList().then(() => {
              this.setState({ isLoading: false });
            });
          });
        }

        this.initMerchantInfo();
        this.initCategory();
        this.initProductListNewProduct();
        this.props.notificationCounter();
      });
    });
  }

  UNSAFE_componentWillUpdate(nextProps, newState) {
    // console.log("UNSAFE_componentWillUpdate");
    const { path, params } = this.props.match || {};
    const { path: nextPath, params: nextParams } = nextProps.match || {};
    const { id: idNew } = params || {};
    const { id } = nextParams || {};
    if (nextPath && (path !== nextPath || id !== idNew)) {
      if (nextPath === "/c/:id") {
        console.log("/c/:id");
        this.setState(
          {
            categoryId: id,
            selectedSubCategory: {},
            subCategoryId: "",
            productList: [],
            skip: 0,
          },
          () => {
            this.setState({ isLoading: true }, () => {
              this.initSingleCategry().then(() => {
                this.initSubCategory();
              });
              this.initProductList().then(() => {
                this.setState({ isLoading: false });
              });
            });
          }
        );
      } else if (nextPath === "/s/:id") {
        this.setState(
          { subCategoryId: id, isLoading: true, productList: [], skip: 0 },
          () => {
            this.initSingleSubCategry(id).then(() => {
              const { selectedSubCategory } = this.state;
              const { parentId } = selectedSubCategory;
              this.setState({ categoryId: parentId }, () => {
                this.initSingleCategry().then(() => {
                  this.initSubCategory();
                });
              });
              this.setState({}, () => {
                this.initProductList().then(() => {
                  this.setState({ isLoading: false });
                });
              });
            });
          }
        );
      } else {
        this.setState(
          {
            isLoading: true,
            subCategoryId: "",
            categoryId: "",
            selectedCategory: {},
            selectedSubCategory: {},
            skip: 0,
            searchValue: "",
            sortingOrder: 0,
            isPaginationEnableForProductList: false,
            subCategories: [],
            searchMaxPrice: "",
            searchMinPrice: "",
            productList: [],
          },
          () => {
            this.initProductList().then(() => {
              this.setState({ isLoading: false });
            });
          }
        );
      }
    }
  }

  initMasterDetailsId = async () => {
    let masterDetailsObj = {};
    const publicUrl = window.location.pathname.split("/")[1];
    const masterDetailsIdUrl = getMasterDetailsIdUrl(publicUrl);
    const masterDetails = await getCall(masterDetailsIdUrl, []).then();
    if (masterDetails.error) {
      clearLocalStorageReload({ response: masterDetails });
    } else {
      if (masterDetails) {
        masterDetailsObj = masterDetails;
      }
    }
    this.setState({ masterDetailsObj: masterDetailsObj });
  };

  initMerchantInfo = async () => {
    const { masterDetailsObj } = this.state;
    const merchantInfoUrl = getMerchantInformationUrl(masterDetailsObj);
    const merchantInfo = await getCall(merchantInfoUrl, []);
    if (merchantInfo.error) {
      clearLocalStorageReload({ response: merchantInfo });
      this.setState({
        isMerchantGSTEnable: false,
        merchantInfo: {},
        AppTitle: "",
      });
    } else {
      this.setState({
        merchantInfo,
        AppTitle: merchantInfo.companyname,
        isMerchantGSTEnable: merchantInfo.enablegst === 0 ? false : true,
      });
    }
  };

  initCategory = async () => {
    const categoryUrl = getCategoriesUrl();
    const categories = await getCall(categoryUrl, []);
    if (categories.error) {
      clearLocalStorageReload({ response: categories });
      this.loadErrorMessageAnimation(categories.error.message);
    } else {
      this.setState({ categories: categories.data });
    }
  };

  initSubCategory = async () => {
    const { selectedCategory } = this.state;
    const { id } = selectedCategory || {};
    if (id) {
      const subcategoryUrl = getSubCategoriesOfParentCategoryUrl(id);
      const subCategories = await getCall(subcategoryUrl, []);
      if (subCategories.error) {
        clearLocalStorageReload({ response: subCategories });
        this.loadErrorMessageAnimation(subCategories.error.message);
      } else {
        this.setState({ subCategories });
      }
    }
  };

  initSingleCategry = async () => {
    const { categoryId } = this.state;
    if (categoryId !== null) {
      const singleCategoryUrl = "categories/" + categoryId;
      const singleCategory = await getCall(singleCategoryUrl, []);
      if (singleCategory.error) {
        clearLocalStorageReload({ response: singleCategory });
        this.loadErrorMessageAnimation(singleCategory.error.message);
        if (singleCategory.error.statusCode === 401) {
          removeUserDetailAndOtherDetails();
          navigateTo(this.props, `/login`);
          return;
        }
      } else {
        this.setState({ selectedCategory: singleCategory });
      }
    }
  };

  initSingleSubCategry = async () => {
    const { subCategoryId } = this.state;
    if (subCategoryId !== null) {
      const singleCategoryUrl = "categories/" + subCategoryId;
      const singleCategory = await getCall(singleCategoryUrl, []);
      if (singleCategory.error) {
        clearLocalStorageReload({ response: singleCategory });
        this.loadErrorMessageAnimation(singleCategory.error.message);
        if (singleCategory.error.statusCode === 401) {
          removeUserDetailAndOtherDetails();
          navigateTo(this.props, `/login`);
          return;
        }
      } else {
        this.setState({ selectedSubCategory: singleCategory });
      }
    }
  };

  initSingleAsParentCategry = async (product) => {
    const { category } = product || {};
    const { parentId } = category || {};
    if (parentId !== null) {
      const singleCategoryUrl = "categories/" + parentId;
      debuglogger("singleCategoryUrl", singleCategoryUrl);
      const singleCategory = await getCall(singleCategoryUrl, []);
      if (singleCategory.error) {
        clearLocalStorageReload({ response: singleCategory });
        this.loadErrorMessageAnimation(singleCategory.error.message);
        if (singleCategory.error.statusCode === 401) {
          removeUserDetailAndOtherDetails();
          navigateTo(this.props, `/login`);
          return;
        }
      } else {
        this.setState({ category: singleCategory });
      }
    }
  };

  initProductListNewProduct = async () => {
    const productListUrl =
      "products?filter[include]=productbrand&filter[include]=productmedia&filter[include]=category&filter[where][productstatus]=1&filter[where][availablequantity][gt]=0&filter[order][0]=created DESC&filter[skip]=0&filter[limit]=20";

    const newArrival = await getCall(productListUrl, []);
    if (newArrival.error) {
      clearLocalStorageReload({ response: newArrival });
      this.loadErrorMessageAnimation(newArrival.error.message);
      if (newArrival.error.statusCode === 401) {
        removeUserDetailAndOtherDetails();
        navigateTo(this.props, `/login`);
        return;
      }
    } else {
      this.setState({ newArrival });
    }
  };

  openFilter = () => {
    document.querySelector(".collection-filter").style = "left: -15px";
  };

  initProductList = async () => {
    try {
      const {
        categoryId,
        subCategoryId,
        sortingOrder,
        skip,
        productList,
        searchValue,
        limit,
        searchMinPrice,
        searchMaxPrice,
      } = this.state;
      const productListUrl = getProductListingUrl(
        categoryId,
        sortingOrder,
        subCategoryId,
        searchValue,
        skip,
        limit,
        searchMinPrice,
        searchMaxPrice
      );
      const products = await getCall(productListUrl, []);
      this.setState({ isContentLoaderVisible: false });
      this.setState({ isLoading: false });
      this.setState({ isLoaderVisible: false });
      if (products.error) {
        clearLocalStorageReload({ response: products });
        this.loadErrorMessageAnimation(products.error.message);
        if (products.error.statusCode === 401) {
          removeUserDetailAndOtherDetails();
          navigateTo(this.props, `/login`);
          return;
        }
      } else {
        if (products && limit <= products.length) {
          this.setState({ isPaginationEnableForProductList: true });
        } else {
          this.setState({ isPaginationEnableForProductList: false });
        }
        if (skip > 0) {
          const pl = productList.concat(products);
          this.setState({ productList: pl });
        } else {
          this.setState({ productList: products });
        }
      }
    } catch (error) {
      this.setState({ isLoading: false });
    }
  };

  handleCategoryWiseProductListFilter = () => {
    this.setState(
      {
        skip: 0,
        isLoading: true,
      },
      () => {
        this.initSubCategory();
        this.initProductList().then(() => {
          this.setState({ isLoading: false });
        });
      }
    );
  };

  handleSubCategoryWiseProductListFilter = () => {
    this.setState(
      {
        skip: 0,
        isLoading: true,
      },
      () => {
        this.initProductList().then(() => {
          this.setState({ isLoading: false });
        });
      }
    );
  };

  handleSortingProductFilter = (order) => {
    this.setState(
      {
        sortingOrder: order,
        skip: 0,
        isContentLoaderVisible: true,
        productList: [],
      },
      () => {
        this.initProductList().then(() => {
          this.setState({ isLoading: false });
        });
      }
    );
  };

  handlePagination = (skip) => {
    this.setState({ skip }, () => {
      this.initProductList();
    });
  };

  onSearchProduct = (value) => {
    this.setState(
      {
        searchValue: value,
        skip: 0,
        isContentLoaderVisible: true,
        productList: [],
      },
      () => {
        this.initProductList();
      }
    );
  };

  onSearchPrice = (min, max) => {
    this.setState(
      {
        searchMinPrice: min,
        searchMaxPrice: max,
        skip: 0,
        isContentLoaderVisible: true,
        productList: [],
      },
      () => {
        this.initProductList().then(() => {
          this.setState({ isLoading: false });
        });
      }
    );
  };

  onSearchMaxPrice = (value) => {
    this.setState({ searchMaxPrice: value, skip: 0, isLoading: true }, () => {
      this.initProductList();
    });
  };

  onSearchMinPrice = (value) => {
    this.setState({ searchMinPrice: value, skip: 0, isLoading: true }, () => {
      this.initProductList();
    });
  };

  clearFilter = () => {
    this.setState(
      {
        searchMinPrice: "",
        searchMaxPrice: "",
        skip: 0,
        isContentLoaderVisible: true,
        productList: [],
      },
      () => {
        this.initProductList().then(() => {
          this.setState({ isLoading: false });
        });
      }
    );
  };

  handleLoader = (isLoading) => {
    this.setState({ isLoading });
  };

  loadErrorMessageAnimation = (message) => {
    this.setState({ errorMessage: message, showErrorMessageModal: true });
  };

  handleOnClickOfParentCategory = (category) => {
    const { id } = category;
    this.setState(
      {
        selectedCategory: category,
        categoryId: id,
        subCategoryId: "",
        selectedSubCategory: {},
      },
      () => {
        this.props.history.replace(`/c/${id}`);
        this.handleCategoryWiseProductListFilter();
      }
    );
  };

  handleOnClickOfSubCategory = (subCategory) => {
    const { id } = subCategory || {};
    this.setState(
      { selectedSubCategory: subCategory, subCategoryId: id },
      () => {
        this.props.history.replace(`/s/${id}`);
        this.handleSubCategoryWiseProductListFilter();
      }
    );
  };

  loadAddToCartMessageAnimation = (message) => {
    this.setState({
      addToCartMessage: message,
      showAddToCartMessageModal: true,
    });
  };

  clearFilterOnClickOfAll = () => {
    this.setState({ isLoading: true }, () => {
      this.initCategory().then(() => {
        this.setState({
          isLoading: false,
          selectedCategory: {},
          categoryId: "",
          subCategoryId: "",
          selectedSubCategory: {},
        });
        this.initSubCategory().then(() => {
          this.setState({ isLoading: false, subCategories: [] });
        });
        this.initSingleCategry();
        this.initProductList();
      });
    });
  };

  addToCart = async (product, qty, isInquiry) => {
    try {
      debuglogger("product", product);
      if (
        product &&
        product.productvariation &&
        product.productvariation !== null &&
        product.productvariation !== "[]"
      ) {
        navigateTo(this.props, `/singleproduct/${product.id}`);
        return;
      }

      this.setState({ isLoading: true });
      let userDetails = loadUserDetailsFromLocalStorage();
      if (!isOpenStoreIdExist() && userDetails === undefined) {
        return;
      } else {
        userDetails = userDetails === undefined ? {} : userDetails;
      }
      const { id, cityId } = userDetails;
      const openStoreId = getOpenStoreIdApp();
      let userId = openStoreId ? openStoreId : id;

      const addtocartUrl = isInquiry
        ? "orders/productInquiry"
        : "orders/addtocart";

      let addtocartObj = {
        orderstatus: 1,
        inshoppingcart: 1,
        userId,
        totalamount: isInquiry ? 0 : product.price * qty,
        orderdetails: [
          {
            quantity: qty,
            productId: product.id,
          },
        ],
        cityId: cityId,
        date: new Date(),
      };

      const addToCartResponse = await postCall(addtocartUrl, addtocartObj);
      this.setState({ isLoading: false });
      if (addToCartResponse.error) {
        clearLocalStorageReload({ response: addToCartResponse });
        this.loadErrorMessageAnimation(addToCartResponse.error.message);
        if (addToCartResponse.error.statusCode === 401) {
          removeUserDetailAndOtherDetails();
          navigateTo(this.props, `/login`);
          return;
        }
      } else {
        if (isInquiry) {
          this.loadAddToCartMessageAnimation(
            getValueFromLanguageObject("str_add_to_inquiry_successfully")
              ? getValueFromLanguageObject("str_add_to_inquiry_successfully")
              : "Product inquiry added successfully"
          );
        } else {
          this.loadAddToCartMessageAnimation(
            getValueFromLanguageObject("str_add_to_cart_successfully")
              ? getValueFromLanguageObject("str_add_to_cart_successfully")
              : "Product added to cart successfully."
          );
        }
        this.hideQuickViewProductDetailsModal();
      }
      this.props.counter();
      this.props.updateQuantity();
      this.setState({ skip: 0 }, () => {
        this.initProductList();
      });
    } catch (error) {
      this.setState({ isLoading: false });
    }
  };

  showQuickViewProductDetailsModal = () => {
    this.setState({ isQuickViewProductDetailsModalShow: true });
  };

  hideQuickViewProductDetailsModal = () => {
    this.setState({ isQuickViewProductDetailsModalShow: false });
  };

  handleClickOfQuickView = (product) => {
    this.setState({ product }, () => {
      this.initSingleAsParentCategry(product);
      this.showQuickViewProductDetailsModal();
    });
  };

  render() {
    const {
      productList,
      newArrival,
      isLoading,
      isLoaderVisible,
      AppTitle,
      showErrorMessageModal,
      errorMessage,
      isPaginationEnableForProductList,
      limit,
      layoutColumns,
      selectedCategory,
      selectedSubCategory,
      categories,
      subCategories,
      categoryId,
      subCategoryId,
      showAddToCartMessageModal,
      addToCartMessage,
      product,
      category,
      isQuickViewProductDetailsModalShow,
      masterDetailsObj,
    } = this.state;
    const { symbol, isInquiry } = this.props;

    return (
      <>
        <div>
          {/*SEO Support*/}
          <Helmet>
            <title>
              {AppTitle} |{" "}
              {getValueFromLanguageObject("str_products")
                ? getValueFromLanguageObject("str_products")
                : "Products"}{" "}
            </title>
            <meta
              name="description"
              content="Multikart – Multipurpose eCommerce React Template is a multi-use React template. It is designed to go well with multi-purpose websites. Multikart Bootstrap 4 Template will help you run multiple businesses."
            />
          </Helmet>
          {/*SEO Support End */}

          <Breadcrumb
            title={
              getValueFromLanguageObject("str_products")
                ? getValueFromLanguageObject("str_products")
                : "Products"
            }
            parentCategory={selectedCategory} //parent category
            subCategory={selectedSubCategory} //subcategory
          />

          <section className="section-b-space">
            <div className="collection-wrapper">
              <div className="container">
                <div className="row">
                  <div className="col-sm-3 collection-filter">
                    <StickyBox offsetTop={20} offsetBottom={20}>
                      <div>
                        <Filter
                          categories={categories}
                          subCategories={subCategories}
                          categoryId={categoryId}
                          subCategoryId={subCategoryId}
                          onClickOfParentCategory={
                            this.handleOnClickOfParentCategory
                          }
                          onClickOfSubCategory={this.handleOnClickOfSubCategory}
                          clearFilterOnClickOfAll={this.clearFilterOnClickOfAll}
                        />

                        <NewProduct newProducts={newArrival} />
                      </div>
                    </StickyBox>
                    {/*side-bar banner end here*/}
                  </div>

                  <div className="collection-content col">
                    <div className="page-main-content ">
                      <div className="">
                        <div className="row">
                          <div className="col-sm-12">
                            <div className="top-banner-wrapper" />

                            <div className="row mb-3 search-product">
                              <div className="col-md-6 col-sm-12 search-form subscribe-form justify-content-start">
                                <input
                                  type="text"
                                  className="form-control"
                                  id="search-product"
                                  onChange={(e) => {
                                    this.onSearchProduct(e.target.value);
                                  }}
                                  placeholder={
                                    getValueFromLanguageObject(
                                      "str_search_products"
                                    )
                                      ? getValueFromLanguageObject(
                                          "str_search_products"
                                        )
                                      : "Search Products"
                                  }
                                />
                              </div>
                              <div className="col-md-6 col-sm-12 search-form subscribe-form d-flex justify-content-end">
                                <div className="form-group mb-0 ml-0 mr-0 d-flex">
                                  {/* <input
                                    type="text"
                                    className="form-control"
                                    id="search-product"
                                    onChange={(e) => {
                                      this.onSearchProduct(e.target.value);
                                    }}
                                    placeholder={
                                      getValueFromLanguageObject(
                                        "str_search_products"
                                      )
                                        ? getValueFromLanguageObject(
                                            "str_search_products"
                                          )
                                        : "Search Products"
                                    }
                                  /> */}
                                  <input
                                    value={this.state.searchMinPrice}
                                    style={{
                                      width: "150px",
                                      marginRight: "7px",
                                      marginLeft: "30px",
                                    }}
                                    type="number"
                                    className="form-control"
                                    id="search-min-price"
                                    onChange={(e) => {
                                      this.setState({
                                        searchMinPrice: e.target.value,
                                      });
                                      // if (e.target.value === "") {
                                      //   this.onSearchMinPrice(e.target.value);
                                      // } else {
                                      //   this.setState({
                                      //     searchMinPrice: e.target.value,
                                      //   });
                                      // }
                                    }}
                                    placeholder={
                                      getValueFromLanguageObject(
                                        "str_min_price"
                                      )
                                        ? getValueFromLanguageObject(
                                            "str_min_price"
                                          )
                                        : "Min Price"
                                    }
                                  />
                                  <input
                                    value={this.state.searchMaxPrice}
                                    style={{
                                      width: "150px",
                                    }}
                                    type="number"
                                    className="form-control"
                                    id="search-max-price"
                                    onChange={(e) => {
                                      this.setState({
                                        searchMaxPrice: e.target.value,
                                      });
                                      // if (e.target.value === "") {
                                      //   this.onSearchMaxPrice(e.target.value);
                                      // } else {
                                      //   this.setState({
                                      //     searchMaxPrice: e.target.value,
                                      //   });
                                      // }
                                    }}
                                    placeholder={
                                      getValueFromLanguageObject(
                                        "str_max_price"
                                      )
                                        ? getValueFromLanguageObject(
                                            "str_max_price"
                                          )
                                        : "Max Price"
                                    }
                                  />

                                  <button
                                    onClick={() => {
                                      const {
                                        searchMinPrice,
                                        searchMaxPrice,
                                      } = this.state;
                                      this.onSearchPrice(
                                        searchMinPrice,
                                        searchMaxPrice
                                      );
                                    }}
                                    className="btn btn-solid product-search ml-3"
                                    type="submit"
                                    id=""
                                  >
                                    {getValueFromLanguageObject("apply")
                                      ? getValueFromLanguageObject("apply")
                                      : "Apply"}
                                  </button>
                                  <button
                                    onClick={() => {
                                      this.clearFilter();
                                    }}
                                    className="btn btn-solid product-search ml-3"
                                    type="submit"
                                    id=""
                                  >
                                    {getValueFromLanguageObject("str_clear")
                                      ? getValueFromLanguageObject("str_clear")
                                      : "Clear"}
                                  </button>
                                </div>
                              </div>
                            </div>
                            <div className="collection-product-wrapper">
                              <div className="product-top-filter">
                                <div className="container-fluid p-0">
                                  <div className="row">
                                    <div className="col-xl-12">
                                      <div className="filter-main-btn">
                                        <span
                                          onClick={this.openFilter}
                                          className="filter-btn btn btn-theme"
                                        >
                                          <i
                                            className="fa fa-filter"
                                            aria-hidden="true"
                                          />
                                          {getValueFromLanguageObject(
                                            "str_filter"
                                          )
                                            ? getValueFromLanguageObject(
                                                "str_filter"
                                              )
                                            : "Filter"}
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="row">
                                    <div className="col-12">
                                      <FilterBar
                                        productList={productList}
                                        handleSortingProductFilter={
                                          this.handleSortingProductFilter
                                        }
                                        onLayoutViewClicked={(colmuns) =>
                                          this.LayoutViewClicked(colmuns)
                                        }
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>

                              {productList.length !== 0 && (
                                <ProductListing
                                  productList={productList}
                                  colSize={layoutColumns}
                                  handlePagination={this.handlePagination}
                                  handleLoader={this.handleLoader}
                                  limit={limit}
                                  isPaginationEnableForProductList={
                                    isPaginationEnableForProductList
                                  }
                                  addToCart={this.addToCart}
                                  handleClickOfQuickView={
                                    this.handleClickOfQuickView
                                  }
                                />
                              )}
                              {productList.length === 0 && isLoaderVisible && (
                                <ProductLoaderMain />
                              )}

                              {this.state.isContentLoaderVisible && (
                                <ProductLoaderMain />
                              )}

                              {productList.length === 0 &&
                                !isLoaderVisible &&
                                !this.state.isContentLoaderVisible && (
                                  <NoSpecialProductFoundAnim />
                                )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
        {isLoading && <MainLoader />}
        {showErrorMessageModal && (
          <ErrorMessageAnimationModal
            show={showErrorMessageModal}
            message={errorMessage}
            onClose={() => {
              this.setState({ showErrorMessageModal: false });
            }}
          />
        )}
        {showAddToCartMessageModal && (
          <AddToCartAnim
            show={showAddToCartMessageModal}
            message={addToCartMessage}
            onClose={() => {
              this.setState({ showAddToCartMessageModal: false });
            }}
          />
        )}
        {isQuickViewProductDetailsModalShow && (
          <QuickViewProductDetailsModal
            product={product}
            category={category}
            masterDetailsObj={masterDetailsObj}
            symbol={symbol}
            isInquiry={isInquiry}
            onAddToCartClicked={this.addToCart}
            isQuickViewProductDetailsModalShow={
              isQuickViewProductDetailsModalShow
            }
            handleCloseQuickViewProductDetailsModal={
              this.hideQuickViewProductDetailsModal
            }
          />
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => ({});

export default connect(
  mapStateToProps,
  {
    counter: getCommonCounter,
    notificationCounter: getNotificationCounter,
    updateQuantity: getUpdateQuantity,
  }
)(withRouter(Products));
