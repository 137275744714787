import React, { Component } from "react";
import Lottie from "react-lottie";
import { getDefaultLoader } from "../store";
import mainLoaderJSON from "./mainLoaderJSON.json";
import mainLoaderParkerGoldJSON from "./json/mainLoaderParkerGoldJSON.json";

class MainLoader extends Component {
  defaultOptions = {};
  constructor(props) {
    super(props);
    const defaultLoader = getDefaultLoader();
    if (defaultLoader === "true" || defaultLoader === true) {
      this.defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: mainLoaderJSON,
      };
    } else {
      this.defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: mainLoaderParkerGoldJSON,
      };
    }
  }

  render() {
    return (
      <>
        <div className="loader-wrapper loader d-flex justify-content-center animationSize-200-200">
          <Lottie options={this.defaultOptions} />
        </div>
      </>
    );
  }
}

export default MainLoader;
