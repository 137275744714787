import React, { Component } from "react";
// import { Modal } from "react-bootstrap";
import Modal from "react-responsive-modal";
import {
  getValueFromLanguageObject,
  loadUserDetailsFromLocalStorage,
} from "../../../store";
import { Select } from "@material-ui/core";
import ImageUploading from "react-images-uploading";
import { postCall, postCallWithMedia } from "../../../api/post";
import {
  clearLocalStorageReload,
  isOpenStoreIdExist,
  navigateTo,
  removeUserDetailAndOtherDetails,
} from "../../../utils/utils";
import {
  getRequestProductImageUploadUrl,
  getRequestProductUrl,
} from "../../../services";
import SuccessMessageAnimationModal from "../../../animation/SuccessMessageAnimationModal";
import ErrorMessageAnimationModal from "../../../animation/ErrorMessageAnimationModal";
import MainLoader from "../../../animation/MainLoader";

const maxMbFileSize = 5 * 1024 * 1024;
class RequestPaymentModal extends Component {
  state = {
    imageList: [],
    imageFileList: [],
    requestProduct: {},
    productDesc: "",
    categoryId: "",
    showSuccessMessageModal: "",
    successMessage: "",
    showErrorMessageModal: "",
    errorMessage: "",
    isLoading: false,
  };

  onChange = (imageList, addUpdateIndex) => {
    this.setState({ imageList: imageList });
  };

  uploadImage = async (file) => {
    try {
      const { masterDetailsObj } = this.props;
      const uploadImageUrl = getRequestProductImageUploadUrl(masterDetailsObj);
      const uploadImage = await postCallWithMedia(
        uploadImageUrl,
        file,
        "requestproduct"
      );
      if (uploadImage.error) {
        clearLocalStorageReload({ response: uploadImage });
        this.loadErrorMessageAnimation(uploadImage.error.message);
        if (uploadImage.error.statusCode === 401) {
          removeUserDetailAndOtherDetails();
          navigateTo(this.props, `/login`);
          return;
        }
        if (uploadImage.error.statusCode === 500) {
          this.loadErrorMessageAnimation("Please try again later");
          return;
        }
      } else {
        if (
          uploadImage &&
          uploadImage.result &&
          uploadImage.result.files &&
          uploadImage.result.files.requestproduct &&
          uploadImage.result.files.requestproduct.length > 0 &&
          uploadImage.result.files.requestproduct[0].name
        ) {
          return uploadImage.result.files.requestproduct[0].name;
        }
      }
    } catch (error) {
      console.log("error");
      this.setState({ isLoading: false });
    }
  };

  handleChangeOfDescription = (event) => {
    this.setState({ productDesc: event.target.value });
  };

  onClickOfSubmit = () => {
    const { imageList } = this.state;
    this.setState({ isLoading: true }, async () => {
      const promises = imageList.map(async (imagePreview, index) => {
        return await this.uploadImage(imagePreview.file);
      });
      const imageFileList = await Promise.all(promises);
      this.setState({ isLoading: false, imageFileList }, () => {
        this.setState({ productDesc: "", categoryId: "" });
        this.requestProductApi();
      });
    });
  };

  requestProductApi = async () => {
    this.setState({ isLoading: true });
    let userDetails = loadUserDetailsFromLocalStorage();
    if (!isOpenStoreIdExist() && userDetails === undefined) {
      return;
    } else {
      userDetails = userDetails === undefined ? {} : userDetails;
    }
    const { id } = userDetails;
    const { productDesc, categoryId, imageFileList } = this.state;
    const imageList = imageFileList.map((image) => {
      return { productname: image };
    });
    const requestProductObj = {
      orderstatus: 1,
      inshoppingcart: 3,
      userId: id,
      description: productDesc,
      categoryId: categoryId,
      productmedia: imageList,
    };
    const requestProductUrl = getRequestProductUrl();
    const requestProductResponse = await postCall(
      requestProductUrl,
      requestProductObj
    );
    this.setState({ isLoading: false });
    if (requestProductResponse.error) {
      clearLocalStorageReload({ response: requestProductResponse });
      this.loadErrorMessageAnimation(requestProductResponse.error.message);
      if (requestProductResponse.error.statusCode === 401) {
        removeUserDetailAndOtherDetails();
        navigateTo(this.props, `/login`);
        return;
      }
    } else {
      this.setState({ imageList: [] });
      this.loadSuccessMessageAnimation(
        getValueFromLanguageObject("str_requested_product_added_successfully")
          ? getValueFromLanguageObject(
              "str_requested_product_added_successfully"
            )
          : "Requested product added successfully"
      );
      const { onCloseAddRequestProductModal, initProductList } = this.props;
      const { updateProductList } = this.props;
      this.setState({ imageList: [] }, () => {
        updateProductList();
      });
      onCloseAddRequestProductModal();
    }
  };

  loadErrorMessageAnimation = (message) => {
    this.setState({ errorMessage: message, showErrorMessageModal: true });
  };

  loadSuccessMessageAnimation = (message) => {
    this.setState({ successMessage: message, showSuccessMessageModal: true });
  };

  render() {
    const {
      imageList,
      productDesc,
      showSuccessMessageModal,
      successMessage,
      showErrorMessageModal,
      errorMessage,
      isLoading,
      categoryId,
    } = this.state;
    const {
      isAddRequestProductModalVisible,
      onCloseAddRequestProductModal,
      categories,
    } = this.props;

    return (
      <>
        <Modal
          open={isAddRequestProductModalVisible}
          onClose={onCloseAddRequestProductModal}
          classNames={{
            overlay: "customOverlay",
            modal: "customModal",
          }}
          center
        >
          <div className="theme-form" style={{ margin: "20px" }}>
            <div className="form-group">
              <ImageUploading
                onChange={this.onChange}
                maxNumber={5}
                multiple
                maxFileSize={maxMbFileSize}
                acceptType={["jpg", "png", "jpeg"]}
                value={imageList}
              >
                {({
                  imageList,
                  onImageUpload,
                  onImageRemoveAll,
                  onImageUpdate,
                  onImageRemove,
                  isDragging,
                  dragProps,
                }) => (
                  <div className="upload__image-wrapper">
                    <div className="mb-2">
                      <label>
                        {" "}
                        {getValueFromLanguageObject("str_product_images")
                          ? getValueFromLanguageObject("str_product_images")
                          : "Product Images"}
                        <span className="text-danger">{"*"}</span>
                      </label>
                    </div>
                    <div className="custom-file__input">
                      <button
                        className="file-upload__custom"
                        style={isDragging ? { color: "red" } : undefined}
                        onClick={(e) => {
                          onImageUpload();
                        }}
                        {...dragProps}
                      >
                        {getValueFromLanguageObject("str_select_or_drop_here")
                          ? getValueFromLanguageObject(
                              "str_select_or_drop_here"
                            )
                          : "Select or Drop here"}
                      </button>
                    </div>
                    <div className="upload-list">
                      {imageList &&
                        imageList.map((image, index) => (
                          <div key={index} className="image-item">
                            <img src={image.dataURL} alt="" width="100" />
                            <div className="image-item__btn-wrapper">
                              <button onClick={() => onImageUpdate(index)}>
                                <i class="fa fa-pencil" aria-hidden="true" />
                              </button>
                              <button onClick={() => onImageRemove(index)}>
                                <i class="fa fa-trash" aria-hidden="true" />
                              </button>
                            </div>
                          </div>
                        ))}
                    </div>
                  </div>
                )}
              </ImageUploading>
            </div>
            <div className="form-group">
              <label htmlFor="categoryId">
                {getValueFromLanguageObject("str_category")
                  ? getValueFromLanguageObject("str_category")
                  : "Category"}
              </label>
              <Select
                className="form-control"
                name="category"
                label="Category"
                onChange={(e) => {
                  this.setState({ categoryId: e.target.value });
                }}
                value={categoryId}
              >
                <option value={""}>Select</option>
                {categories.map((category, index) => {
                  return (
                    <option key={index} value={category.id}>
                      {category.name}
                    </option>
                  );
                })}
              </Select>
            </div>
            <div className="form-group">
              <label htmlFor="inquiryMsg">
                {getValueFromLanguageObject("str_description")
                  ? getValueFromLanguageObject("str_description")
                  : "Description"}
              </label>
              <textarea
                className="form-control"
                placeholder={
                  getValueFromLanguageObject(
                    "str_write_product_description_here"
                  )
                    ? getValueFromLanguageObject(
                        "str_write_product_description_here"
                      )
                    : "Write product description here"
                }
                rows="6"
                minLength="5"
                maxLength="512"
                value={productDesc}
                onChange={this.handleChangeOfDescription}
              />
            </div>

            <div className="form-group">
              <button
                onClick={() => {
                  this.onClickOfSubmit();
                }}
                disabled={imageList.length === 0 ? true : false}
                className="btn btn-solid"
              >
                {getValueFromLanguageObject("str_request_product")
                  ? getValueFromLanguageObject("str_request_product")
                  : "Request Product"}
              </button>
            </div>
          </div>
        </Modal>
        {isLoading && <MainLoader />}
        {showErrorMessageModal && (
          <ErrorMessageAnimationModal
            show={showErrorMessageModal}
            message={errorMessage}
            onClose={() => {
              this.setState({ showErrorMessageModal: false });
            }}
          />
        )}
        {showSuccessMessageModal && (
          <SuccessMessageAnimationModal
            show={showSuccessMessageModal}
            message={successMessage}
            onClose={() => {
              this.setState({ showSuccessMessageModal: false });
            }}
          />
        )}
      </>
    );
  }
}

export default RequestPaymentModal;
