import React, { Component } from "react";
import { connect } from "react-redux";
import { getNotificationCounter } from "../../../actions";
import { Helmet } from "react-helmet";
import Breadcrumb from "../../common/breadcrumb";
import { getCall, postCallWithMedia, patchCall } from "../../../api/post";
import {
  loadUserDetailsFromLocalStorage,
  getValueFromLanguageObject,
  getColorCodeBasedOnKey,
  removeCurrentLanguage,
  saveUserDetailsToLocalStorage,
} from "../../../store";
import Avatar from "react-avatar";
import ImageUploader from "react-images-upload";
import {
  getProfileImageUrl,
  getMerchantInformationUrl,
  getCitiesUrl,
  getSignupOptionsUrl,
  getMasterDetailsIdUrl,
  getTenantInfoUrl,
  getSettingUrl,
  getGroupWiseGSTConfigurationUrl,
  getAddressDetailUrl,
} from "../../../services";
import MainLoader from "../../../animation/MainLoader";
import ErrorMessageAnimationModal from "../../../animation/ErrorMessageAnimationModal";
import SuccessMessageAnimationModal from "../../../animation/SuccessMessageAnimationModal";
import {
  clearLocalStorageReload,
  isOpenStoreIdExist,
  navigateTo,
  removeUserDetailAndOtherDetails,
} from "../../../utils/utils";
import { withRouter } from "react-router-dom";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import SimpleReactValidator from "simple-react-validator";

const cityDropDownStyle = {
  fontSize: "12px",
  padding: "17px 25px",
  height: "54px",
  marginBottom: "50px",
};

class MyProfile extends Component {
  state = {
    cellNumber: "",
    cities: [],
    userName: "",
    companyName: "",
    cityId: "",
    emailId: "",
    userDetails: {},
    hoverStyle: { height: "500px" },
    isHover: true,
    isLoading: true,
    gstin: "",
    AppTitle: "",
    showErrorMessageModal: false,
    errorMessage: "",
    showSuccessMessageModal: false,
    successMessage: "",
    masterDetailsObj: {},
    maxCellnumberDigitLimit: "",
    text: "",
    countryList: [],
    stateList: [],
    cityList: [],
    shippingCountryList: [],
    shippingStateList: [],
    shippingCityList: [],
    groupWiseGSTINConfig: [],
  };

  constructor(props) {
    super(props);
    this.validator = new SimpleReactValidator();
  }

  componentDidMount = () => {
    const userDetails = loadUserDetailsFromLocalStorage();
    if (!isOpenStoreIdExist() && userDetails === undefined) {
      navigateTo(this.props, `/login`);
      return;
    }
    if (isOpenStoreIdExist()) {
      navigateTo(this.props, `/`);
      return;
    }

    this.initMasterDetailsId().then(() => {
      this.initCities();
      this.initSignUpOptions();
      this.initMerchantInfo();
      this.initUserDetails().then(() => {
        this.setState({ isLoading: false });
      });
      this.initTenantInfo();
      this.initCountryList();
      this.initShippingCountryList();
      this.initCatelogueSettings();
      this.initGroupWiseGSTINConfig();

      this.initAddressDetails();
    });
  };

  initMasterDetailsId = async () => {
    let masterDetailsObj = {};
    const publicUrl = window.location.pathname.split("/")[1];
    const masterDetailsIdUrl = getMasterDetailsIdUrl(publicUrl);
    const masterDetails = await getCall(masterDetailsIdUrl, []).then();
    if (masterDetails.error) {
      clearLocalStorageReload({ response: masterDetails });
    } else {
      if (masterDetails) {
        masterDetailsObj = masterDetails;
      }
    }
    this.setState({ masterDetailsObj: masterDetailsObj });
  };

  initCities = async () => {
    const { masterDetailsObj } = this.state;
    const cities = await getCall(getCitiesUrl(masterDetailsObj), []);
    if (cities.error) {
      clearLocalStorageReload({ response: cities });
    }
    this.setState({ cities: cities });
  };

  initUserDetails = async () => {
    const userDetails = loadUserDetailsFromLocalStorage();
    if (userDetails === undefined) {
      navigateTo(this.props, `/login`);
      return;
    }
    const userDetailUrl = `users/${userDetails.id}`;
    const userDetailsResponse = await getCall(userDetailUrl, []);

    if (userDetailsResponse.error) {
      clearLocalStorageReload({ response: userDetailsResponse });
      this.loadErrorMessageAnimation(userDetailsResponse.error.message);
      if (userDetailsResponse.error.statusCode === 401) {
        removeUserDetailAndOtherDetails();
        navigateTo(this.props, `/login`);
        return;
      }
    } else {
      this.setState({
        userDetails: userDetailsResponse,
        userName: userDetailsResponse.username,
        cellNumber: userDetailsResponse.cellnumber,
        emailId: userDetailsResponse.email,
        companyName: userDetailsResponse.companyname,
        cityId: userDetailsResponse.cityId,
        gstin: userDetailsResponse.gstin,
      });
      this.preFillUpBillingDetailForm(userDetailsResponse);
    }
  };

  initSignUpOptions = async () => {
    const { masterDetailsObj } = this.state;
    const signupOptions = await getCall(
      getSignupOptionsUrl(masterDetailsObj),
      []
    );
    if (signupOptions.error) {
      clearLocalStorageReload({ response: signupOptions });
    } else {
      if (signupOptions.length > 0) {
        this.setState({ signupOptions: JSON.parse(signupOptions[0].text) });
      }
    }
  };

  initMerchantInfo = async () => {
    const { masterDetailsObj } = this.state;
    const merchantInfoUrl = getMerchantInformationUrl(masterDetailsObj);
    const merchantInfo = await getCall(merchantInfoUrl, []);
    if (merchantInfo.error) {
      clearLocalStorageReload({ response: merchantInfo });
      this.setState({
        isMerchantGSTEnable: false,
        merchantInfo: {},
        AppTitle: "",
      });
    } else {
      this.setState({
        merchantInfo,
        AppTitle: merchantInfo.companyname,
        isMerchantGSTEnable: merchantInfo.enablegst === 0 ? false : true,
      });
    }
  };

  initTenantInfo = async () => {
    const { masterDetailsObj } = this.state;
    const { id } = masterDetailsObj || {};
    if (!id) {
      return;
    }
    const tenantInfoUrl = getTenantInfoUrl(id);
    const tenantInfo = await getCall(tenantInfoUrl, []);
    if (tenantInfo.error) {
      clearLocalStorageReload({ response: tenantInfo });
      this.loadErrorMessageAnimation(tenantInfo.error.message);
    } else {
      if (
        tenantInfo &&
        tenantInfo.length > 0 &&
        tenantInfo[0].text &&
        tenantInfo[0].text.length > 0
      ) {
        const tenantSettings = JSON.parse(tenantInfo[0].text);
        if ("maxCellnumberDigitLimit" in tenantSettings) {
          this.setState({
            maxCellnumberDigitLimit: tenantSettings.maxCellnumberDigitLimit,
          });
        } else {
          this.setState({
            maxCellnumberDigitLimit: "10",
          });
        }
      }
    }
  };

  // setting code start
  removeUserDetailAndRedirectToLogin = () => {
    removeUserDetailAndOtherDetails();
    removeCurrentLanguage();
    navigateTo(this.props, `/login`);
  };

  initCountryList = async () => {
    const countryUrl = `states?getCountries=getCountries`;
    const countryList = await getCall(countryUrl, []);
    if (countryList.error) {
      clearLocalStorageReload({ response: countryList });
      this.loadErrorMessageAnimation(countryList.error.message);
      if (countryList.error.statusCode === 401) {
        removeUserDetailAndOtherDetails();
        navigateTo(this.props, `/login`);
        return;
      }
    } else {
      this.setState({ countryList: countryList.data });
    }
  };

  initShippingCountryList = async () => {
    const countryUrl = `states?getCountries=getCountries`;
    const countryList = await getCall(countryUrl, []);
    if (countryList.error) {
      clearLocalStorageReload({ response: countryList });
      this.loadErrorMessageAnimation(countryList.error.message);
      if (countryList.error.statusCode === 401) {
        removeUserDetailAndOtherDetails();
        navigateTo(this.props, `/login`);
        return;
      }
    } else {
      this.setState({ shippingCountryList: countryList.data });
    }
  };

  initStateList = async () => {
    const { addressDetails } = this.state;
    const stateUrl =
      `states?filter[where][parentId]=` + this.state.billingCountry;
    const stateList = await getCall(stateUrl, []);
    if (stateList.error) {
      clearLocalStorageReload({ response: stateList });
      this.loadErrorMessageAnimation(stateList.error.message);
      if (stateList.error.statusCode === 401) {
        removeUserDetailAndOtherDetails();
        navigateTo(this.props, `/login`);
        return;
      }
    } else {
      this.setState({ stateList: stateList.data });
      if (
        stateList &&
        stateList.data &&
        addressDetails &&
        stateList.data[0] &&
        stateList.data[0].id &&
        addressDetails.length > 0 &&
        addressDetails[10].visible === 0
      ) {
        this.initCityListing(stateList.data[0].id);
      }
    }
  };

  initShippingStateList = async () => {
    const { addressDetails } = this.state;
    const stateUrl =
      `states?filter[where][parentId]=` + this.state.shippingCountry;
    const stateList = await getCall(stateUrl, []);
    if (stateList.error) {
      clearLocalStorageReload({ response: stateList });
      this.loadErrorMessageAnimation(stateList.error.message);
      if (stateList.error.statusCode === 401) {
        removeUserDetailAndOtherDetails();
        navigateTo(this.props, `/login`);
        return;
      }
    } else {
      this.setState({ shippingStateList: stateList.data });
      if (
        stateList &&
        stateList.data &&
        addressDetails &&
        addressDetails.length > 0 &&
        addressDetails[10].visible === 0
      ) {
        this.initShippingCityListing(stateList.data[0].id);
      }
    }
  };

  initCityList = async () => {
    const cityUrl = `cities?filter[where][stateId]=` + this.state.billingState;
    const cityList = await getCall(cityUrl, []);
    if (cityList.error) {
      clearLocalStorageReload({ response: cityList });
      this.loadErrorMessageAnimation(cityList.error.message);
      if (cityList.error.statusCode === 401) {
        removeUserDetailAndOtherDetails();
        navigateTo(this.props, `/login`);
        return;
      }
    } else {
      this.setState({ cityList: cityList.data });
    }
  };

  initCityListing = async (billingState) => {
    const cityUrl = `cities?filter[where][stateId]=` + billingState;
    const cityList = await getCall(cityUrl, []);
    if (cityList.error) {
      clearLocalStorageReload({ response: cityList });
      this.loadErrorMessageAnimation(cityList.error.message);
      if (cityList.error.statusCode === 401) {
        removeUserDetailAndOtherDetails();
        navigateTo(this.props, `/login`);
        return;
      }
    } else {
      this.setState({ cityList: cityList.data });
    }
  };

  initShippingCityList = async () => {
    const cityUrl = `cities?filter[where][stateId]=` + this.state.shippingState;
    const cityList = await getCall(cityUrl, []);
    if (cityList.error) {
      clearLocalStorageReload({ response: cityList });
      this.loadErrorMessageAnimation(cityList.error.message);
      if (cityList.error.statusCode === 401) {
        removeUserDetailAndOtherDetails();
        navigateTo(this.props, `/login`);
        return;
      }
    } else {
      this.setState({ shippingCityList: cityList.data });
    }
  };

  initShippingCityListing = async (shippingState) => {
    const cityUrl = `cities?filter[where][stateId]=` + shippingState;
    const cityList = await getCall(cityUrl, []);
    if (cityList.error) {
      clearLocalStorageReload({ response: cityList });
      this.loadErrorMessageAnimation(cityList.error.message);
      if (cityList.error.statusCode === 401) {
        removeUserDetailAndOtherDetails();
        navigateTo(this.props, `/login`);
        return;
      }
    } else {
      this.setState({ shippingCityList: cityList.data });
    }
  };

  initCatelogueSettings = async () => {
    const { masterDetailsObj } = this.state;
    const settingUrl = getSettingUrl(masterDetailsObj);
    const settingInfo = await getCall(settingUrl, []);
    if (settingInfo.error) {
      clearLocalStorageReload({ response: settingInfo });
    } else {
      let settings = {};
      settingInfo.forEach((setting) => {
        if (
          (setting.registerallow === "Catalogue_FMCG" ||
            setting.registerallow === "Catalogue_Jewellary" ||
            setting.registerallow === "Catalogue_Electronics" ||
            setting.registerallow === "Catalogue_FABRIC") &&
          setting.status === 1
        ) {
          settings = setting;
        }
      });
      this.setState({ currentCatelogueType: settings });
    }
  };

  initGroupWiseGSTINConfig = async () => {
    const { masterDetailsObj } = this.state;
    const groupWiseGSTINConfigUrl = getGroupWiseGSTConfigurationUrl(
      masterDetailsObj
    );
    const groupWiseGSTINConfig = await getCall(groupWiseGSTINConfigUrl, []);
    if (groupWiseGSTINConfig.error) {
      clearLocalStorageReload({ response: groupWiseGSTINConfig });
      this.loadErrorMessageAnimation(groupWiseGSTINConfig.error.message);
    } else {
      if (
        groupWiseGSTINConfig &&
        groupWiseGSTINConfig.length > 0 &&
        groupWiseGSTINConfig[0].status === 1
      ) {
        this.setState({
          groupWiseGSTINConfig: JSON.parse(groupWiseGSTINConfig[0].text),
        });
      }
    }
  };

  initAddressDetails = async () => {
    const { masterDetailsObj } = this.state;
    const addressDetails = await getCall(
      getAddressDetailUrl(masterDetailsObj),
      []
    );

    if (addressDetails.error) {
      clearLocalStorageReload({ response: addressDetails });
    } else {
      if (addressDetails.length > 0) {
        this.setState({ addressDetails: JSON.parse(addressDetails[0].text) });
      }
    }
  };

  preFillUpBillingDetailForm = (userDetailsResponse) => {
    const { billingaddress, shippingaddress } = userDetailsResponse;
    const billingAddress = JSON.parse(billingaddress);
    const shippingAddress = JSON.parse(shippingaddress);
    if (billingAddress) {
      const {
        name,
        mobile,
        email,
        companyname,
        gstin,
        address1,
        address2,
        landmark,
        cityId,
        stateId,
        countryId,
        zipcode,
        zonenumber,
        streetnumber,
        buildingnumber,
        unitnumber,
      } = billingAddress;
      this.setState({
        billingName: name,
        billingMobileNumber: mobile,
        billingEmailAddress: email,
        billingCompanyName: companyname,
        billingGSTIN: gstin,
        billingAddressLine1: address1,
        billingAddressLine2: address2,
        billingLandmark: landmark,
        billingPincode: zipcode,
        billingZonenumber: zonenumber,
        billingStreetnumber: streetnumber,
        billingBuildingnumber: buildingnumber,
        billingUnitnumber: unitnumber,
      });
      this.initCountryList().then(() => {
        this.setState({ billingCountry: countryId }, () => {
          this.initStateList().then(() => {
            this.setState({ billingState: stateId }, () => {
              this.initCityList().then(() => {
                this.setState({ billingCity: cityId });
              });
            });
          });
        });
      });
    }

    if (shippingAddress) {
      const {
        name,
        mobile,
        email,
        companyname,
        gstin,
        address1,
        address2,
        landmark,
        cityId,
        stateId,
        countryId,
        zipcode,
        zonenumber,
        streetnumber,
        buildingnumber,
        unitnumber,
      } = shippingAddress;
      this.setState({
        shippingName: name,
        shippingMobileNumber: mobile,
        shippingEmailAddress: email,
        shippingCompanyName: companyname,
        shippingGSTIN: gstin,
        shippingAddressLine1: address1,
        shippingAddressLine2: address2,
        shippingZonenumber: zonenumber,
        shippingLandmark: landmark,
        shippingPincode: zipcode,
        shippingStreetnumber: streetnumber,
        shippingBuildingnumber: buildingnumber,
        shippingUnitnumber: unitnumber,
      });
      this.initShippingCountryList().then(() => {
        this.setState({ shippingCountry: countryId }, () => {
          this.initShippingStateList().then(() => {
            this.setState({ shippingState: stateId }, () => {
              this.initShippingCityList().then(() => {
                this.setState({ shippingCity: cityId });
              });
            });
          });
        });
      });
    }
  };

  onChangeOfShippingCheckbox = (event) => {
    const {
      billingName,
      billingMobileNumber,
      billingEmailAddress,
      billingCompanyName,
      billingGSTIN,
      billingAddressLine1,
      billingAddressLine2,
      billingLandmark,
      billingCountry,
      billingState,
      billingCity,
      billingPincode,
      billingZonenumber,
      billingStreetnumber,
      billingBuildingnumber,
      billingUnitnumber,
    } = this.state;
    const isChecked = event.target.checked;
    this.setState({ chkShippingAddress: event.target.checked }, () => {
      if (isChecked) {
        this.setState({
          shippingName: billingName,
          shippingMobileNumber: billingMobileNumber,
          shippingEmailAddress: billingEmailAddress,
          shippingCompanyName: billingCompanyName,
          shippingGSTIN: billingGSTIN,
          shippingAddressLine1: billingAddressLine1,
          shippingAddressLine2: billingAddressLine2,
          shippingLandmark: billingLandmark,
          shippingPincode: billingPincode,
          shippingZonenumber: billingZonenumber,
          shippingStreetnumber: billingStreetnumber,
          shippingBuildingnumber: billingBuildingnumber,
          shippingUnitnumber: billingUnitnumber,
        });
        this.initShippingCountryList().then(() => {
          this.setState({ shippingCountry: billingCountry }, () => {
            this.initShippingStateList().then(() => {
              this.setState({ shippingState: billingState }, () => {
                this.initShippingCityList().then(() => {
                  this.setState({ shippingCity: billingCity });
                });
              });
            });
          });
        });
      }
    });
  };

  updateUserDetails = async () => {
    const {
      billingName,
      billingMobileNumber,
      billingEmailAddress,
      billingCompanyName,
      billingGSTIN,
      billingAddressLine1,
      billingAddressLine2,
      billingLandmark,
      billingCountry,
      billingState,
      billingCity,
      billingPincode,
      billingZonenumber,
      billingStreetnumber,
      billingBuildingnumber,
      billingUnitnumber,
      shippingName,
      shippingMobileNumber,
      shippingEmailAddress,
      shippingCompanyName,
      shippingGSTIN,
      shippingAddressLine1,
      shippingAddressLine2,
      shippingLandmark,
      shippingCountry,
      shippingState,
      shippingCity,
      shippingPincode,
      shippingZonenumber,
      shippingStreetnumber,
      shippingBuildingnumber,
      shippingUnitnumber,
    } = this.state;
    const userDetails = loadUserDetailsFromLocalStorage();
    if (userDetails === undefined) {
      return;
    }
    const { id } = userDetails;
    const userDetailsUrl = "users/" + id;
    const userDetailsObj = {
      shippingaddress: {
        name: shippingName,
        mobile: shippingMobileNumber,
        email: shippingEmailAddress,
        companyname: shippingCompanyName,
        gstin: shippingGSTIN,
        address1: shippingAddressLine1,
        address2: shippingAddressLine2,
        landmark: shippingLandmark,
        countryId: shippingCountry,
        stateId: shippingState,
        cityId: shippingCity,
        zipcode: shippingPincode,
        zonenumber: shippingZonenumber,
        streetnumber: shippingStreetnumber,
        buildingnumber: shippingBuildingnumber,
        unitnumber: shippingUnitnumber,
      },
      billingaddress: {
        name: billingName,
        mobile: billingMobileNumber,
        email: billingEmailAddress,
        companyname: billingCompanyName,
        gstin: billingGSTIN,
        address1: billingAddressLine1,
        address2: billingAddressLine2,
        landmark: billingLandmark,
        countryId: billingCountry,
        stateId: billingState,
        cityId: billingCity,
        zipcode: billingPincode,
        zonenumber: billingZonenumber,
        streetnumber: billingStreetnumber,
        buildingnumber: billingBuildingnumber,
        unitnumber: billingUnitnumber,
      },
      updateShippingPrice: true,
    };
    this.setState({ isLoading: true });
    const updateUserDetails = await patchCall(userDetailsUrl, userDetailsObj);
    this.setState({ isLoading: false });
    if (updateUserDetails.error) {
      clearLocalStorageReload({ response: updateUserDetails });
      this.loadErrorMessageAnimation(updateUserDetails.error.message);
      if (updateUserDetails.error.statusCode === 401) {
        removeUserDetailAndOtherDetails();
        navigateTo(this.props, `/login`);
        return;
      }
    } else {
      this.loadSuccessMessageAnimation(
        getValueFromLanguageObject(
          "Str_billing_shipping_address_edited_successfully"
        )
          ? getValueFromLanguageObject(
              "Str_billing_shipping_address_edited_successfully"
            )
          : "Billing/Shipping address edited successfully"
      );
      this.preFillUpBillingDetailForm(updateUserDetails);
      saveUserDetailsToLocalStorage(updateUserDetails);
    }
  };

  onClickOfSaveButton = () => {
    const userDetails = loadUserDetailsFromLocalStorage();
    if (userDetails === undefined) {
      return;
    }
    // if (this.state.chkShippingAddress) {
    //   console.log(this.state.chkShippingAddress);
    //   const {
    //     billingName,
    //     billingMobileNumber,
    //     billingEmailAddress,
    //     billingCompanyName,
    //     billingGSTIN,
    //     billingAddressLine1,
    //     billingAddressLine2,
    //     billingLandmark,
    //     billingPincode,
    //     groupWiseGSTINConfig,
    //     currentCatelogueType,
    //     billingZonenumber,
    //     billingStreetnumber,
    //     billingBuildingnumber,
    //     billingUnitnumber,
    //   } = this.state;
    //   let found = false;
    //   if (
    //     userDetails &&
    //     userDetails.groupId !== null &&
    //     userDetails.groupId !== undefined
    //   ) {
    //     const temp = groupWiseGSTINConfig.forEach((groupId) => {
    //       if (userDetails.groupId === groupId) {
    //         found = true;
    //       }
    //       return groupId;
    //     });
    //   }
    //   if (
    //     found &&
    //     currentCatelogueType &&
    //     currentCatelogueType.registerallow === "Catalogue_FMCG"
    //   ) {
    //     if (
    //       billingCompanyName === undefined ||
    //       billingCompanyName === "" ||
    //       billingCompanyName.trim().length === 0
    //     ) {
    //       this.loadErrorMessageAnimation(
    //         getValueFromLanguageObject("str_company_name_should_not_be_empty")
    //           ? getValueFromLanguageObject(
    //               "str_company_name_should_not_be_empty"
    //             )
    //           : "Company Name should not be empty!"
    //       );
    //       return;
    //     }
    //     if (
    //       billingGSTIN === undefined ||
    //       billingGSTIN === "" ||
    //       billingGSTIN.trim().length === 0
    //     ) {
    //       this.loadErrorMessageAnimation(
    //         getValueFromLanguageObject("str_gst_number_should_not_be_empty")
    //           ? getValueFromLanguageObject("str_gst_number_should_not_be_empty")
    //           : "GST Number should not be empty!"
    //       );
    //       return;
    //     }
    //   }
    //   this.setState(
    //     {
    //       shippingName: billingName,
    //       shippingMobileNumber: billingMobileNumber,
    //       shippingEmailAddress: billingEmailAddress,
    //       shippingCompanyName: billingCompanyName,
    //       shippingGSTIN: billingGSTIN,
    //       shippingAddressLine1: billingAddressLine1,
    //       shippingAddressLine2: billingAddressLine2,
    //       shippingLandmark: billingLandmark,
    //       shippingPincode: billingPincode,
    //       shippingZonenumber: billingZonenumber,
    //       shippingStreetnumber: billingStreetnumber,
    //       shippingBuildingnumber: billingBuildingnumber,
    //       shippingUnitnumber: billingUnitnumber,
    //     },
    //     () => {
    //       this.initShippingCountryList().then(() => {
    //         this.setState(
    //           { shippingCountry: this.state.billingCountry },
    //           () => {
    //             this.initShippingStateList().then(() => {
    //               this.setState(
    //                 { shippingState: this.state.billingState },
    //                 () => {
    //                   this.initShippingCityList().then(() => {
    //                     this.setState(
    //                       { shippingCity: this.state.billingCity },
    //                       () => {
    //                         if (this.validator.allValid()) {
    //                           // update user detail
    //                           this.updateUserDetails();
    //                         } else {
    //                           this.validator.showMessages();
    //                           this.forceUpdate();
    //                         }
    //                       }
    //                     );
    //                   });
    //                 }
    //               );
    //             });
    //           }
    //         );
    //       });
    //     }
    //   );
    // }
    // else {
    if (this.validator.allValid()) {
      // update user detail
      const {
        billingCompanyName,
        billingGSTIN,
        shippingCompanyName,
        shippingGSTIN,
        groupWiseGSTINConfig,
        currentCatelogueType,
      } = this.state;
      let found = false;
      if (
        userDetails &&
        userDetails.groupId !== null &&
        userDetails.groupId !== undefined
      ) {
        const temp = groupWiseGSTINConfig.map((groupId) => {
          if (userDetails.groupId === groupId) {
            found = true;
          }
          return groupId;
        });

        if (
          found &&
          currentCatelogueType &&
          currentCatelogueType.registerallow === "Catalogue_FMCG"
        ) {
          if (
            billingCompanyName === undefined ||
            billingCompanyName === "" ||
            billingCompanyName.trim().length === 0
          ) {
            this.loadErrorMessageAnimation(
              getValueFromLanguageObject("str_company_name_should_not_be_empty")
                ? getValueFromLanguageObject(
                    "str_company_name_should_not_be_empty"
                  )
                : "Company Name should not be empty!"
            );
            return;
          }
          if (
            billingGSTIN === undefined ||
            billingGSTIN === "" ||
            billingGSTIN.trim().length === 0
          ) {
            this.loadErrorMessageAnimation(
              getValueFromLanguageObject("str_gst_number_should_not_be_empty")
                ? getValueFromLanguageObject(
                    "str_gst_number_should_not_be_empty"
                  )
                : "GST Number should not be empty!"
            );
            return;
          }
          if (
            shippingCompanyName === undefined ||
            shippingCompanyName === "" ||
            shippingCompanyName.trim().length === 0
          ) {
            this.loadErrorMessageAnimation(
              getValueFromLanguageObject("str_company_name_should_not_be_empty")
                ? getValueFromLanguageObject(
                    "str_company_name_should_not_be_empty"
                  )
                : "Company Name should not be empty!"
            );
            return;
          }
          if (
            shippingGSTIN === undefined ||
            shippingGSTIN === "" ||
            shippingGSTIN.trim().length === 0
          ) {
            this.loadErrorMessageAnimation(
              getValueFromLanguageObject("str_gst_number_should_not_be_empty")
                ? getValueFromLanguageObject(
                    "str_gst_number_should_not_be_empty"
                  )
                : "GST Number should not be empty!"
            );
            return;
          }
        }
      }
      this.updateUserDetails();
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
    // }
  };

  onChangeOfBillingName = (event) => {
    this.setState({ billingName: event.target.value });
  };

  onChangeOfBillingMobileNumber = (event) => {
    this.setState({ billingMobileNumber: event.target.value });
  };
  onChangeOfBillingEmailAddress = (event) => {
    this.setState({ billingEmailAddress: event.target.value });
  };
  onChangeOfBillingCompanyName = (event) => {
    this.setState({ billingCompanyName: event.target.value });
  };

  onChangeOfBillingGSTIN = (event) => {
    this.setState({ billingGSTIN: event.target.value });
  };

  onChangeOfBillingAddressLine1 = (event) => {
    this.setState({ billingAddressLine1: event.target.value });
  };

  onChangeOfBillingAddressLine2 = (event) => {
    this.setState({ billingAddressLine2: event.target.value });
  };

  onChangeOfBillingLandmark = (event) => {
    this.setState({ billingLandmark: event.target.value });
  };

  onChangeOfBillingCountry = (event) => {
    this.setState({ billingCountry: event.target.value }, () => {
      this.initStateList();
    });
  };

  onChangeOfBillingState = (event) => {
    this.setState({ billingState: event.target.value }, () => {
      this.initCityList();
    });
  };

  onChangeOfBillingCity = (event) => {
    this.setState({ billingCity: event.target.value });
  };

  onChangeOfBillingPincode = (event) => {
    this.setState({ billingPincode: event.target.value });
  };

  onChangeOfBillingZonenumber = (event) => {
    this.setState({ billingZonenumber: event.target.value });
  };

  onChangeOfBillingStreetnumber = (event) => {
    this.setState({ billingStreetnumber: event.target.value });
  };

  onChangeOfBillingBuildingnumber = (event) => {
    this.setState({ billingBuildingnumber: event.target.value });
  };

  onChangeOfBillingUnitnumber = (event) => {
    this.setState({ billingUnitnumber: event.target.value });
  };

  onChangeOfShippingName = (event) => {
    this.setState({ shippingName: event.target.value });
  };

  onChangeOfShippingMobileNumber = (event) => {
    this.setState({ shippingMobileNumber: event.target.value });
  };
  onChangeOfShippingEmailAddress = (event) => {
    this.setState({ shippingEmailAddress: event.target.value });
  };
  onChangeOfShippingCompanyName = (event) => {
    this.setState({ shippingCompanyName: event.target.value });
  };

  onChangeOfShippingGSTIN = (event) => {
    this.setState({ shippingGSTIN: event.target.value });
  };

  onChangeOfShippingAddressLine1 = (event) => {
    this.setState({ shippingAddressLine1: event.target.value });
  };

  onChangeOfShippingAddressLine2 = (event) => {
    this.setState({ shippingAddressLine2: event.target.value });
  };

  onChangeOfShippingZonenumber = (event) => {
    this.setState({ shippingZonenumber: event.target.value });
  };

  onChangeOfShippingStreetnumber = (event) => {
    this.setState({ shippingStreetnumber: event.target.value });
  };

  onChangeOfShippingBuildingnumber = (event) => {
    this.setState({ shippingBuildingnumber: event.target.value });
  };

  onChangeOfShippingUnitnumber = (event) => {
    this.setState({ shippingUnitnumber: event.target.value });
  };

  onChangeOfShippingLandmark = (event) => {
    this.setState({ shippingLandmark: event.target.value });
  };

  onChangeOfShippingCountry = (event) => {
    this.setState({ shippingCountry: event.target.value }, () => {
      this.initShippingStateList();
    });
  };

  onChangeOfShippingState = (event) => {
    this.setState({ shippingState: event.target.value }, () => {
      this.initShippingCityList();
    });
  };

  onChangeOfShippingCity = (event) => {
    this.setState({ shippingCity: event.target.value });
  };

  onChangeOfShippingPincode = (event) => {
    this.setState({ shippingPincode: event.target.value });
  };

  loadErrorMessageAnimation = (message) => {
    this.setState({ errorMessage: message, showErrorMessageModal: true });
  };

  loadSuccessMessageAnimation = (message) => {
    this.setState({ successMessage: message, showSuccessMessageModal: true });
  };
  //setting code end

  handleChangeOfUserName = (event) => {
    const regex = /^[a-zA-Z _]*$/;
    if (!regex.test(event.target.value)) {
      return;
    }
    this.setState({ userName: event.target.value });
  };

  handleChangeOfCellNumber = (event) => {
    const number = event.target.value.trim();

    if (!isNaN(number)) {
      this.setState({ cellNumber: number });
    } else {
      return;
    }
  };

  handleChangeOfCompanyName = (event) => {
    this.setState({ companyName: event.target.value });
  };
  handleChangeOfEmailId = (event) => {
    this.setState({ emailId: event.target.value });
  };

  handleChangeOfCity = (event) => {
    this.setState({ cityId: event.target.value });
  };

  handleChangeOfGSTIN = (event) => {
    this.setState({ gstin: event.target.value });
  };

  onClickOfSaveChanges = () => {
    const {
      userName,
      cellNumber,
      emailId,
      companyName,
      cityId,
      gstin,
      signupOptions,
      maxCellnumberDigitLimit,
    } = this.state;
    const maxCellnumberDigitLimitInt = parseInt(maxCellnumberDigitLimit);
    if (userName.trim().length < 3 || userName.trim().length === 0) {
      this.loadErrorMessageAnimation(
        getValueFromLanguageObject("str_please_enter_valid_user_name")
          ? getValueFromLanguageObject("str_please_enter_valid_user_name")
          : "Please Enter Valid User Name."
      );
      return;
    }

    if (
      signupOptions[0].field_name === "Name" &&
      signupOptions[0].mandatory === 1 &&
      signupOptions[0].visible === 1 &&
      userName &&
      (userName.trim().length < 3 || userName.trim().length === 0)
    ) {
      this.loadErrorMessageAnimation(
        getValueFromLanguageObject("str_please_enter_valid_name")
          ? getValueFromLanguageObject("str_please_enter_valid_name")
          : "Please Enter Valid Name."
      );
      return;
    }
    if (
      signupOptions[5].field_name === "Mobile Number" &&
      signupOptions[5].mandatory === 1 &&
      signupOptions[5].visible === 1 &&
      cellNumber &&
      cellNumber.trim().length !== maxCellnumberDigitLimitInt
    ) {
      this.loadErrorMessageAnimation(
        getValueFromLanguageObject("msg_enter_mobile_number_incorrect")
          ? getValueFromLanguageObject("msg_enter_mobile_number_incorrect")
          : "Please enter correct mobile number."
      );
      return;
    }

    if (emailId && emailId.trim().length > 0) {
      const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (!re.test(emailId)) {
        this.loadErrorMessageAnimation(
          getValueFromLanguageObject("str_please_enter_valid_email_address")
            ? getValueFromLanguageObject("str_please_enter_valid_email_address")
            : "Please Enter Valid Email Address"
        );
        return;
      }
    }

    if (
      signupOptions[4].field_name === "Company Name" &&
      signupOptions[4].mandatory === 1 &&
      signupOptions[4].visible === 1 &&
      companyName &&
      companyName.trim().length === 0
    ) {
      this.loadErrorMessageAnimation(
        getValueFromLanguageObject("msg_enter_company_name_incorrect")
          ? getValueFromLanguageObject("msg_enter_company_name_incorrect")
          : "Please enter company name."
      );
      return;
    }

    if (cityId && cityId.trim().length === 0) {
      this.loadErrorMessageAnimation(
        getValueFromLanguageObject("str_please_select_city")
          ? getValueFromLanguageObject("str_please_select_city")
          : "Please Select City."
      );
      return;
    }

    if (
      (signupOptions[1].field_name === "Email" &&
        signupOptions[1].mandatory === 1 &&
        signupOptions[1].visible === 1) ||
      (emailId && emailId.trim().length > 0)
    ) {
      const re = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/;
      if (!re.test(emailId)) {
        this.loadErrorMessageAnimation(
          getValueFromLanguageObject("str_please_enter_valid_email_address")
            ? getValueFromLanguageObject("str_please_enter_valid_email_address")
            : "Please Enter Valid Email Address."
        );
        return;
      }
    }

    if (
      (signupOptions[2].field_name === "GSTIN" &&
        signupOptions[2].mandatory === 1 &&
        signupOptions[2].visible === 1) ||
      (gstin && gstin.trim().length > 0)
    ) {
      const re = /^([0][1-9]|[1-2][0-9]|[3][0-7])([a-zA-Z]{5}[0-9]{4}[a-zA-Z]{1}[1-9a-zA-Z]{1}[zZ]{1}[0-9a-zA-Z]{1})+$/;
      if (!re.test(gstin)) {
        this.loadErrorMessageAnimation(
          getValueFromLanguageObject("str_please_enter_valid_gst_number")
            ? getValueFromLanguageObject("str_please_enter_valid_gst_number")
            : "Please Enter Valid GST Number."
        );
        return;
      }
    }

    this.editProfileInformation();
  };

  editProfileInformation = () => {
    try {
      this.setState({ isLoading: true }, async () => {
        const userDetails = loadUserDetailsFromLocalStorage();
        if (userDetails === undefined) {
          return;
        }
        const editProfileUrl = "users/" + userDetails.id;
        const editProfileObj = {
          username: this.state.userName,
          cellnumber: this.state.cellNumber,
          email: this.state.emailId ? this.state.emailId.toLowerCase() : "",
          companyname: this.state.companyName,
          cityId: this.state.cityId,
          gstin: this.state.gstin,
        };
        const editProfile = await patchCall(editProfileUrl, editProfileObj);
        this.setState({ isLoading: false });
        if (editProfile.error) {
          clearLocalStorageReload({ response: editProfile });
          this.loadErrorMessageAnimation(editProfile.error.message);
          if (editProfile.error.statusCode === 401) {
            removeUserDetailAndOtherDetails();
            navigateTo(this.props, `/login`);
            return;
          }
        } else {
          this.loadSuccessMessageAnimation(
            getValueFromLanguageObject(
              "str_profile_information_updated_successfully"
            )
              ? getValueFromLanguageObject(
                  "str_profile_information_updated_successfully"
                )
              : "Profile Information updated successfully"
          );
          this.setState({ isLoading: true }, () => {
            this.initUserDetails().then(() => {
              this.setState({ isLoading: false });
            });
          });
        }
      });
    } catch (error) {
      this.setState({ isLoading: false });
    }
  };

  toggleEnterHover = () => {
    this.setState({ isHover: true });
  };

  toggleLeaveHover = () => {
    this.setState({ isHover: true });
  };

  onClickOfEditProfile = async (picture) => {
    try {
      const { masterDetailsObj } = this.state;
      // return;
      this.setState({ isLoading: true }, async () => {
        const uploadImageUrl = `containers/profilepic-${masterDetailsObj.codename}/upload`;
        const uploadImage = await postCallWithMedia(
          uploadImageUrl,
          picture[0],
          "profilepic"
        );
        this.setState({ isLoading: false });
        if (uploadImage.error) {
          clearLocalStorageReload({ response: uploadImage });
          this.loadErrorMessageAnimation(uploadImage.error.message);
        } else {
          const { result } = uploadImage || {};
          const { files } = result || {};
          const { profilepic } = files || {};
          const { name } = profilepic[0] || {};
          if (name) {
            const userDetails = loadUserDetailsFromLocalStorage();
            if (userDetails === undefined) {
              return;
            }
            const updateUserDetailUrl = `users/${userDetails.id}`;
            const updateUserDetailObj = {
              id: userDetails.id,
              profilepic: uploadImage.result.files.profilepic[0].name,
            };
            const updateUserDetail = await patchCall(
              updateUserDetailUrl,
              updateUserDetailObj
            );
            if (updateUserDetail.error) {
              clearLocalStorageReload({ response: updateUserDetail });
              this.loadErrorMessageAnimation(updateUserDetail.error.message);
            } else {
              this.loadSuccessMessageAnimation(
                getValueFromLanguageObject(
                  "str_profile_picture_is_updated_successfully"
                )
                  ? getValueFromLanguageObject(
                      "str_profile_picture_is_updated_successfully"
                    )
                  : "Profile Picture is updated successfully"
              );
              this.setState({ isLoading: true }, () => {
                this.initUserDetails().then(() => {
                  this.setState({ isLoading: false });
                });
              });
            }
          }
        }
      });
    } catch (error) {
      this.setState({ isLoading: false });
    }
  };

  renderSaveChanges = () => {
    const {
      userDetails,
      userName,
      cellNumber,
      emailId,
      companyName,
      cityId,
      gstin,
    } = this.state;
    if (
      (userDetails && userDetails.username !== userName) ||
      userDetails.cellnumber !== cellNumber ||
      userDetails.email !== emailId ||
      userDetails.companyname !== companyName ||
      userDetails.cityId !== cityId ||
      userDetails.gstin !== gstin
    ) {
      return (
        <>
          {/* <div className="row"> */}
          {/* <div className="col-6" /> */}
          <div
            onClick={() => {
              this.onClickOfSaveChanges();
            }}
            className="btn btn-solid pull-right save-button"

            // style={{
            //   color: getColorCodeBasedOnKey("text_save_changes_my_profile"),
            //   backgroundImage: `linear-gradient(30deg, ${getColorCodeBasedOnKey(
            //     "bg_save_changes_my_profile"
            //   )} 50%, transparent 50%)`,
            //   float: "right",
            //   marginBottom: "50px",
            // }}
          >
            {getValueFromLanguageObject("str_save_changes")
              ? getValueFromLanguageObject("str_save_changes")
              : "Save Changes"}
          </div>
          {/* </div> */}
        </>
      );
    } else {
      return;
    }
  };

  loadErrorMessageAnimation = (message) => {
    this.setState({ errorMessage: message, showErrorMessageModal: true });
  };

  loadSuccessMessageAnimation = (message) => {
    this.setState({ successMessage: message, showSuccessMessageModal: true });
  };

  render() {
    const {
      cities,
      userDetails,
      isLoading,
      AppTitle,
      signupOptions,
      showErrorMessageModal,
      errorMessage,
      showSuccessMessageModal,
      successMessage,
      masterDetailsObj,
      maxCellnumberDigitLimit,
      //setting state
      countryList,
      stateList,
      cityList,
      shippingCountryList,
      shippingStateList,
      shippingCityList,
      addressDetails,
    } = this.state;

    const maxCellnumberDigitLimitInt = parseInt(maxCellnumberDigitLimit);
    return (
      <>
        <div>
          {/*SEO Support*/}
          <Helmet>
            <title>
              {/* {AppTitle} |
              {getValueFromLanguageObject("str_my_profile_page")
                ? getValueFromLanguageObject("str_my_profile_page")
                : "My Profile Page"} */}
            </title>
            <meta
              name="description"
              content="Multikart – Multipurpose eCommerce React Template is a multi-use React template. It is designed to go well with multi-purpose websites. Multikart Bootstrap 4 Template will help you run multiple businesses."
            />
          </Helmet>
          {/*SEO Support End */}

          <Breadcrumb
            title={
              getValueFromLanguageObject("str_my_profile")
                ? getValueFromLanguageObject("str_my_profile")
                : "My Profile"
            }
          />
          <Tabs>
            <section
              className="login-page section-b-space"
              style={{
                border: `1px solid ${getColorCodeBasedOnKey(
                  "text_heading_label_my_profile"
                )}`,
              }}
            >
              <div className="container">
                <div className="row justify-content-center">
                  {/* <div className="col-lg-6">
                <h3
                  style={{
                    color: getColorCodeBasedOnKey(
                      "text_profile_label_my_profile"
                    ),
                  }}
                >
                  Profile
                </h3>
                <div className="row">
                  <div className="col-lg-12">
                    <div className="theme-card">
                      <div
                        onMouseEnter={this.toggleEnterHover}
                        onMouseLeave={this.toggleLeaveHover}
                        style={hoverStyle}
                      >
                        <Avatar
                          style={{
                            left: "10%",
                            top: "10%",
                            position: "absolute",
                          }}
                          size="430"
                          name={
                            userDetails.profilepic ? "" : userDetails.username
                          }
                          round={true}
                          src={
                            userDetails.profilepic
                              ? getProfileImageUrl(userDetails.profilepic)
                              : ""
                          }
                        />
                      </div>
                      <ImageUploader
                        style={{ height: "50px" }}
                        buttonText="Upload Profile Image"
                        onChange={this.onClickOfEditProfile}
                        imgExtension={[".jpg", ".png"]}
                        maxFileSize={5242880}
                        singleImage
                        withIcon={false}
                        withLabel={false}
                        buttonStyles={{ padding: "5% 20%", fontSize: "15px" }}
                        fileContainerStyle={{
                          padding: "0",
                          boxShadow: "none",
                          borderRadius: 0,
                          background: "none",
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div> */}

                  <div className="col-12">
                    <TabList>
                      <Tab>
                        {/* <h3
                          style={{
                            color: getColorCodeBasedOnKey(
                              "text_information_label_my_profile"
                            ),
                          }}
                        > */}
                        {getValueFromLanguageObject("str_personal_detail")
                          ? getValueFromLanguageObject("str_personal_detail")
                          : "PERSONAL DETAIL"}
                      </Tab>
                      <Tab>
                        {/* <h3
                          style={{
                            color: getColorCodeBasedOnKey(
                              "text_information_label_my_profile"
                            ),
                          }}
                        > */}
                        {getValueFromLanguageObject("str_address_details")
                          ? getValueFromLanguageObject("str_address_details")
                          : "ADDRESS DETAILS"}
                        {/* </h3> */}
                      </Tab>
                    </TabList>
                    <TabPanel>
                      <div className="row">
                        <div className="col-lg-12">
                          <div className="theme-card">
                            <form className="theme-form">
                              <div className="row form-group">
                                <div className="col-md-3 col-lg-2">
                                  <Avatar
                                    size="170"
                                    className="profile-avatr"
                                    name={
                                      userDetails.profilepic
                                        ? ""
                                        : userDetails.username
                                    }
                                    round={true}
                                    src={
                                      userDetails.profilepic
                                        ? getProfileImageUrl(
                                            userDetails.profilepic,
                                            masterDetailsObj
                                          )
                                        : ""
                                    }
                                  />

                                  <ImageUploader
                                    buttonText={
                                      getValueFromLanguageObject(
                                        "str_upload_profile_image"
                                      )
                                        ? getValueFromLanguageObject(
                                            "str_upload_profile_image"
                                          )
                                        : "Upload profile image"
                                    }
                                    onChange={this.onClickOfEditProfile}
                                    imgExtension={[".jpg", ".png", ".jpeg"]}
                                    maxFileSize={5242880}
                                    singleImage
                                    withIcon={false}
                                    withLabel={false}
                                    buttonStyles={{
                                      fontSize: "14px",
                                      padding: "10px",
                                    }}
                                    fileContainerStyle={{
                                      padding: "0",
                                      boxShadow: "none",
                                      borderRadius: 0,
                                      background: "none",
                                    }}
                                  />
                                </div>
                                <div className="col-lg-10 col-md-9">
                                  <div className="row">
                                    {signupOptions &&
                                      signupOptions.length > 0 &&
                                      signupOptions[0].visible === 1 && (
                                        <div className="col-md-6">
                                          <label
                                            htmlFor="Name"
                                            style={{
                                              color: getColorCodeBasedOnKey(
                                                "text_name_label_my_profile"
                                              ),
                                            }}
                                          >
                                            {getValueFromLanguageObject(
                                              "str_name"
                                            )
                                              ? getValueFromLanguageObject(
                                                  "str_name"
                                                )
                                              : "Name"}
                                          </label>
                                          <input
                                            id="username"
                                            type="text"
                                            onChange={
                                              this.handleChangeOfUserName
                                            }
                                            className="form-control"
                                            value={this.state.userName}
                                            placeholder={
                                              getValueFromLanguageObject(
                                                "str_enter_your_name"
                                              )
                                                ? getValueFromLanguageObject(
                                                    "str_enter_your_name"
                                                  )
                                                : "Enter Your Name"
                                            }
                                            required={true}
                                            maxLength="30"
                                            minLength="2"
                                          />
                                        </div>
                                        // </div>
                                      )}
                                    {signupOptions &&
                                      signupOptions.length > 0 &&
                                      signupOptions[5].visible === 1 && (
                                        <div className="col-md-6">
                                          <label
                                            htmlFor="cellnumber"
                                            style={{
                                              color: getColorCodeBasedOnKey(
                                                "text_cell_number_label_my_profile"
                                              ),
                                            }}
                                          >
                                            {getValueFromLanguageObject(
                                              "mobile_number"
                                            )
                                              ? getValueFromLanguageObject(
                                                  "mobile_number"
                                                )
                                              : "Mobile number"}
                                          </label>
                                          <input
                                            id="cellnumber"
                                            type="tel"
                                            onChange={
                                              this.handleChangeOfCellNumber
                                            }
                                            className="form-control"
                                            value={this.state.cellNumber}
                                            placeholder={
                                              getValueFromLanguageObject(
                                                "str_enter_mobile_number"
                                              )
                                                ? getValueFromLanguageObject(
                                                    "str_enter_mobile_number"
                                                  )
                                                : "Enter Mobile Number"
                                            }
                                            required={true}
                                            maxLength={
                                              maxCellnumberDigitLimitInt
                                            }
                                            pattern="[0-9]{10}"
                                          />
                                        </div>
                                      )}
                                    {signupOptions &&
                                      signupOptions.length > 0 &&
                                      signupOptions[1].visible === 1 && (
                                        // <div className="row">
                                        <div className="col-md-6">
                                          <label
                                            htmlFor="email"
                                            style={{
                                              color: getColorCodeBasedOnKey(
                                                "text_email_label_my_profile"
                                              ),
                                            }}
                                          >
                                            {getValueFromLanguageObject(
                                              "str_email_address"
                                            )
                                              ? getValueFromLanguageObject(
                                                  "str_email_address"
                                                )
                                              : "Email Address"}
                                          </label>
                                          <input
                                            type="text"
                                            className="form-control"
                                            id="emailId"
                                            placeholder={
                                              getValueFromLanguageObject(
                                                "str_enter_email_address"
                                              )
                                                ? getValueFromLanguageObject(
                                                    "str_enter_email_address"
                                                  )
                                                : "Enter Email Address"
                                            }
                                            value={this.state.emailId}
                                            minLength="5"
                                            pattern="/^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/"
                                            maxLength="64"
                                            onChange={
                                              this.handleChangeOfEmailId
                                            }
                                          />
                                        </div>
                                        // </div>
                                      )}

                                    {signupOptions &&
                                      signupOptions.length > 0 &&
                                      signupOptions[2].visible === 1 && (
                                        <div>
                                          <label
                                            htmlFor="gstin"
                                            style={{
                                              color: getColorCodeBasedOnKey(
                                                "text_gstin_signup"
                                              ),
                                            }}
                                          >
                                            {getValueFromLanguageObject(
                                              "str_gstin"
                                            )
                                              ? getValueFromLanguageObject(
                                                  "str_gstin"
                                                )
                                              : "GSTIN"}
                                          </label>
                                          <input
                                            type="text"
                                            className="form-control"
                                            id="gstin"
                                            placeholder={
                                              getValueFromLanguageObject(
                                                "str_enter_gstin"
                                              )
                                                ? getValueFromLanguageObject(
                                                    "str_enter_gstin"
                                                  )
                                                : "Enter GSTIN"
                                            }
                                            required={
                                              signupOptions[2].mandatory === 1
                                                ? true
                                                : false
                                            }
                                            value={this.state.gstin}
                                            onChange={this.handleChangeOfGSTIN}
                                          />
                                        </div>
                                      )}

                                    {signupOptions &&
                                      signupOptions.length > 0 &&
                                      signupOptions[3].visible === 1 && (
                                        <div>
                                          <label
                                            htmlFor="companyname"
                                            style={{
                                              color: getColorCodeBasedOnKey(
                                                "text_company_name_label_my_profile"
                                              ),
                                            }}
                                          >
                                            {getValueFromLanguageObject(
                                              "str_company_name"
                                            )
                                              ? getValueFromLanguageObject(
                                                  "str_company_name"
                                                )
                                              : "Company Name"}
                                          </label>
                                          <input
                                            type="text"
                                            className="form-control"
                                            id="companyname"
                                            placeholder={
                                              getValueFromLanguageObject(
                                                "str_enter_company_name"
                                              )
                                                ? getValueFromLanguageObject(
                                                    "str_enter_company_name"
                                                  )
                                                : "Enter Company Name"
                                            }
                                            required=""
                                            value={this.state.companyName}
                                            minLength="2"
                                            maxLength="30"
                                            onChange={
                                              this.handleChangeOfCompanyName
                                            }
                                          />
                                        </div>
                                      )}

                                    {signupOptions &&
                                      signupOptions.length > 0 &&
                                      signupOptions[4].visible === 1 && (
                                        <div className="col-md-6">
                                          <label
                                            htmlFor="city"
                                            style={{
                                              color: getColorCodeBasedOnKey(
                                                "text_city_label_my_profile"
                                              ),
                                            }}
                                          >
                                            {getValueFromLanguageObject(
                                              "str_city"
                                            )
                                              ? getValueFromLanguageObject(
                                                  "str_city"
                                                )
                                              : "City"}
                                          </label>
                                          <select
                                            style={cityDropDownStyle}
                                            className="form-control custom-city-profile custom-city"
                                            name="city"
                                            required={true}
                                            value={this.state.cityId}
                                            onChange={this.handleChangeOfCity}
                                          >
                                            <option
                                              value={""}
                                              style={{
                                                color: getColorCodeBasedOnKey(
                                                  "text_select_city_label_my_profile"
                                                ),
                                              }}
                                            >
                                              {getValueFromLanguageObject(
                                                "str_select_city"
                                              )
                                                ? getValueFromLanguageObject(
                                                    "str_select_city"
                                                  )
                                                : "Select City"}
                                            </option>
                                            {cities.map((city, index) => (
                                              <option
                                                key={index}
                                                value={city.id}
                                                style={{
                                                  color: getColorCodeBasedOnKey(
                                                    "text_cities_label_my_profile"
                                                  ),
                                                }}
                                              >
                                                {city.name}
                                              </option>
                                            ))}
                                          </select>
                                        </div>
                                      )}
                                  </div>
                                  {this.renderSaveChanges()}
                                </div>
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>
                    </TabPanel>
                    <TabPanel>
                      {/* billing address start */}
                      <div className="row">
                        <div className="col-lg-6">
                          <div className="theme-card">
                            <form className="theme-form">
                              <h3
                                style={{
                                  color: getColorCodeBasedOnKey(
                                    "text_billing_details_checkout"
                                  ),
                                }}
                              >
                                <div className="checkout-page">
                                  <div className="checkout-box">
                                    <div className="checkout-title">
                                      {getValueFromLanguageObject(
                                        "billing_details"
                                      )
                                        ? getValueFromLanguageObject(
                                            "billing_details"
                                          )
                                        : "Billing Details"}
                                    </div>
                                  </div>
                                </div>
                              </h3>
                              <div className="form-group row">
                                {addressDetails &&
                                  addressDetails.length > 0 &&
                                  addressDetails[0].visible === 1 && (
                                    <div className="col-md-6">
                                      <label
                                        htmlFor="Name"
                                        style={{
                                          color: getColorCodeBasedOnKey(
                                            "text_name_checkout"
                                          ),
                                        }}
                                      >
                                        {getValueFromLanguageObject("str_name")
                                          ? getValueFromLanguageObject(
                                              "str_name"
                                            )
                                          : "Name"}
                                        <span className="text-danger">
                                          {addressDetails[0].mandatory === 1 &&
                                            "*"}
                                        </span>
                                      </label>
                                      <input
                                        className="form-control mb-1"
                                        type="text"
                                        name="billingName"
                                        required={
                                          addressDetails[0].mandatory === 1
                                            ? true
                                            : false
                                        }
                                        value={this.state.billingName}
                                        onChange={this.onChangeOfBillingName}
                                        placeholder={
                                          getValueFromLanguageObject(
                                            "str_enter_name"
                                          )
                                            ? getValueFromLanguageObject(
                                                "str_enter_name"
                                              )
                                            : "Enter Name"
                                        }
                                      />
                                      {this.validator.message(
                                        "billingName",
                                        this.state.billingName,
                                        addressDetails[0].mandatory === 1
                                          ? "required"
                                          : ""
                                      )}
                                    </div>
                                  )}
                                {addressDetails &&
                                  addressDetails.length > 0 &&
                                  addressDetails[1].visible === 1 && (
                                    <div className="col-md-6">
                                      <label
                                        htmlFor="cellnumber"
                                        style={{
                                          color: getColorCodeBasedOnKey(
                                            "text_mobile_number_checkout"
                                          ),
                                        }}
                                      >
                                        {getValueFromLanguageObject(
                                          "mobile_number"
                                        )
                                          ? getValueFromLanguageObject(
                                              "mobile_number"
                                            )
                                          : "Mobile number"}
                                        <span className="text-danger">
                                          {addressDetails[1].mandatory === 1 &&
                                            "*"}
                                        </span>
                                      </label>
                                      <input
                                        className="form-control mb-1"
                                        type="number"
                                        name="billingMobileNumber"
                                        required={
                                          addressDetails[1].mandatory === 1
                                            ? true
                                            : false
                                        }
                                        // pattern="/^\?([0-9]{10})$/"
                                        value={this.state.billingMobileNumber}
                                        onChange={
                                          this.onChangeOfBillingMobileNumber
                                        }
                                        placeholder={
                                          getValueFromLanguageObject(
                                            "str_enter_mobile_number"
                                          )
                                            ? getValueFromLanguageObject(
                                                "str_enter_mobile_number"
                                              )
                                            : "Enter Mobile Number"
                                        }
                                      />
                                      {this.validator.message(
                                        "billingMobileNumber",
                                        this.state.billingMobileNumber,
                                        addressDetails[1].mandatory === 1
                                          ? `required|regex:^[0-9]{${maxCellnumberDigitLimitInt}}$`
                                          : `regex:^[0-9]{${maxCellnumberDigitLimitInt}}$`
                                      )}
                                    </div>
                                  )}
                                {addressDetails &&
                                  addressDetails.length > 0 &&
                                  addressDetails[2].visible === 1 && (
                                    <div className="col-md-6">
                                      <label
                                        htmlFor="email"
                                        style={{
                                          marginTop: "30px",
                                          color: getColorCodeBasedOnKey(
                                            "text_email_address_checkout"
                                          ),
                                        }}
                                      >
                                        {getValueFromLanguageObject(
                                          "str_email_address"
                                        )
                                          ? getValueFromLanguageObject(
                                              "str_email_address"
                                            )
                                          : "Email Address"}
                                        <span className="text-danger">
                                          {addressDetails[2].mandatory === 1 &&
                                            "*"}
                                        </span>
                                      </label>
                                      <input
                                        className="form-control mb-1"
                                        type="text"
                                        name="billingEmailAddress"
                                        value={this.state.billingEmailAddress}
                                        required={
                                          addressDetails[2].mandatory === 1
                                            ? true
                                            : false
                                        }
                                        onChange={
                                          this.onChangeOfBillingEmailAddress
                                        }
                                        placeholder={
                                          getValueFromLanguageObject(
                                            "str_enter_email_address"
                                          )
                                            ? getValueFromLanguageObject(
                                                "str_enter_email_address"
                                              )
                                            : "Enter Email Address"
                                        }
                                      />
                                      {this.validator.message(
                                        "billingEmailAddress",
                                        this.state.billingEmailAddress,
                                        addressDetails[2].mandatory === 1
                                          ? "required|email"
                                          : "email"
                                      )}
                                    </div>
                                  )}
                                {addressDetails &&
                                  addressDetails.length > 0 &&
                                  addressDetails[3].visible === 1 && (
                                    <div className="col-md-6">
                                      <label
                                        htmlFor="companyName"
                                        style={{
                                          marginTop: "30px",
                                          color: getColorCodeBasedOnKey(
                                            "text_company_name_checkout"
                                          ),
                                        }}
                                      >
                                        {getValueFromLanguageObject(
                                          "str_company_name"
                                        )
                                          ? getValueFromLanguageObject(
                                              "str_company_name"
                                            )
                                          : "Company Name"}
                                        <span className="text-danger">
                                          {addressDetails[3].mandatory === 1 &&
                                            "*"}
                                        </span>
                                      </label>
                                      <input
                                        className="form-control mb-1"
                                        type="text"
                                        name="billingCompanyName"
                                        value={this.state.billingCompanyName}
                                        required={
                                          addressDetails[3].mandatory === 1
                                            ? true
                                            : false
                                        }
                                        onChange={
                                          this.onChangeOfBillingCompanyName
                                        }
                                        placeholder={
                                          getValueFromLanguageObject(
                                            "str_enter_company_name"
                                          )
                                            ? getValueFromLanguageObject(
                                                "str_enter_company_name"
                                              )
                                            : "Enter Company Name"
                                        }
                                      />
                                      {this.validator.message(
                                        "billingCompanyName",
                                        this.state.billingCompanyName,
                                        addressDetails[3].mandatory === 1
                                          ? "required"
                                          : ""
                                      )}
                                    </div>
                                  )}
                                {addressDetails &&
                                  addressDetails.length > 0 &&
                                  addressDetails[4].visible === 1 && (
                                    <div className="col-md-6">
                                      <label
                                        htmlFor="gstin"
                                        style={{
                                          marginTop: "30px",
                                          color: getColorCodeBasedOnKey(
                                            "text_gstin_checkout"
                                          ),
                                        }}
                                      >
                                        {getValueFromLanguageObject(
                                          "str_gst_in"
                                        )
                                          ? getValueFromLanguageObject(
                                              "str_gst_in"
                                            )
                                          : "GSTIN"}
                                        <span className="text-danger">
                                          {addressDetails[4].mandatory === 1 &&
                                            "*"}
                                        </span>
                                      </label>
                                      <input
                                        className="form-control mb-1"
                                        type="text"
                                        name="billingGSTIN"
                                        value={this.state.billingGSTIN}
                                        required={
                                          addressDetails[4].mandatory === 1
                                            ? true
                                            : false
                                        }
                                        onChange={this.onChangeOfBillingGSTIN}
                                        placeholder={
                                          getValueFromLanguageObject(
                                            "str_enter_gstin"
                                          )
                                            ? getValueFromLanguageObject(
                                                "str_enter_gstin"
                                              )
                                            : "Enter GSTIN"
                                        }
                                      />
                                      {this.validator.message(
                                        "billingGSTIN",
                                        this.state.billingGSTIN,
                                        addressDetails[4].mandatory === 1
                                          ? [
                                              "required",
                                              {
                                                regex:
                                                  "^([0][1-9]|[1-2][0-9]|[3][0-7])([a-zA-Z]{5}[0-9]{4}[a-zA-Z]{1}[1-9a-zA-Z]{1}[zZ]{1}[0-9a-zA-Z]{1})+$",
                                              },
                                            ]
                                          : [
                                              "",
                                              {
                                                regex:
                                                  "^([0][1-9]|[1-2][0-9]|[3][0-7])([a-zA-Z]{5}[0-9]{4}[a-zA-Z]{1}[1-9a-zA-Z]{1}[zZ]{1}[0-9a-zA-Z]{1})+$",
                                              },
                                            ]
                                      )}
                                    </div>
                                  )}
                                {addressDetails &&
                                  addressDetails.length > 0 &&
                                  addressDetails[5].visible === 1 && (
                                    <div className="col-md-6">
                                      <label
                                        htmlFor="addressLine1"
                                        style={{
                                          marginTop: "30px",
                                          color: getColorCodeBasedOnKey(
                                            "text_address_line_1_checkout"
                                          ),
                                        }}
                                      >
                                        {getValueFromLanguageObject(
                                          "str_address_line_1"
                                        )
                                          ? getValueFromLanguageObject(
                                              "str_address_line_1"
                                            )
                                          : "Address Line 1"}
                                        <span className="text-danger">
                                          {addressDetails[5].mandatory === 1 &&
                                            "*"}
                                        </span>
                                      </label>
                                      <input
                                        className="form-control mb-1"
                                        type="text"
                                        name="billingAddressLine1"
                                        value={this.state.billingAddressLine1}
                                        required={
                                          addressDetails[5].mandatory === 1
                                            ? true
                                            : false
                                        }
                                        onChange={
                                          this.onChangeOfBillingAddressLine1
                                        }
                                        placeholder={
                                          getValueFromLanguageObject(
                                            "str_enter_billing_address_line_1"
                                          )
                                            ? getValueFromLanguageObject(
                                                "str_enter_billing_address_line_1"
                                              )
                                            : "Enter Billing Address Line 1"
                                        }
                                      />
                                      {this.validator.message(
                                        "billingAddressLine1",
                                        this.state.billingAddressLine1,
                                        addressDetails[5].mandatory === 1
                                          ? "required|min:2|max:256"
                                          : "min:2|max:256"
                                      )}
                                    </div>
                                  )}
                                {addressDetails &&
                                  addressDetails.length > 0 &&
                                  addressDetails[6].visible === 1 && (
                                    <div className="col-md-6">
                                      <label
                                        htmlFor="addressLine2"
                                        style={{
                                          marginTop: "30px",
                                          color: getColorCodeBasedOnKey(
                                            "text_address_line_2_checkout"
                                          ),
                                        }}
                                      >
                                        {getValueFromLanguageObject(
                                          "str_address_line_2"
                                        )
                                          ? getValueFromLanguageObject(
                                              "str_address_line_2"
                                            )
                                          : "Address Line 2"}
                                        <span className="text-danger">
                                          {addressDetails[6].mandatory === 1 &&
                                            "*"}
                                        </span>
                                      </label>
                                      <input
                                        className="form-control mb-1"
                                        type="text"
                                        name="billingAddressLine2"
                                        value={this.state.billingAddressLine2}
                                        required={
                                          addressDetails[6].mandatory === 1
                                            ? true
                                            : false
                                        }
                                        onChange={
                                          this.onChangeOfBillingAddressLine2
                                        }
                                        placeholder={
                                          getValueFromLanguageObject(
                                            "str_enter_billing_address_line_2"
                                          )
                                            ? getValueFromLanguageObject(
                                                "str_enter_billing_address_line_2"
                                              )
                                            : "Enter Billing Address Line 2"
                                        }
                                      />
                                      {this.validator.message(
                                        "billingAddressLine2",
                                        this.state.billingAddressLine2,
                                        addressDetails[6].mandatory === 1
                                          ? "required|min:2|max:256"
                                          : "min:2|max:256"
                                      )}
                                    </div>
                                  )}
                                {addressDetails &&
                                  addressDetails.length > 0 &&
                                  addressDetails[12].visible === 1 && (
                                    <div className="col-md-6">
                                      <label
                                        htmlFor="zoneNumber"
                                        style={{
                                          marginTop: "30px",
                                          color: getColorCodeBasedOnKey(
                                            "text_zone_number_checkout"
                                          ),
                                        }}
                                      >
                                        {getValueFromLanguageObject(
                                          "str_zone_number"
                                        )
                                          ? getValueFromLanguageObject(
                                              "str_zone_number"
                                            )
                                          : "Zone Number/Name"}
                                        <span className="text-danger">
                                          {addressDetails[12].mandatory === 1 &&
                                            "*"}
                                        </span>
                                      </label>
                                      <input
                                        className="form-control mb-1"
                                        type="text"
                                        name="billingZonenumber"
                                        value={this.state.billingZonenumber}
                                        required={
                                          addressDetails[12].mandatory === 1
                                            ? true
                                            : false
                                        }
                                        onChange={
                                          this.onChangeOfBillingZonenumber
                                        }
                                        placeholder={
                                          getValueFromLanguageObject(
                                            "str_enter_billing_zone_number"
                                          )
                                            ? getValueFromLanguageObject(
                                                "str_enter_billing_zone_number"
                                              )
                                            : "Enter Billing Zone Number/Name"
                                        }
                                      />
                                      {this.validator.message(
                                        "billingZonenumber",
                                        this.state.billingZonenumber,
                                        addressDetails[12].mandatory === 1
                                          ? "required|min:1|max:256"
                                          : "min:1|max:256"
                                      )}
                                    </div>
                                  )}
                                {addressDetails &&
                                  addressDetails.length > 0 &&
                                  addressDetails[13].visible === 1 && (
                                    <div className="col-md-6">
                                      <label
                                        htmlFor="streetNumber"
                                        style={{
                                          marginTop: "30px",
                                          color: getColorCodeBasedOnKey(
                                            "text_street_number_checkout"
                                          ),
                                        }}
                                      >
                                        {getValueFromLanguageObject(
                                          "str_street_number"
                                        )
                                          ? getValueFromLanguageObject(
                                              "str_street_number"
                                            )
                                          : "Street Number/Name"}
                                        <span className="text-danger">
                                          {addressDetails[13].mandatory === 1 &&
                                            "*"}
                                        </span>
                                      </label>
                                      <input
                                        className="form-control mb-1"
                                        type="text"
                                        name="billingStreetnumber"
                                        value={this.state.billingStreetnumber}
                                        required={
                                          addressDetails[13].mandatory === 1
                                            ? true
                                            : false
                                        }
                                        onChange={
                                          this.onChangeOfBillingStreetnumber
                                        }
                                        placeholder={
                                          getValueFromLanguageObject(
                                            "str_enter_billing_street_number"
                                          )
                                            ? getValueFromLanguageObject(
                                                "str_enter_billing_street_number"
                                              )
                                            : "Enter Billing Street Number/Name"
                                        }
                                      />
                                      {this.validator.message(
                                        "billingStreetnumber",
                                        this.state.billingStreetnumber,
                                        addressDetails[13].mandatory === 1
                                          ? "required|min:1|max:256"
                                          : "min:1|max:256"
                                      )}
                                    </div>
                                  )}
                                {addressDetails &&
                                  addressDetails.length > 0 &&
                                  addressDetails[14].visible === 1 && (
                                    <div className="col-md-6">
                                      <label
                                        htmlFor="buildingNumber"
                                        style={{
                                          marginTop: "30px",
                                          color: getColorCodeBasedOnKey(
                                            "text_building_number_checkout"
                                          ),
                                        }}
                                      >
                                        {getValueFromLanguageObject(
                                          "str_building_number"
                                        )
                                          ? getValueFromLanguageObject(
                                              "str_building_number"
                                            )
                                          : "Building Number/Name"}
                                        <span className="text-danger">
                                          {addressDetails[14].mandatory === 1 &&
                                            "*"}
                                        </span>
                                      </label>
                                      <input
                                        className="form-control mb-1"
                                        type="text"
                                        name="billingBuildingnumber"
                                        value={this.state.billingBuildingnumber}
                                        required={
                                          addressDetails[14].mandatory === 1
                                            ? true
                                            : false
                                        }
                                        onChange={
                                          this.onChangeOfBillingBuildingnumber
                                        }
                                        placeholder={
                                          getValueFromLanguageObject(
                                            "str_enter_billing_building_number"
                                          )
                                            ? getValueFromLanguageObject(
                                                "str_enter_billing_building_number"
                                              )
                                            : "Enter Billing Building Number/Name"
                                        }
                                      />
                                      {this.validator.message(
                                        "billingBuildingnumber",
                                        this.state.billingBuildingnumber,
                                        addressDetails[14].mandatory === 1
                                          ? "required|min:1|max:256"
                                          : "min:1|max:256"
                                      )}
                                    </div>
                                  )}
                                {addressDetails &&
                                  addressDetails.length > 0 &&
                                  addressDetails[15].visible === 1 && (
                                    <div className="col-md-6">
                                      <label
                                        htmlFor="unitNumber"
                                        style={{
                                          marginTop: "30px",
                                          color: getColorCodeBasedOnKey(
                                            "text_unit_number_checkout"
                                          ),
                                        }}
                                      >
                                        {getValueFromLanguageObject(
                                          "str_unit_number"
                                        )
                                          ? getValueFromLanguageObject(
                                              "str_unit_number"
                                            )
                                          : "Unit Number/Name"}
                                        <span className="text-danger">
                                          {addressDetails[15].mandatory === 1 &&
                                            "*"}
                                        </span>
                                      </label>
                                      <input
                                        className="form-control mb-1"
                                        type="text"
                                        name="billingUnitnumber"
                                        value={this.state.billingUnitnumber}
                                        required={
                                          addressDetails[15].mandatory === 1
                                            ? true
                                            : false
                                        }
                                        onChange={
                                          this.onChangeOfBillingUnitnumber
                                        }
                                        placeholder={
                                          getValueFromLanguageObject(
                                            "str_enter_billing_unit_number"
                                          )
                                            ? getValueFromLanguageObject(
                                                "str_enter_billing_unit_number"
                                              )
                                            : "Enter Billing Unit Number/Name"
                                        }
                                      />
                                      {this.validator.message(
                                        "billingUnitnumber",
                                        this.state.billingUnitnumber,
                                        addressDetails[15].mandatory === 1
                                          ? "required|min:1|max:256"
                                          : "min:1|max:256"
                                      )}
                                    </div>
                                  )}
                                {addressDetails &&
                                  addressDetails.length > 0 &&
                                  addressDetails[7].visible === 1 && (
                                    <div className="col-md-6">
                                      <label
                                        htmlFor="landmark"
                                        style={{
                                          marginTop: "30px",
                                          color: getColorCodeBasedOnKey(
                                            "text_landmark_checkout"
                                          ),
                                        }}
                                      >
                                        {getValueFromLanguageObject(
                                          "str_landmark"
                                        )
                                          ? getValueFromLanguageObject(
                                              "str_landmark"
                                            )
                                          : "Landmark"}
                                        <span className="text-danger">
                                          {addressDetails[7].mandatory === 1 &&
                                            "*"}
                                        </span>
                                      </label>
                                      <input
                                        className="form-control mb-1"
                                        type="text"
                                        name="billingLandmark"
                                        value={this.state.billingLandmark}
                                        required={
                                          addressDetails[7].mandatory === 1
                                            ? true
                                            : false
                                        }
                                        onChange={
                                          this.onChangeOfBillingLandmark
                                        }
                                        placeholder={
                                          getValueFromLanguageObject(
                                            "str_enter_landmark"
                                          )
                                            ? getValueFromLanguageObject(
                                                "str_enter_landmark"
                                              )
                                            : "Enter Landmark"
                                        }
                                      />
                                      {this.validator.message(
                                        "billingLandmark",
                                        this.state.billingLandmark,
                                        addressDetails[7].mandatory === 1
                                          ? "required|min:2|max:256"
                                          : "min:2|max:256"
                                      )}
                                    </div>
                                  )}
                                {addressDetails &&
                                  addressDetails.length > 0 &&
                                  addressDetails[9].visible === 1 && (
                                    <div className="col-md-6">
                                      <label
                                        htmlFor="countryName"
                                        style={{
                                          marginTop: "30px",
                                          color: getColorCodeBasedOnKey(
                                            "text_country_checkout"
                                          ),
                                        }}
                                      >
                                        {getValueFromLanguageObject(
                                          "str_country"
                                        )
                                          ? getValueFromLanguageObject(
                                              "str_country"
                                            )
                                          : "Country"}
                                        <span className="text-danger">
                                          {addressDetails[9].mandatory === 1 &&
                                            "*"}
                                        </span>
                                      </label>
                                      <select
                                        style={cityDropDownStyle}
                                        className="form-control custom-city-profile custom-city mb-0"
                                        name="billingCountry"
                                        value={this.state.billingCountry}
                                        onChange={this.onChangeOfBillingCountry}
                                        required={
                                          addressDetails[9].mandatory === 1
                                            ? true
                                            : false
                                        }
                                      >
                                        <option
                                          value=""
                                          style={{
                                            color: getColorCodeBasedOnKey(
                                              "text_select_country_checkout"
                                            ),
                                          }}
                                        >
                                          {getValueFromLanguageObject(
                                            "str_select_country"
                                          )
                                            ? getValueFromLanguageObject(
                                                "str_select_country"
                                              )
                                            : "Select Country"}
                                        </option>
                                        {countryList.length > 0 &&
                                          countryList.map((country) => {
                                            return (
                                              <option
                                                value={country.id}
                                                style={{
                                                  color: getColorCodeBasedOnKey(
                                                    "text_country_name_checkout"
                                                  ),
                                                }}
                                              >
                                                {country.name}
                                              </option>
                                            );
                                          })}
                                      </select>
                                      {this.validator.message(
                                        "billingCountry",
                                        this.state.billingCountry,
                                        addressDetails[9].mandatory === 1
                                          ? "required"
                                          : ""
                                      )}
                                    </div>
                                  )}
                                {addressDetails &&
                                  addressDetails.length > 0 &&
                                  addressDetails[10].visible === 1 && (
                                    <div className="col-md-6">
                                      <label
                                        htmlFor="stateName"
                                        style={{
                                          marginTop: "30px",
                                          color: getColorCodeBasedOnKey(
                                            "text_state_checkout"
                                          ),
                                        }}
                                      >
                                        {getValueFromLanguageObject("str_state")
                                          ? getValueFromLanguageObject(
                                              "str_state"
                                            )
                                          : "State"}
                                        <span className="text-danger">
                                          {addressDetails[10].mandatory === 1 &&
                                            "*"}
                                        </span>
                                      </label>
                                      <select
                                        style={cityDropDownStyle}
                                        className="form-control custom-city-profile custom-city"
                                        name="billingState"
                                        value={this.state.billingState}
                                        onChange={this.onChangeOfBillingState}
                                        required={
                                          addressDetails[10].mandatory === 1
                                            ? true
                                            : false
                                        }
                                      >
                                        <option
                                          value=""
                                          style={{
                                            color: getColorCodeBasedOnKey(
                                              "text_select_state_checkout"
                                            ),
                                          }}
                                        >
                                          {getValueFromLanguageObject(
                                            "str_select_state"
                                          )
                                            ? getValueFromLanguageObject(
                                                "str_select_state"
                                              )
                                            : "Select State"}
                                        </option>
                                        {stateList.length > 0 &&
                                          stateList.map((state) => {
                                            return (
                                              <option
                                                value={state.id}
                                                style={{
                                                  color: getColorCodeBasedOnKey(
                                                    "text_state_name_checkout"
                                                  ),
                                                }}
                                              >
                                                {state.name}
                                              </option>
                                            );
                                          })}
                                      </select>
                                      {this.validator.message(
                                        "billingState",
                                        this.state.billingState,
                                        addressDetails[10].mandatory === 1
                                          ? "required"
                                          : ""
                                      )}
                                    </div>
                                  )}
                                {addressDetails &&
                                  addressDetails.length > 0 &&
                                  addressDetails[11].visible === 1 && (
                                    <div className="col-md-6">
                                      <label
                                        htmlFor="cityName"
                                        style={{
                                          // marginTop: "30px",
                                          color: getColorCodeBasedOnKey(
                                            "text_city_checkout"
                                          ),
                                        }}
                                      >
                                        {getValueFromLanguageObject(
                                          "str_town_city"
                                        )
                                          ? getValueFromLanguageObject(
                                              "str_town_city"
                                            )
                                          : "Town/City"}
                                        <span className="text-danger">
                                          {addressDetails[11].mandatory === 1 &&
                                            "*"}
                                        </span>
                                      </label>
                                      <select
                                        style={cityDropDownStyle}
                                        className="form-control custom-city-profile custom-city mb-0"
                                        value={this.state.billingCity}
                                        required={
                                          addressDetails[11].mandatory === 1
                                            ? true
                                            : false
                                        }
                                        onChange={this.onChangeOfBillingCity}
                                      >
                                        <option
                                          value=""
                                          style={{
                                            color: getColorCodeBasedOnKey(
                                              "text_select_city_checkout"
                                            ),
                                          }}
                                        >
                                          {getValueFromLanguageObject(
                                            "str_select_city"
                                          )
                                            ? getValueFromLanguageObject(
                                                "str_select_city"
                                              )
                                            : "Select City"}
                                        </option>
                                        {cityList.length > 0 &&
                                          cityList.map((city) => {
                                            return (
                                              <option
                                                value={city.id}
                                                style={{
                                                  color: getColorCodeBasedOnKey(
                                                    "text_city_name_checkout"
                                                  ),
                                                }}
                                              >
                                                {city.name}
                                              </option>
                                            );
                                          })}
                                      </select>
                                      {this.validator.message(
                                        "billingCity",
                                        this.state.billingCity,
                                        addressDetails[11].mandatory === 1
                                          ? "required"
                                          : ""
                                      )}
                                    </div>
                                  )}
                                {addressDetails &&
                                  addressDetails.length > 0 &&
                                  addressDetails[8].visible === 1 && (
                                    <div className="col-md-6">
                                      <label
                                        htmlFor="postalCode"
                                        style={{
                                          // marginTop: "30px",
                                          color: getColorCodeBasedOnKey(
                                            "text_postal_code_checkout"
                                          ),
                                        }}
                                      >
                                        {getValueFromLanguageObject(
                                          "str_postal_code"
                                        )
                                          ? getValueFromLanguageObject(
                                              "str_postal_code"
                                            )
                                          : "Postal Code"}
                                        <span className="text-danger">
                                          {addressDetails[8].mandatory === 1 &&
                                            "*"}
                                        </span>
                                      </label>
                                      <input
                                        className="form-control mb-1"
                                        type="number"
                                        name="billingPincode"
                                        value={this.state.billingPincode}
                                        required={
                                          addressDetails[8].mandatory === 1
                                            ? true
                                            : false
                                        }
                                        onChange={this.onChangeOfBillingPincode}
                                        placeholder={
                                          getValueFromLanguageObject(
                                            "str_enter_postal_code"
                                          )
                                            ? getValueFromLanguageObject(
                                                "str_enter_postal_code"
                                              )
                                            : "Enter Postal Code"
                                        }
                                      />
                                      {this.validator.message(
                                        "billingPincode",
                                        this.state.billingPincode,
                                        addressDetails[8].mandatory === 1
                                          ? "required|integer"
                                          : "integer"
                                      )}
                                    </div>
                                  )}
                                {/* {this.renderSaveChanges()} */}
                              </div>
                            </form>
                          </div>
                        </div>
                        {/* billing address end */}
                        {/* shipping address start */}
                        <div className="col-lg-6">
                          <div className="theme-card">
                            <form className="theme-form">
                              <h3
                                style={{
                                  color: getColorCodeBasedOnKey(
                                    "text_shipping_details_checkout"
                                  ),
                                }}
                              >
                                <div className="checkout-page">
                                  <div className="checkout-box">
                                    <div className="checkout-title">
                                      {getValueFromLanguageObject(
                                        "shipping_address"
                                      )
                                        ? getValueFromLanguageObject(
                                            "shipping_address"
                                          )
                                        : "Shipping Address"}
                                    </div>
                                  </div>
                                </div>
                              </h3>
                              <div className="form-group row">
                                {addressDetails &&
                                  addressDetails.length > 0 &&
                                  addressDetails[0].visible === 1 && (
                                    <div className="col-lg-6">
                                      <label
                                        htmlFor="Name"
                                        style={{
                                          color: getColorCodeBasedOnKey(
                                            "text_name_checkout"
                                          ),
                                        }}
                                      >
                                        {getValueFromLanguageObject("str_name")
                                          ? getValueFromLanguageObject(
                                              "str_name"
                                            )
                                          : "Name"}
                                        <span className="text-danger">
                                          {addressDetails[0].mandatory === 1 &&
                                            "*"}
                                        </span>
                                      </label>
                                      <input
                                        className="form-control mb-1"
                                        type="text"
                                        name="shippingName"
                                        required={
                                          addressDetails[0].mandatory === 1
                                            ? true
                                            : false
                                        }
                                        value={this.state.shippingName}
                                        onChange={this.onChangeOfShippingName}
                                        placeholder={
                                          getValueFromLanguageObject(
                                            "str_enter_name"
                                          )
                                            ? getValueFromLanguageObject(
                                                "str_enter_name"
                                              )
                                            : "Enter Name"
                                        }
                                      />
                                      {this.validator.message(
                                        "shippingName",
                                        this.state.shippingName,
                                        addressDetails[0].mandatory === 1
                                          ? "required"
                                          : ""
                                      )}
                                    </div>
                                  )}
                                {addressDetails &&
                                  addressDetails.length > 0 &&
                                  addressDetails[1].visible === 1 && (
                                    <div className="col-lg-6">
                                      <label
                                        htmlFor="cellnumber"
                                        style={{
                                          color: getColorCodeBasedOnKey(
                                            "text_mobile_number_checkout"
                                          ),
                                        }}
                                      >
                                        {getValueFromLanguageObject(
                                          "mobile_number"
                                        )
                                          ? getValueFromLanguageObject(
                                              "mobile_number"
                                            )
                                          : "Mobile number"}
                                        <span className="text-danger">
                                          {addressDetails[1].mandatory === 1 &&
                                            "*"}
                                        </span>
                                      </label>
                                      <input
                                        className="form-control mb-1"
                                        type="number"
                                        name="shippingMobileNumber"
                                        required={
                                          addressDetails[1].mandatory === 1
                                            ? true
                                            : false
                                        }
                                        // pattern="/^\?([0-9]{10})$/"
                                        value={this.state.shippingMobileNumber}
                                        onChange={
                                          this.onChangeOfShippingMobileNumber
                                        }
                                        placeholder={
                                          getValueFromLanguageObject(
                                            "str_enter_mobile_number"
                                          )
                                            ? getValueFromLanguageObject(
                                                "str_enter_mobile_number"
                                              )
                                            : "Enter Mobile Number"
                                        }
                                      />
                                      {this.validator.message(
                                        "shippingMobileNumber",
                                        this.state.shippingMobileNumber,
                                        addressDetails[1].mandatory === 1
                                          ? `required|regex:^[0-9]{${maxCellnumberDigitLimitInt}}$`
                                          : `regex:^[0-9]{${maxCellnumberDigitLimitInt}}$`
                                      )}
                                    </div>
                                  )}
                                {addressDetails &&
                                  addressDetails.length > 0 &&
                                  addressDetails[2].visible === 1 && (
                                    <div className="col-lg-6">
                                      <label
                                        htmlFor="email"
                                        style={{
                                          marginTop: "30px",
                                          color: getColorCodeBasedOnKey(
                                            "text_email_address_checkout"
                                          ),
                                        }}
                                      >
                                        {getValueFromLanguageObject(
                                          "str_email_address"
                                        )
                                          ? getValueFromLanguageObject(
                                              "str_email_address"
                                            )
                                          : "Email Address"}
                                        <span className="text-danger">
                                          {addressDetails[2].mandatory === 1 &&
                                            "*"}
                                        </span>
                                      </label>
                                      <input
                                        className="form-control mb-1"
                                        type="text"
                                        name="shippingEmailAddress"
                                        value={this.state.shippingEmailAddress}
                                        required={
                                          addressDetails[2].mandatory === 1
                                            ? true
                                            : false
                                        }
                                        onChange={
                                          this.onChangeOfShippingEmailAddress
                                        }
                                        placeholder={
                                          getValueFromLanguageObject(
                                            "str_enter_email_address"
                                          )
                                            ? getValueFromLanguageObject(
                                                "str_enter_email_address"
                                              )
                                            : "Enter Email Address"
                                        }
                                      />
                                      {this.validator.message(
                                        "shippingEmailAddress",
                                        this.state.shippingEmailAddress,
                                        addressDetails[2].mandatory === 1
                                          ? "required|email"
                                          : "email"
                                      )}
                                    </div>
                                  )}
                                {addressDetails &&
                                  addressDetails.length > 0 &&
                                  addressDetails[3].visible === 1 && (
                                    <div className="col-lg-6">
                                      <label
                                        htmlFor="companyName"
                                        style={{
                                          marginTop: "30px",
                                          color: getColorCodeBasedOnKey(
                                            "text_company_name_checkout"
                                          ),
                                        }}
                                      >
                                        {getValueFromLanguageObject(
                                          "str_company_name"
                                        )
                                          ? getValueFromLanguageObject(
                                              "str_company_name"
                                            )
                                          : "Company Name"}
                                        <span className="text-danger">
                                          {addressDetails[3].mandatory === 1 &&
                                            "*"}
                                        </span>
                                      </label>
                                      <input
                                        className="form-control mb-1"
                                        type="text"
                                        name="shippingCompanyName"
                                        value={this.state.shippingCompanyName}
                                        required={
                                          addressDetails[3].mandatory === 1
                                            ? true
                                            : false
                                        }
                                        onChange={
                                          this.onChangeOfShippingCompanyName
                                        }
                                        placeholder={
                                          getValueFromLanguageObject(
                                            "str_enter_company_name"
                                          )
                                            ? getValueFromLanguageObject(
                                                "str_enter_company_name"
                                              )
                                            : "Enter Company Name"
                                        }
                                      />
                                      {this.validator.message(
                                        "shippingCompanyName",
                                        this.state.shippingCompanyName,
                                        addressDetails[3].mandatory === 1
                                          ? "required"
                                          : ""
                                      )}
                                    </div>
                                  )}
                                {addressDetails &&
                                  addressDetails.length > 0 &&
                                  addressDetails[4].visible === 1 && (
                                    <div className="col-lg-6">
                                      <label
                                        htmlFor="gstin"
                                        style={{
                                          marginTop: "30px",
                                          color: getColorCodeBasedOnKey(
                                            "text_gstin_checkout"
                                          ),
                                        }}
                                      >
                                        {getValueFromLanguageObject(
                                          "str_gst_in"
                                        )
                                          ? getValueFromLanguageObject(
                                              "str_gst_in"
                                            )
                                          : "GSTIN"}
                                        <span className="text-danger">
                                          {addressDetails[4].mandatory === 1 &&
                                            "*"}
                                        </span>
                                      </label>
                                      <input
                                        className="form-control mb-1"
                                        type="text"
                                        name="shippingGSTIN"
                                        value={this.state.shippingGSTIN}
                                        required={
                                          addressDetails[4].mandatory === 1
                                            ? true
                                            : false
                                        }
                                        onChange={this.onChangeOfShippingGSTIN}
                                        placeholder={
                                          getValueFromLanguageObject(
                                            "str_enter_gstin"
                                          )
                                            ? getValueFromLanguageObject(
                                                "str_enter_gstin"
                                              )
                                            : "Enter GSTIN"
                                        }
                                      />
                                      {this.validator.message(
                                        "shippingGSTIN",
                                        this.state.shippingGSTIN,
                                        addressDetails[4].mandatory === 1
                                          ? [
                                              "required",
                                              {
                                                regex:
                                                  "^([0][1-9]|[1-2][0-9]|[3][0-7])([a-zA-Z]{5}[0-9]{4}[a-zA-Z]{1}[1-9a-zA-Z]{1}[zZ]{1}[0-9a-zA-Z]{1})+$",
                                              },
                                            ]
                                          : [
                                              "",
                                              {
                                                regex:
                                                  "^([0][1-9]|[1-2][0-9]|[3][0-7])([a-zA-Z]{5}[0-9]{4}[a-zA-Z]{1}[1-9a-zA-Z]{1}[zZ]{1}[0-9a-zA-Z]{1})+$",
                                              },
                                            ]
                                      )}
                                    </div>
                                  )}
                                {addressDetails &&
                                  addressDetails.length > 0 &&
                                  addressDetails[5].visible === 1 && (
                                    <div className="col-lg-6">
                                      <label
                                        htmlFor="addressLine1"
                                        style={{
                                          marginTop: "30px",
                                          color: getColorCodeBasedOnKey(
                                            "text_address_line_1_checkout"
                                          ),
                                        }}
                                      >
                                        {getValueFromLanguageObject(
                                          "str_address_line_1"
                                        )
                                          ? getValueFromLanguageObject(
                                              "str_address_line_1"
                                            )
                                          : "Address Line 1"}
                                        <span className="text-danger">
                                          {addressDetails[5].mandatory === 1 &&
                                            "*"}
                                        </span>
                                      </label>
                                      <input
                                        className="form-control mb-1"
                                        type="text"
                                        name="shippingAddressLine1"
                                        value={this.state.shippingAddressLine1}
                                        required={
                                          addressDetails[5].mandatory === 1
                                            ? true
                                            : false
                                        }
                                        onChange={
                                          this.onChangeOfShippingAddressLine1
                                        }
                                        placeholder={
                                          getValueFromLanguageObject(
                                            "str_enter_shipping_address_line_1"
                                          )
                                            ? getValueFromLanguageObject(
                                                "str_enter_shipping_address_line_1"
                                              )
                                            : "Enter Shipping Address Line 1"
                                        }
                                      />
                                      {this.validator.message(
                                        "shippingAddressLine1",
                                        this.state.shippingAddressLine1,
                                        addressDetails[5].mandatory === 1
                                          ? "required|min:2|max:256"
                                          : "min:2|max:256"
                                      )}
                                    </div>
                                  )}
                                {addressDetails &&
                                  addressDetails.length > 0 &&
                                  addressDetails[6].visible === 1 && (
                                    <div className="col-lg-6">
                                      <label
                                        htmlFor="addressLine2"
                                        style={{
                                          marginTop: "30px",
                                          color: getColorCodeBasedOnKey(
                                            "text_address_line_2_checkout"
                                          ),
                                        }}
                                      >
                                        {getValueFromLanguageObject(
                                          "str_address_line_2"
                                        )
                                          ? getValueFromLanguageObject(
                                              "str_address_line_2"
                                            )
                                          : "Address Line 2"}
                                        <span className="text-danger">
                                          {addressDetails[6].mandatory === 1 &&
                                            "*"}
                                        </span>
                                      </label>
                                      <input
                                        className="form-control mb-1"
                                        type="text"
                                        name="shippingAddressLine2"
                                        value={this.state.shippingAddressLine2}
                                        required={
                                          addressDetails[6].mandatory === 1
                                            ? true
                                            : false
                                        }
                                        onChange={
                                          this.onChangeOfShippingAddressLine2
                                        }
                                        placeholder={
                                          getValueFromLanguageObject(
                                            "str_enter_shipping_address_line_2"
                                          )
                                            ? getValueFromLanguageObject(
                                                "str_enter_shipping_address_line_2"
                                              )
                                            : "Enter Shipping Address Line 2"
                                        }
                                      />
                                      {this.validator.message(
                                        "shippingAddressLine2",
                                        this.state.shippingAddressLine2,
                                        addressDetails[6].mandatory === 1
                                          ? "required|min:2|max:256"
                                          : "min:2|max:256"
                                      )}
                                    </div>
                                  )}
                                {addressDetails &&
                                  addressDetails.length > 0 &&
                                  addressDetails[12].visible === 1 && (
                                    <div className="col-lg-6">
                                      <label
                                        htmlFor="zoneNumber"
                                        style={{
                                          marginTop: "30px",
                                          color: getColorCodeBasedOnKey(
                                            "text_zone_number_checkout"
                                          ),
                                        }}
                                      >
                                        {getValueFromLanguageObject(
                                          "str_zone_number"
                                        )
                                          ? getValueFromLanguageObject(
                                              "str_zone_number"
                                            )
                                          : "Zone Number/Name"}
                                        <span className="text-danger">
                                          {addressDetails[12].mandatory === 1 &&
                                            "*"}
                                        </span>
                                      </label>
                                      <input
                                        className="form-control mb-1"
                                        type="text"
                                        name="shippingZonenumber"
                                        value={this.state.shippingZonenumber}
                                        required={
                                          addressDetails[12].mandatory === 1
                                            ? true
                                            : false
                                        }
                                        onChange={
                                          this.onChangeOfShippingZonenumber
                                        }
                                        placeholder={
                                          getValueFromLanguageObject(
                                            "str_enter_shipping_zone_number"
                                          )
                                            ? getValueFromLanguageObject(
                                                "str_enter_shipping_zone_number"
                                              )
                                            : "Enter Shipping Zone Number/Name"
                                        }
                                      />
                                      {this.validator.message(
                                        "shippingZonenumber",
                                        this.state.shippingZonenumber,
                                        addressDetails[12].mandatory === 1
                                          ? "required|min:1|max:256"
                                          : "min:1|max:256"
                                      )}
                                    </div>
                                  )}
                                {addressDetails &&
                                  addressDetails.length > 0 &&
                                  addressDetails[13].visible === 1 && (
                                    <div className="col-lg-6">
                                      <label
                                        htmlFor="streetNumber"
                                        style={{
                                          marginTop: "30px",
                                          color: getColorCodeBasedOnKey(
                                            "text_street_number_checkout"
                                          ),
                                        }}
                                      >
                                        {getValueFromLanguageObject(
                                          "str_street_number"
                                        )
                                          ? getValueFromLanguageObject(
                                              "str_street_number"
                                            )
                                          : "Street Number/Name"}
                                        <span className="text-danger">
                                          {addressDetails[13].mandatory === 1 &&
                                            "*"}
                                        </span>
                                      </label>
                                      <input
                                        className="form-control mb-1"
                                        type="text"
                                        name="shippingStreetnumber"
                                        value={this.state.shippingStreetnumber}
                                        required={
                                          addressDetails[13].mandatory === 1
                                            ? true
                                            : false
                                        }
                                        onChange={
                                          this.onChangeOfShippingStreetnumber
                                        }
                                        placeholder={
                                          getValueFromLanguageObject(
                                            "str_enter_shipping_street_number"
                                          )
                                            ? getValueFromLanguageObject(
                                                "str_enter_shipping_street_number"
                                              )
                                            : "Enter Shipping Street Number/Name"
                                        }
                                      />
                                      {this.validator.message(
                                        "shippingStreetnumber",
                                        this.state.shippingStreetnumber,
                                        addressDetails[13].mandatory === 1
                                          ? "required|min:1|max:256"
                                          : "min:1|max:256"
                                      )}
                                    </div>
                                  )}
                                {addressDetails &&
                                  addressDetails.length > 0 &&
                                  addressDetails[14].visible === 1 && (
                                    <div className="col-lg-6">
                                      <label
                                        htmlFor="buildingNumber"
                                        style={{
                                          marginTop: "30px",
                                          color: getColorCodeBasedOnKey(
                                            "text_building_number_checkout"
                                          ),
                                        }}
                                      >
                                        {getValueFromLanguageObject(
                                          "str_building_number"
                                        )
                                          ? getValueFromLanguageObject(
                                              "str_building_number"
                                            )
                                          : "Building Number/Name"}
                                        <span className="text-danger">
                                          {addressDetails[14].mandatory === 1 &&
                                            "*"}
                                        </span>
                                      </label>
                                      <input
                                        className="form-control mb-1"
                                        type="text"
                                        name="shippingBuildingnumber"
                                        value={
                                          this.state.shippingBuildingnumber
                                        }
                                        required={
                                          addressDetails[14].mandatory === 1
                                            ? true
                                            : false
                                        }
                                        onChange={
                                          this.onChangeOfShippingBuildingnumber
                                        }
                                        placeholder={
                                          getValueFromLanguageObject(
                                            "str_enter_shipping_building_number"
                                          )
                                            ? getValueFromLanguageObject(
                                                "str_enter_shipping_building_number"
                                              )
                                            : "Enter Shipping Building Number/Name"
                                        }
                                      />
                                      {this.validator.message(
                                        "shippingBuildingnumber",
                                        this.state.shippingBuildingnumber,
                                        addressDetails[14].mandatory === 1
                                          ? "required|min:1|max:256"
                                          : "min:1|max:256"
                                      )}
                                    </div>
                                  )}
                                {addressDetails &&
                                  addressDetails.length > 0 &&
                                  addressDetails[15].visible === 1 && (
                                    <div className="col-lg-6">
                                      <label
                                        htmlFor="unitNumber"
                                        style={{
                                          marginTop: "30px",
                                          color: getColorCodeBasedOnKey(
                                            "text_unit_number_checkout"
                                          ),
                                        }}
                                      >
                                        {getValueFromLanguageObject(
                                          "str_unit_number"
                                        )
                                          ? getValueFromLanguageObject(
                                              "str_unit_number"
                                            )
                                          : "Unit Number/Name"}
                                        <span className="text-danger">
                                          {addressDetails[15].mandatory === 1 &&
                                            "*"}
                                        </span>
                                      </label>
                                      <input
                                        className="form-control mb-1"
                                        type="text"
                                        name="shippingUnitnumber"
                                        value={this.state.shippingUnitnumber}
                                        required={
                                          addressDetails[15].mandatory === 1
                                            ? true
                                            : false
                                        }
                                        onChange={
                                          this.onChangeOfShippingUnitnumber
                                        }
                                        placeholder={
                                          getValueFromLanguageObject(
                                            "str_enter_shipping_unit_number"
                                          )
                                            ? getValueFromLanguageObject(
                                                "str_enter_shipping_unit_number"
                                              )
                                            : "Enter Shipping Unit Number/Name"
                                        }
                                      />
                                      {this.validator.message(
                                        "shippingUnitnumber",
                                        this.state.shippingUnitnumber,
                                        addressDetails[15].mandatory === 1
                                          ? "required|min:1|max:256"
                                          : "min:1|max:256"
                                      )}
                                    </div>
                                  )}
                                {addressDetails &&
                                  addressDetails.length > 0 &&
                                  addressDetails[7].visible === 1 && (
                                    <div className="col-lg-6">
                                      <label
                                        htmlFor="landmark"
                                        style={{
                                          marginTop: "30px",
                                          color: getColorCodeBasedOnKey(
                                            "text_landmark_checkout"
                                          ),
                                        }}
                                      >
                                        {getValueFromLanguageObject(
                                          "str_landmark"
                                        )
                                          ? getValueFromLanguageObject(
                                              "str_landmark"
                                            )
                                          : "Landmark"}
                                        <span className="text-danger">
                                          {addressDetails[7].mandatory === 1 &&
                                            "*"}
                                        </span>
                                      </label>
                                      <input
                                        className="form-control mb-1"
                                        type="text"
                                        name="shippingLandmark"
                                        value={this.state.shippingLandmark}
                                        required={
                                          addressDetails[7].mandatory === 1
                                            ? true
                                            : false
                                        }
                                        onChange={
                                          this.onChangeOfShippingLandmark
                                        }
                                        placeholder={
                                          getValueFromLanguageObject(
                                            "str_enter_landmark"
                                          )
                                            ? getValueFromLanguageObject(
                                                "str_enter_landmark"
                                              )
                                            : "Enter Landmark"
                                        }
                                      />
                                      {this.validator.message(
                                        "shippingLandmark",
                                        this.state.shippingLandmark,
                                        addressDetails[7].mandatory === 1
                                          ? "required|min:2|max:256"
                                          : "min:2|max:256"
                                      )}
                                    </div>
                                  )}
                                {addressDetails &&
                                  addressDetails.length > 0 &&
                                  addressDetails[9].visible === 1 && (
                                    <div className="col-lg-6">
                                      <label
                                        htmlFor="countryName"
                                        style={{
                                          marginTop: "30px",
                                          color: getColorCodeBasedOnKey(
                                            "text_country_checkout"
                                          ),
                                        }}
                                      >
                                        {getValueFromLanguageObject(
                                          "str_country"
                                        )
                                          ? getValueFromLanguageObject(
                                              "str_country"
                                            )
                                          : "Country"}
                                        <span className="text-danger">
                                          {addressDetails[9].mandatory === 1 &&
                                            "*"}
                                        </span>
                                      </label>
                                      <select
                                        style={cityDropDownStyle}
                                        className="form-control custom-city-profile custom-city mb-0"
                                        name="shippingCountry"
                                        value={this.state.shippingCountry}
                                        onChange={
                                          this.onChangeOfShippingCountry
                                        }
                                        required={
                                          addressDetails[9].mandatory === 1
                                            ? true
                                            : false
                                        }
                                      >
                                        <option
                                          value=""
                                          style={{
                                            color: getColorCodeBasedOnKey(
                                              "text_select_country_checkout"
                                            ),
                                          }}
                                        >
                                          {getValueFromLanguageObject(
                                            "str_select_country"
                                          )
                                            ? getValueFromLanguageObject(
                                                "str_select_country"
                                              )
                                            : "Select Country"}
                                        </option>
                                        {countryList.length > 0 &&
                                          countryList.map((country) => {
                                            return (
                                              <option
                                                value={country.id}
                                                style={{
                                                  color: getColorCodeBasedOnKey(
                                                    "text_country_name_checkout"
                                                  ),
                                                }}
                                              >
                                                {country.name}
                                              </option>
                                            );
                                          })}
                                      </select>
                                      {this.validator.message(
                                        "shippingCountry",
                                        this.state.shippingCountry,
                                        addressDetails[9].mandatory === 1
                                          ? "required"
                                          : ""
                                      )}
                                    </div>
                                  )}
                                {addressDetails &&
                                  addressDetails.length > 0 &&
                                  addressDetails[10].visible === 1 && (
                                    <div className="col-lg-6">
                                      <label
                                        htmlFor="stateName"
                                        style={{
                                          marginTop: "30px",
                                          color: getColorCodeBasedOnKey(
                                            "text_state_checkout"
                                          ),
                                        }}
                                      >
                                        {getValueFromLanguageObject("str_state")
                                          ? getValueFromLanguageObject(
                                              "str_state"
                                            )
                                          : "State"}
                                        <span className="text-danger">
                                          {addressDetails[10].mandatory === 1 &&
                                            "*"}
                                        </span>
                                      </label>
                                      <select
                                        style={cityDropDownStyle}
                                        className="form-control custom-city-profile custom-city"
                                        name="shippingState"
                                        value={this.state.shippingState}
                                        onChange={this.onChangeOfShippingState}
                                        required={
                                          addressDetails[10].mandatory === 1
                                            ? true
                                            : false
                                        }
                                      >
                                        <option
                                          value=""
                                          style={{
                                            color: getColorCodeBasedOnKey(
                                              "text_select_state_checkout"
                                            ),
                                          }}
                                        >
                                          {getValueFromLanguageObject(
                                            "str_select_state"
                                          )
                                            ? getValueFromLanguageObject(
                                                "str_select_state"
                                              )
                                            : "Select State"}
                                        </option>
                                        {stateList.length > 0 &&
                                          stateList.map((state) => {
                                            return (
                                              <option
                                                value={state.id}
                                                style={{
                                                  color: getColorCodeBasedOnKey(
                                                    "text_state_name_checkout"
                                                  ),
                                                }}
                                              >
                                                {state.name}
                                              </option>
                                            );
                                          })}
                                      </select>
                                      {this.validator.message(
                                        "shippingState",
                                        this.state.shippingState,
                                        addressDetails[10].mandatory === 1
                                          ? "required"
                                          : ""
                                      )}
                                    </div>
                                  )}
                                {addressDetails &&
                                  addressDetails.length > 0 &&
                                  addressDetails[11].visible === 1 && (
                                    <div className="col-lg-6">
                                      <label
                                        htmlFor="cityName"
                                        style={{
                                          // marginTop: "30px",
                                          color: getColorCodeBasedOnKey(
                                            "text_city_checkout"
                                          ),
                                        }}
                                      >
                                        {getValueFromLanguageObject(
                                          "str_town_city"
                                        )
                                          ? getValueFromLanguageObject(
                                              "str_town_city"
                                            )
                                          : "Town/City"}
                                        <span className="text-danger">
                                          {addressDetails[11].mandatory === 1 &&
                                            "*"}
                                        </span>
                                      </label>
                                      <select
                                        style={cityDropDownStyle}
                                        className="form-control custom-city-profile custom-city mb-0"
                                        value={this.state.shippingCity}
                                        required={
                                          addressDetails[11].mandatory === 1
                                            ? true
                                            : false
                                        }
                                        onChange={this.onChangeOfShippingCity}
                                      >
                                        <option
                                          value=""
                                          style={{
                                            color: getColorCodeBasedOnKey(
                                              "text_select_city_checkout"
                                            ),
                                          }}
                                        >
                                          {getValueFromLanguageObject(
                                            "str_select_city"
                                          )
                                            ? getValueFromLanguageObject(
                                                "str_select_city"
                                              )
                                            : "Select City"}
                                        </option>
                                        {cityList.length > 0 &&
                                          cityList.map((city) => {
                                            return (
                                              <option
                                                value={city.id}
                                                style={{
                                                  color: getColorCodeBasedOnKey(
                                                    "text_city_name_checkout"
                                                  ),
                                                }}
                                              >
                                                {city.name}
                                              </option>
                                            );
                                          })}
                                      </select>
                                      {this.validator.message(
                                        "shippingCity",
                                        this.state.shippingCity,
                                        addressDetails[11].mandatory === 1
                                          ? "required"
                                          : ""
                                      )}
                                    </div>
                                  )}
                                {addressDetails &&
                                  addressDetails.length > 0 &&
                                  addressDetails[8].visible === 1 && (
                                    <div className="col-lg-6">
                                      <label
                                        htmlFor="postalCode"
                                        style={{
                                          // marginTop: "30px",
                                          color: getColorCodeBasedOnKey(
                                            "text_postal_code_checkout"
                                          ),
                                        }}
                                      >
                                        {getValueFromLanguageObject(
                                          "str_postal_code"
                                        )
                                          ? getValueFromLanguageObject(
                                              "str_postal_code"
                                            )
                                          : "Postal Code"}
                                        <span className="text-danger">
                                          {addressDetails[8].mandatory === 1 &&
                                            "*"}
                                        </span>
                                      </label>
                                      <input
                                        className="form-control mb-1"
                                        type="number"
                                        name="shippingPincode"
                                        value={this.state.shippingPincode}
                                        required={
                                          addressDetails[8].mandatory === 1
                                            ? true
                                            : false
                                        }
                                        onChange={
                                          this.onChangeOfShippingPincode
                                        }
                                        placeholder={
                                          getValueFromLanguageObject(
                                            "str_enter_postal_code"
                                          )
                                            ? getValueFromLanguageObject(
                                                "str_enter_postal_code"
                                              )
                                            : "Enter Postal Code"
                                        }
                                      />
                                      {this.validator.message(
                                        "shippingPincode",
                                        this.state.shippingPincode,
                                        addressDetails[8].mandatory === 1
                                          ? "required|integer"
                                          : "integer"
                                      )}
                                    </div>
                                  )}
                                {/* {this.renderSaveChanges()} */}
                              </div>
                            </form>
                          </div>
                        </div>
                        {/* shipping address end */}
                        <div className="col-md-12 mt-3 text-right">
                          <button
                            type="button"
                            className="btn-solid btn"
                            onClick={() => {
                              this.onClickOfSaveButton();
                            }}
                          >
                            {getValueFromLanguageObject("str_save")
                              ? getValueFromLanguageObject("str_save")
                              : "Save"}
                          </button>
                        </div>
                      </div>
                    </TabPanel>
                  </div>
                </div>
              </div>
            </section>
          </Tabs>
          {isLoading && <MainLoader />}
        </div>
        {showErrorMessageModal && (
          <ErrorMessageAnimationModal
            show={showErrorMessageModal}
            message={errorMessage}
            onClose={() => {
              this.setState({ showErrorMessageModal: false });
            }}
          />
        )}
        {showSuccessMessageModal && (
          <SuccessMessageAnimationModal
            show={showSuccessMessageModal}
            message={successMessage}
            onClose={() => {
              this.setState({ showSuccessMessageModal: false });
            }}
          />
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  baseCurrency: state.settings.baseCurrency,
});

export default connect(mapStateToProps, {
  notificationCounter: getNotificationCounter,
})(withRouter(MyProfile));
