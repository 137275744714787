import React, { Component } from "react";
import Lottie from "react-lottie";
import noProductFoundEditOrderJSON from "../json/noProductFoundEditOrderJSON.json";
import { getValueFromLanguageObject } from "../../store";
import { navigateTo } from "../../utils/utils";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

class NoProductFoundCartAnim extends Component {
  defaultOptions = {};

  constructor(props) {
    super(props);
    this.defaultOptions = {
      loop: true,
      autoplay: true,
      animationData: noProductFoundEditOrderJSON,
    };
  }

  render() {
    return (
      <>
        <section className="cart-section section-b-space">
          <div className="container">
            <div className="row">
              <div className="col-sm-12">
                <div>
                  <div className="col-sm-12 empty-cart-cls text-center animationSize-280-140">
                    <Lottie options={this.defaultOptions} />
                    <h3>
                      <strong>
                        {getValueFromLanguageObject("str_no_cart_item_found")
                          ? getValueFromLanguageObject("str_no_cart_item_found")
                          : "Cart is Empty"}
                      </strong>
                    </h3>
                    <h4>
                      {getValueFromLanguageObject(
                        "str_explore_more_shortlist_some_items"
                      )
                        ? getValueFromLanguageObject(
                            "str_explore_more_shortlist_some_items"
                          )
                        : "Explore more shortlist some items."}
                    </h4>
                    <button
                      className="btn btn-solid"
                      onClick={() => navigateTo(this.props, `/products`)}
                    >
                      Start Shopping
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }
}

const mapStateToProps = (state) => ({});
export default connect(mapStateToProps, {})(withRouter(NoProductFoundCartAnim));
