import React, { useEffect, useState } from "react";
import CurrencyMatrixTable from "../components/CurrencyMatrixTable";
import CurrencyBuySellDropdown from "../components/CurrencyBuySellDropdown";
import { getCurrencyListApi } from "../../services";
import { getCall } from "../../api/post";
import MainLoader from "../../animation/MainLoader";

const DashboardPage = () => {
  const [currencyList, setCurrencyList] = useState([]);
  const [isLoding, setIsLoading] = useState(false);
  useEffect(() => {
    async function getCurrencyList() {
      setIsLoading(true);
      const getCurrencyListUrl = getCurrencyListApi();
      const currencyListResponse = await getCall(getCurrencyListUrl);
      setIsLoading(false);
      setCurrencyList(currencyListResponse);
    }
    getCurrencyList();
  }, []);

  return (
    <>
      <div className="content d-flex flex-column xe">
        <div className="container xe-container">
          <div className="row flex-row-reverse">
            <div className="col-lg-4">
              <CurrencyBuySellDropdown
                className="card mb-xl-8"
                currencyList={currencyList}
              />
            </div>
            <div className="col-lg-8">
              <CurrencyMatrixTable
                className="card mb-5 mb-xl-8"
                currencyList={currencyList}
              />
            </div>
          </div>
        </div>
      </div>
      {isLoding && <MainLoader />}
    </>
  );
};
export default DashboardPage;
