import { connect } from "react-redux";
import React, { Component } from "react";
import Iframe from "react-iframe";
import Breadcrumb from "../../common/breadcrumb";
import {
  getValueFromLanguageObject,
  loadUserDetailsFromLocalStorage,
} from "../../../store";
import { getCall } from "../../../api/post";
import {
  getAboutUsUrl,
  getMasterDetailsIdUrl,
  getMerchantInformationUrl,
} from "../../../services";
import {
  clearLocalStorageReload,
  isOpenStoreIdExist,
  navigateTo,
} from "../../../utils/utils";
import { Helmet } from "react-helmet";

class AboutUs extends Component {
  state = {
    iFrameHeight: 0,
    iframeRef: null,
    text: "",
    masterDetailsObj: {},
    AppTitle: "",
  };

  componentDidMount() {
    const userDetails = loadUserDetailsFromLocalStorage();
    if (!isOpenStoreIdExist() && userDetails === undefined) {
      navigateTo(this.props, `/login`);
      return;
    }
    this.initMasterDetailsId().then(() => {
      this.initMerchantInfo();
    });
  }

  initMasterDetailsId = async () => {
    let masterDetailsObj = {};
    const publicUrl = window.location.pathname.split("/")[1];
    const masterDetailsIdUrl = getMasterDetailsIdUrl(publicUrl);
    const masterDetails = await getCall(masterDetailsIdUrl, []).then();
    if (masterDetails.error) {
      clearLocalStorageReload({ response: masterDetails });
    } else {
      if (masterDetails) {
        masterDetailsObj = masterDetails;
      }
    }
    this.setState({ masterDetailsObj: masterDetailsObj });
  };

  initMerchantInfo = async () => {
    const { masterDetailsObj } = this.state;
    const merchantInfoUrl = getMerchantInformationUrl(masterDetailsObj);
    const merchantInfo = await getCall(merchantInfoUrl, []);
    if (merchantInfo.error) {
      clearLocalStorageReload({ response: merchantInfo });
      this.setState({
        isMerchantGSTEnable: false,
        merchantInfo: {},
        AppTitle: "",
      });
    } else {
      this.setState({
        merchantInfo,
        AppTitle: merchantInfo.companyname,
        isMerchantGSTEnable: merchantInfo.enablegst === 0 ? false : true,
      });
    }
  };

  render() {
    const { masterDetailsObj, AppTitle } = this.state;
    return (
      <div>
        {/*SEO Support*/}
        <Helmet>
          <title>
            {AppTitle} |{" "}
            {getValueFromLanguageObject("str_about_us")
              ? getValueFromLanguageObject("str_about_us")
              : "About Us"}{" "}
          </title>
          <meta
            name="description"
            content="Multikart – Multipurpose eCommerce React Template is a multi-use React template. It is designed to go well with multi-purpose websites. Multikart Bootstrap 4 Template will help you run multiple businesses."
          />
        </Helmet>
        {/*SEO Support End */}
        <Breadcrumb
          title={
            getValueFromLanguageObject("str_about_us")
              ? getValueFromLanguageObject("str_about_us")
              : "About Us"
          }
        />
        <section className="section-b-space">
          <div className="container">
            <Iframe
              url={getAboutUsUrl(masterDetailsObj)}
              position="relative"
              width="100%"
              frameBorder="0"
              height="800"
              styles={{ height: "100%" }}
            />
          </div>
        </section>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, {})(AboutUs);
