import { combineReducers } from "redux";
import { IntlReducer as Intl, IntlProvider } from "react-redux-multilingual";

// Import custom components
import productReducer from "./products";
import cartReducer from "./cart-couter";
import settingsReducer from "./setting";
import languageReducer from "./languge-reducer";
import masterDetailsReducer from "./master-details-reducer"

const rootReducer = combineReducers({
  data: productReducer,
  cartCounter: cartReducer,
  settings: settingsReducer,
  language: languageReducer,
  masterDetails : masterDetailsReducer,
  Intl,
});

export default rootReducer;
