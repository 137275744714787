export const sadadPaymentConfigure = ({
  merchantId,
  orderId,
  sadadPaymentWebsite,
  totalAmount,
  userId,
  userEmail,
  userCellnumber,
  sadadPageLanguage,
  sadadCallbackUrl,
  transactionDate,
  productionDetails,
  sadadCheckoutVersion,
}) => {
  return {
    merchant_id: merchantId,
    ORDER_ID: orderId,
    WEBSITE: sadadPaymentWebsite,
    TXN_AMOUNT: totalAmount,
    CUST_ID: userId,
    EMAIL: userEmail,
    MOBILE_NO: userCellnumber,
    SADAD_WEBCHECKOUT_PAGE_LANGUAGE: sadadPageLanguage === "ar" ? "Arb" : "eng",
    CALLBACK_URL: sadadCallbackUrl,
    txnDate: transactionDate,
    productdetail: productionDetails,
    checksumhash: "",
    VERSION: sadadCheckoutVersion,
  };
};
