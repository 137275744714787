import React, { Component } from "react";
import { getCall } from "../../../api/post";
import {
  getImageUrl,
  getPlaceHolderImage,
  getSettingUrl,
  getMerchantInformationUrl,
  getPaymentDetailsUrl,
  getMasterDetailsIdUrl,
  getAddressDetailUrl,
} from "../../../services";
import { connect } from "react-redux";
import Moment from "react-moment";
import {
  getCommonCounter,
  getNotificationCounter,
  isInquiryOrCart,
} from "../../../actions";
import {
  loadUserDetailsFromLocalStorage,
  getValueFromLanguageObject,
  getCurrencySymbol,
  getColorCodeBasedOnKey,
} from "../../../store";
import MainLoader from "../../../animation/MainLoader";
import ErrorMessageAnimationModal from "../../../animation/ErrorMessageAnimationModal";
import RightTickMarkAnim from "../../../animation/RightTickMarkAnim";
import {
  clearLocalStorageReload,
  isOpenStoreIdExist,
  navigateTo,
  removeUserDetailAndOtherDetails,
} from "../../../utils/utils";
import { withRouter } from "react-router-dom";
import Lightbox from "react-image-lightbox";

class OrderDetails extends Component {
  state = {
    items: [],
    orderId: this.props.match.params.id,
    inquiryId: this.props.match.params.id,
    orderSummary: {},
    inquirySummary: {},
    isLoading: true,
    isMerchantGSTEnable: false,
    merchantInfo: {},
    paymentDetails: [],
    currentCatelogueType: {},
    showErrorMessageModal: false,
    errorMessage: "",
    masterDetailsObj: {},
    isFullScreenImage: false,
    fullScreenImagePath: "",
  };

  componentDidMount() {
    const userDetails = loadUserDetailsFromLocalStorage();
    if (!isOpenStoreIdExist() && userDetails === undefined) {
      navigateTo(this.props, `/login`);
      return;
    }
    if (isOpenStoreIdExist()) {
      navigateTo(this.props, `/`);
      return;
    }
    this.initMasterDetailsId().then(() => {
      const { masterDetailsObj } = this.state;
      this.props.isInquiryOrCart(masterDetailsObj);
      this.initOrderSummary().then(() => {
        this.setState({ isLoading: false });
      });

      this.initMerchantInfo();
      this.initPaymentDetails();
      this.initCatelogueSettings();
      this.props.counter();
      this.props.notificationCounter();
      this.props.isInquiryOrCart(masterDetailsObj);
      this.initAddressDetails();
    });
  }

  initMasterDetailsId = async () => {
    let masterDetailsObj = {};
    const publicUrl = window.location.pathname.split("/")[1];
    const masterDetailsIdUrl = getMasterDetailsIdUrl(publicUrl);
    const masterDetails = await getCall(masterDetailsIdUrl, []).then();
    if (masterDetails.error) {
      clearLocalStorageReload({ response: masterDetails });
    } else {
      if (masterDetails) {
        masterDetailsObj = masterDetails;
      }
    }
    this.setState({ masterDetailsObj: masterDetailsObj });
  };
  // initInquirySummary = async () => {
  //   const { orderId } = this.state;
  //   const inquirySummaryUrl = `orders/${orderId} `;
  //   const orderSummary = await getCall(inquirySummaryUrl, []);

  //   if (orderSummary.error) {
  //     this.loadErrorMessageAnimation(orderSummary.error.message);
  //     if (orderSummary.error.statusCode === 401) {
  //       localStorage.removeItem("userDetails");
  //       localStorage.removeItem("apiToken");
  //       this.props.history.push(`/login`);
  //       return;
  //     }
  //   } else {
  //     this.setState({ orderSummary });
  //   }
  // };
  initOrderSummary = async () => {
    const { orderId } = this.state;

    const orderSummaryUrl = `orders/${orderId}?filter[include]=orderdetails&filter[include]=productmedia`;
    const orderSummary = await getCall(orderSummaryUrl, []);

    if (orderSummary.error) {
      clearLocalStorageReload({ response: orderSummary });
      this.loadErrorMessageAnimation(orderSummary.error.message);
      if (orderSummary.error.statusCode === 401) {
        removeUserDetailAndOtherDetails();
        navigateTo(this.props, `/login`);
        return;
      }
    } else {
      this.setState({ orderSummary });
    }
  };

  initMerchantInfo = async () => {
    const { masterDetailsObj } = this.state;
    const merchantInfoUrl = getMerchantInformationUrl(masterDetailsObj);
    const merchantInfo = await getCall(merchantInfoUrl, []);
    if (merchantInfo.error) {
      clearLocalStorageReload({ response: merchantInfo });
      this.setState({ isMerchantGSTEnable: false, merchantInfo: {} });
    } else {
      this.setState({
        merchantInfo,
        isMerchantGSTEnable: merchantInfo.enablegst === 0 ? false : true,
      });
    }
  };

  initPaymentDetails = async () => {
    const { masterDetailsObj } = this.state;
    const paymentDetailsUrl = getPaymentDetailsUrl(masterDetailsObj);
    const paymentDetails = await getCall(paymentDetailsUrl, []);
    if (paymentDetails.error) {
      clearLocalStorageReload({ response: paymentDetails });
      this.loadErrorMessageAnimation(paymentDetails.error.message);
    } else {
      this.setState({ paymentDetails });
    }
  };

  initCatelogueSettings = async () => {
    const { masterDetailsObj } = this.state;
    const settingUrl = getSettingUrl(masterDetailsObj);
    const settingInfo = await getCall(settingUrl, []);
    if (settingInfo.error) {
      clearLocalStorageReload({ response: settingInfo });
    } else {
      let settings = {};
      settingInfo.forEach((setting) => {
        if (
          (setting.registerallow === "Catalogue_FMCG" ||
            setting.registerallow === "Catalogue_Jewellary" ||
            setting.registerallow === "Catalogue_Electronics" ||
            setting.registerallow === "Catalogue_FABRIC") &&
          setting.status === 1
        ) {
          settings = setting;
        }
      });
      this.setState({ currentCatelogueType: settings });
    }
  };

  initAddressDetails = async () => {
    const { masterDetailsObj } = this.state;
    const addressDetails = await getCall(
      getAddressDetailUrl(masterDetailsObj),
      []
    );

    if (addressDetails.error) {
      clearLocalStorageReload({ response: addressDetails });
    } else {
      if (addressDetails.length > 0) {
        this.setState({ addressDetails: JSON.parse(addressDetails[0].text) });
      }
    }
  };

  getTotalofCart(cartList) {
    let total = 0;
    if (cartList.orderdetail)
      for (let i = 0; i < cartList.orderdetail.length; i++) {
        total =
          total +
          cartList.orderdetail[i].amount * cartList.orderdetail[i].quantity;
      }
    return total;
  }

  getCGSTFromTotal = (total) => {
    const { merchantInfo } = this.state;
    return (total * merchantInfo.CGST) / 100;
  };
  getSGSTFromTotal = (total) => {
    const { merchantInfo } = this.state;
    return (total * merchantInfo.SGST) / 100;
  };

  getIGSTFromTotal = (total) => {
    const { merchantInfo } = this.state;
    return (total * merchantInfo.IGST) / 100;
  };

  getGrandTotal = (total, cgst, sgst, igst, shippingCharges) => {
    const { merchantInfo, currentCatelogueType } = this.state;
    var grandTotal = 0;
    if (merchantInfo.enablegst === 0) {
      grandTotal = total + shippingCharges;
      return grandTotal;
    }
    if (
      merchantInfo.enablegst === 1 &&
      currentCatelogueType &&
      currentCatelogueType.registerallow === "Catalogue_Jewellary"
    ) {
      grandTotal = total + shippingCharges;
      return grandTotal;
    }
    if (merchantInfo.countryname === "India") {
      grandTotal = total + cgst + sgst + shippingCharges;
    } else {
      grandTotal = total + igst + shippingCharges;
    }
    return grandTotal;
  };

  renderBillingAddress = () => {
    const { orderSummary, addressDetails } = this.state;
    if (
      orderSummary !== undefined &&
      orderSummary.address &&
      orderSummary.address !== undefined
    ) {
      const address = JSON.parse(orderSummary.address);
      if (Object.keys(address).length === 0) {
        return;
      }
      if (address !== undefined) {
        const billingAddress = address.billingaddress;
        return (
          <ul className="order-detail">
            {addressDetails &&
              addressDetails.length > 0 &&
              addressDetails[0].visible === 1 && (
                <li style={{ fontWeight: 550 }}>
                  {" "}
                  {getValueFromLanguageObject("str_name")
                    ? getValueFromLanguageObject("str_name")
                    : "Name"}{" "}
                  : {billingAddress.name}
                </li>
              )}

            {addressDetails &&
              addressDetails.length > 0 &&
              addressDetails[1].visible === 1 && (
                <li>
                  {" "}
                  {getValueFromLanguageObject("mobile_number")
                    ? getValueFromLanguageObject("mobile_number")
                    : "Mobile number"}{" "}
                  : {billingAddress.mobile}
                </li>
              )}

            {billingAddress.email &&
              addressDetails &&
              addressDetails.length > 0 &&
              addressDetails[2].visible === 1 && (
                <li>
                  {getValueFromLanguageObject("str_email_address")
                    ? getValueFromLanguageObject("str_email_address")
                    : "Email Address"}
                  : {billingAddress.email}
                </li>
              )}
            {billingAddress.companyname &&
              addressDetails &&
              addressDetails.length > 0 &&
              addressDetails[3].visible === 1 && (
                <li>
                  {" "}
                  {getValueFromLanguageObject("str_company_name")
                    ? getValueFromLanguageObject("str_company_name")
                    : "Company Name"}{" "}
                  : {billingAddress.companyname}
                </li>
              )}
            {billingAddress.gstin &&
              addressDetails &&
              addressDetails.length > 0 &&
              addressDetails[4].visible === 1 && (
                <li>
                  {" "}
                  {getValueFromLanguageObject("str_gstin")
                    ? getValueFromLanguageObject("str_gstin")
                    : "GSTIN"}{" "}
                  : {billingAddress.gstin}
                </li>
              )}

            {billingAddress.address1 &&
              addressDetails &&
              addressDetails.length > 0 &&
              addressDetails[5].visible === 1 && (
                <li>
                  {getValueFromLanguageObject("str_address")
                    ? getValueFromLanguageObject("str_address")
                    : "Address"}{" "}
                  : {billingAddress.address1},
                  {addressDetails &&
                    addressDetails.length > 0 &&
                    addressDetails[6].visible === 1 &&
                    billingAddress.address2 + `,`}
                  {addressDetails &&
                    addressDetails.length > 0 &&
                    addressDetails[12].visible === 1 &&
                    billingAddress.zonenumber + `,`}
                  {addressDetails &&
                    addressDetails.length > 0 &&
                    addressDetails[13].visible === 1 &&
                    billingAddress.streetnumber + `,`}
                  {addressDetails &&
                    addressDetails.length > 0 &&
                    addressDetails[14].visible === 1 &&
                    billingAddress.buildingnumber + `,`}
                  {addressDetails &&
                    addressDetails.length > 0 &&
                    addressDetails[15].visible === 1 &&
                    billingAddress.unitnumber + `,`}
                  {addressDetails &&
                    addressDetails.length > 0 &&
                    addressDetails[7].visible === 1 &&
                    billingAddress.landmark + `,`}
                </li>
              )}
            <li>
              {addressDetails &&
                addressDetails.length > 0 &&
                addressDetails[11].visible === 1 &&
                billingAddress.cityname + `,`}{" "}
              {addressDetails &&
                addressDetails.length > 0 &&
                addressDetails[10].visible === 1 &&
                billingAddress.statename}{" "}
              {addressDetails &&
                addressDetails.length > 0 &&
                addressDetails[9].visible === 1 &&
                billingAddress.countryname}{" "}
              -{" "}
              {addressDetails &&
                addressDetails.length > 0 &&
                addressDetails[8].visible === 1 &&
                billingAddress.zipcode}
            </li>
          </ul>
        );
      }
    }
  };

  renderShippingAddress = () => {
    const { orderSummary, addressDetails } = this.state;
    if (orderSummary !== undefined && orderSummary.address !== undefined) {
      const address = JSON.parse(orderSummary.address);
      if (Object.keys(address).length === 0) {
        return;
      }
      if (address !== undefined) {
        const shippingaddress = address.shippingaddress;
        return (
          <ul className="order-detail">
            {addressDetails &&
              addressDetails.length > 0 &&
              addressDetails[0].visible === 1 && (
                <li style={{ fontWeight: 550 }}>
                  {" "}
                  {getValueFromLanguageObject("str_name")
                    ? getValueFromLanguageObject("str_name")
                    : "Name"}{" "}
                  : {shippingaddress.name}
                </li>
              )}

            {addressDetails &&
              addressDetails.length > 0 &&
              addressDetails[1].visible === 1 && (
                <li>
                  {" "}
                  {getValueFromLanguageObject("mobile_number")
                    ? getValueFromLanguageObject("mobile_number")
                    : "Mobile number"}{" "}
                  : {shippingaddress.mobile}
                </li>
              )}

            {shippingaddress.email &&
              addressDetails &&
              addressDetails.length > 0 &&
              addressDetails[2].visible === 1 && (
                <li>
                  {getValueFromLanguageObject("str_email_address")
                    ? getValueFromLanguageObject("str_email_address")
                    : "Email Address"}
                  : {shippingaddress.email}
                </li>
              )}
            {shippingaddress.companyname &&
              addressDetails &&
              addressDetails.length > 0 &&
              addressDetails[3].visible === 1 && (
                <li>
                  {" "}
                  {getValueFromLanguageObject("str_company_name")
                    ? getValueFromLanguageObject("str_company_name")
                    : "Company Name"}{" "}
                  : {shippingaddress.companyname}
                </li>
              )}
            {shippingaddress.gstin &&
              addressDetails &&
              addressDetails.length > 0 &&
              addressDetails[4].visible === 1 && (
                <li>
                  {" "}
                  {getValueFromLanguageObject("str_gstin")
                    ? getValueFromLanguageObject("str_gstin")
                    : "GSTIN"}{" "}
                  : {shippingaddress.gstin}
                </li>
              )}

            {shippingaddress.address1 &&
              addressDetails &&
              addressDetails.length > 0 &&
              addressDetails[5].visible === 1 && (
                <li>
                  {getValueFromLanguageObject("str_address")
                    ? getValueFromLanguageObject("str_address")
                    : "Address"}{" "}
                  : {shippingaddress.address1 + `,`}
                  {addressDetails &&
                    addressDetails.length > 0 &&
                    addressDetails[6].visible === 1 &&
                    shippingaddress.address2 + `,`}
                  {addressDetails &&
                    addressDetails.length > 0 &&
                    addressDetails[12].visible === 1 &&
                    shippingaddress.zonenumber + `,`}
                  {addressDetails &&
                    addressDetails.length > 0 &&
                    addressDetails[13].visible === 1 &&
                    shippingaddress.streetnumber + `,`}
                  {addressDetails &&
                    addressDetails.length > 0 &&
                    addressDetails[14].visible === 1 &&
                    shippingaddress.buildingnumber + `,`}
                  {addressDetails &&
                    addressDetails.length > 0 &&
                    addressDetails[15].visible === 1 &&
                    shippingaddress.unitnumber + `,`}
                  {addressDetails &&
                    addressDetails.length > 0 &&
                    addressDetails[7].visible === 1 &&
                    shippingaddress.landmark + `,`}
                </li>
              )}
            <li>
              {addressDetails &&
                addressDetails.length > 0 &&
                addressDetails[11].visible === 1 &&
                shippingaddress.cityname + `,`}{" "}
              {addressDetails &&
                addressDetails.length > 0 &&
                addressDetails[10].visible === 1 &&
                shippingaddress.statename}{" "}
              {addressDetails &&
                addressDetails.length > 0 &&
                addressDetails[9].visible === 1 &&
                shippingaddress.countryname}{" "}
              -{" "}
              {addressDetails &&
                addressDetails.length > 0 &&
                addressDetails[8].visible === 1 &&
                shippingaddress.zipcode}
            </li>
          </ul>
        );
      }
    }
  };

  renderPriceBreakdownSJ = (other) => {
    const jwelleryData = JSON.parse(other);
    if (jwelleryData.jewelleryData) {
      return (
        <div className="col-12" style={{ textAlign: "end" }}>
          <h4>Price Breakdown</h4>
          <p>
            {jwelleryData.jewelleryData.name} :{" "}
            {jwelleryData.jewelleryData.weightintoprice}
          </p>
          {jwelleryData.jewelleryData.makingcharges &&
            jwelleryData.jewelleryData.makingcharges.map((makingcharge) => {
              return (
                <p>
                  {makingcharge.pricelable} : {makingcharge.amount}
                </p>
              );
            })}
        </div>
      );
    } else {
      return <div />;
    }
  };

  loadErrorMessageAnimation = (message) => {
    this.setState({ errorMessage: message, showErrorMessageModal: true });
  };

  renderPaymentStatus = (paymentstatus) => {
    const strPaymentStatus = getValueFromLanguageObject("str_payment_status")
      ? getValueFromLanguageObject("str_payment_status")
      : "Payment Status";
    const paymentStatus =
      paymentstatus === 1
        ? "PENDING"
        : paymentstatus === 2
        ? "SUCCESS"
        : "FAILED";
    return <li>{`${strPaymentStatus}: ${paymentStatus}`}</li>;
  };

  render() {
    const {
      orderSummary,
      isLoading,
      isMerchantGSTEnable,
      currentCatelogueType,
      paymentDetails,
      showErrorMessageModal,
      errorMessage,
      masterDetailsObj,
      isFullScreenImage,
      fullScreenImagePath,
    } = this.state;
    const { symbol, isInquiry } = this.props;

    return (
      <div>
        <section className="section-b-space light-layout">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="success-text">
                  {/* <i className="fa fa-check-circle" aria-hidden="true" /> */}
                  <RightTickMarkAnim />
                  <h2>
                    {getValueFromLanguageObject("str_thank_you")
                      ? getValueFromLanguageObject("str_thank_you")
                      : "thank you"}
                  </h2>

                  <div>
                    <p>
                      {!isInquiry
                        ? getValueFromLanguageObject(
                            "str_order_placed_successfully"
                          )
                          ? getValueFromLanguageObject(
                              "str_order_placed_successfully"
                            )
                          : "Order Placed Successfully"
                        : getValueFromLanguageObject(
                            "str_inquiry_placed_successfully"
                          )
                        ? getValueFromLanguageObject(
                            "str_inquiry_placed_successfully"
                          )
                        : "Inquiry Placed Successfully"}
                    </p>
                    <p>
                      {!isInquiry
                        ? getValueFromLanguageObject("order_id")
                          ? getValueFromLanguageObject("order_id")
                          : "Order ID"
                        : getValueFromLanguageObject("str_inquiry_no_")
                        ? getValueFromLanguageObject("str_inquiry_no_")
                        : "Inquiry ID"}
                      : {orderSummary.orderno}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="section-b-space">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="product-order order-success-sec">
                  <h4 className="f22">
                    {!isInquiry
                      ? getValueFromLanguageObject("str_your_order_details")
                        ? getValueFromLanguageObject("str_your_order_details")
                        : "Your order details"
                      : getValueFromLanguageObject("str_your_inquiry_details")
                      ? getValueFromLanguageObject("str_your_inquiry_details")
                      : "Your inquiry details"}
                  </h4>

                  {orderSummary.orderdetail &&
                    orderSummary.orderdetail.map((item, index) => {
                      return (
                        <div className="row product-order-detail" key={index}>
                          <div
                            className="col-12 col-sm-2"
                            onClick={() => {
                              const path =
                                item.products.productmedia.length > 0
                                  ? getImageUrl(
                                      item.products.productmedia[0].productname,
                                      masterDetailsObj
                                    )
                                  : getPlaceHolderImage();
                              this.setState({
                                isFullScreenImage: true,
                                fullScreenImagePath: path,
                              });
                            }}
                          >
                            <img
                              src={
                                item.products.productmedia.length > 0
                                  ? getImageUrl(
                                      item.products.productmedia[0].productname,
                                      masterDetailsObj
                                    )
                                  : getPlaceHolderImage()
                              }
                              alt=""
                              className="img-fluid"
                              onError={(e) => {
                                e.target.onerror = null;
                                e.target.src = getPlaceHolderImage();
                              }}
                            />
                          </div>
                          <div className="col-12 col-sm-4 order_detail">
                            <div>
                              <h4>
                                {getValueFromLanguageObject("str_product_name")
                                  ? getValueFromLanguageObject(
                                      "str_product_name"
                                    )
                                  : "product name"}
                              </h4>
                              <h5>{item.products.name}</h5>
                            </div>
                          </div>
                          <div className="col-6 col-sm-3 order_detail">
                            <div>
                              <h4>
                                {getValueFromLanguageObject("str_quantity")
                                  ? getValueFromLanguageObject("str_quantity")
                                  : "quantity"}
                              </h4>
                              <h5>{item.quantity}</h5>
                            </div>
                          </div>
                          {!isInquiry && (
                            <div className="col-6 col-sm-3 order_detail">
                              <div>
                                <h4>
                                  {getValueFromLanguageObject("str_price")
                                    ? getValueFromLanguageObject("str_price")
                                    : "price"}
                                </h4>
                                <h5>
                                  {symbol}
                                  {parseFloat(item.amount).toFixed(2)}
                                </h5>
                              </div>
                            </div>
                          )}
                          {currentCatelogueType &&
                            currentCatelogueType.registerallow ===
                              "Catalogue_Jewellary" &&
                            item.products.other &&
                            item.products.other &&
                            this.renderPriceBreakdownSJ(item.products.other)}
                        </div>
                      );
                    })}
                  {!isInquiry && (
                    <div className="total-sec">
                      <div className="final-total">
                        <h4>
                          {getValueFromLanguageObject("str_total")
                            ? getValueFromLanguageObject("str_total")
                            : "total"}
                          <span>
                            {`${symbol}${parseFloat(
                              this.getTotalofCart(orderSummary)
                            ).toFixed(2)}`}
                          </span>
                        </h4>
                        <h4>
                          {`+ ${
                            getValueFromLanguageObject("shipping_charges")
                              ? getValueFromLanguageObject("shipping_charges")
                              : "Shipping Charges"
                          }`}
                          <span>
                            {`${symbol}${parseFloat(
                              orderSummary.shippingprice
                                ? orderSummary.shippingprice
                                : 0
                            ).toFixed(2)}`}
                          </span>
                        </h4>
                        {isMerchantGSTEnable &&
                          currentCatelogueType &&
                          currentCatelogueType.registerallow !==
                            "Catalogue_Jewellary" &&
                          this.state.merchantInfo.countryname === "India" && (
                            <h4>
                              {`+ ${
                                getValueFromLanguageObject("str_cgst")
                                  ? getValueFromLanguageObject("str_cgst")
                                  : "CGST"
                              } (${this.state.merchantInfo.CGST}%) `}
                              <span>
                                {`${symbol}${parseFloat(
                                  this.getCGSTFromTotal(
                                    this.getTotalofCart(orderSummary)
                                  )
                                ).toFixed(2)}`}
                              </span>
                            </h4>
                          )}
                        {isMerchantGSTEnable &&
                          currentCatelogueType &&
                          currentCatelogueType.registerallow !==
                            "Catalogue_Jewellary" &&
                          this.state.merchantInfo.countryname === "India" && (
                            <h4>
                              {`+ ${
                                getValueFromLanguageObject("str_sgst")
                                  ? getValueFromLanguageObject("str_sgst")
                                  : "SGST"
                              } (${this.state.merchantInfo.SGST}%) `}
                              <span>
                                {symbol}
                                {parseFloat(
                                  this.getSGSTFromTotal(
                                    this.getTotalofCart(orderSummary)
                                  )
                                ).toFixed(2)}
                              </span>
                            </h4>
                          )}
                        {isMerchantGSTEnable &&
                          currentCatelogueType &&
                          currentCatelogueType.registerallow !==
                            "Catalogue_Jewellary" &&
                          this.state.merchantInfo.countryname !== "India" && (
                            <h4>
                              {`+ ${
                                getValueFromLanguageObject("str_igst")
                                  ? getValueFromLanguageObject("str_igst")
                                  : "IGST"
                              } (${this.state.merchantInfo.IGST}%) `}

                              <span>
                                {`${symbol}${parseFloat(
                                  this.getIGSTFromTotal(
                                    this.getTotalofCart(orderSummary)
                                  )
                                ).toFixed(2)}`}
                              </span>
                            </h4>
                          )}
                        <h4>
                          {`- ${
                            getValueFromLanguageObject("discount")
                              ? getValueFromLanguageObject("discount")
                              : "Discount"
                          }`}
                          <span>{`${symbol}${parseFloat(0).toFixed(2)}`}</span>
                        </h4>
                        <h4>
                          {getValueFromLanguageObject("amount_payable")
                            ? getValueFromLanguageObject("amount_payable")
                            : "Amount Payable"}
                          <span>
                            {symbol}
                            {parseFloat(
                              this.getGrandTotal(
                                this.getTotalofCart(orderSummary),
                                this.getCGSTFromTotal(
                                  this.getTotalofCart(orderSummary)
                                ),
                                this.getSGSTFromTotal(
                                  this.getTotalofCart(orderSummary)
                                ),
                                this.getIGSTFromTotal(
                                  this.getTotalofCart(orderSummary)
                                ),
                                orderSummary.shippingprice
                              )
                            ).toFixed(2)}
                          </span>
                        </h4>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className="col-lg-12">
                <div className="row order-success-sec">
                  <div className="col-sm-12 col-md-6 col-lg-4 mb-5">
                    <h4 className="f22">
                      {getValueFromLanguageObject("str_summery")
                        ? getValueFromLanguageObject("str_summery")
                        : "summery"}
                    </h4>
                    <ul className="order-detail">
                      <li>
                        {!isInquiry
                          ? getValueFromLanguageObject("order_id")
                            ? getValueFromLanguageObject("order_id")
                            : "Order ID"
                          : getValueFromLanguageObject("str_inquiry_id")
                          ? getValueFromLanguageObject("str_inquiry_id")
                          : "Inquiry ID"}
                        : {orderSummary.orderno}
                      </li>

                      <li>
                        {!isInquiry
                          ? getValueFromLanguageObject("str_order_date")
                            ? getValueFromLanguageObject("str_order_date")
                            : "Order Date"
                          : getValueFromLanguageObject("str_inquiry_date")
                          ? getValueFromLanguageObject("str_inquiry_date")
                          : "Inquiry Date"}
                        :
                        <Moment format="DD-MMM-YYYY">
                          {orderSummary.date}
                        </Moment>
                      </li>

                      {!isInquiry && (
                        <li>
                          {getValueFromLanguageObject("str_delivery_date")
                            ? getValueFromLanguageObject("str_delivery_date")
                            : "Delivery Date"}
                          :
                          <Moment format="DD-MMM-YYYY">
                            {orderSummary.deliverydate}
                          </Moment>
                        </li>
                      )}
                      {!isInquiry && (
                        <li>
                          {getValueFromLanguageObject("str_order_total")
                            ? getValueFromLanguageObject("str_order_total")
                            : "Order Total"}
                          : {symbol}
                          {parseFloat(
                            this.getGrandTotal(
                              this.getTotalofCart(orderSummary),
                              this.getCGSTFromTotal(
                                this.getTotalofCart(orderSummary)
                              ),
                              this.getSGSTFromTotal(
                                this.getTotalofCart(orderSummary)
                              ),
                              this.getIGSTFromTotal(
                                this.getTotalofCart(orderSummary)
                              ),
                              orderSummary.shippingprice
                            )
                          ).toFixed(2)}
                        </li>
                      )}
                      {!isInquiry && (
                        <div>
                          <li>
                            <b>
                              {getValueFromLanguageObject("str_order_notes")
                                ? getValueFromLanguageObject("str_order_notes")
                                : "Order Notes"}
                              :
                            </b>
                          </li>
                          <li>{orderSummary.description || "-"}</li>
                        </div>
                      )}
                    </ul>
                  </div>

                  <div className="col-sm-12 col-md-6 col-lg-4 mb-5">
                    <h4 className="f22">
                      {getValueFromLanguageObject("str_customer_detail")
                        ? getValueFromLanguageObject("str_customer_detail")
                        : "Customer Details"}
                    </h4>
                    <ul className="order-detail">
                      <li>
                        {getValueFromLanguageObject("str_name")
                          ? getValueFromLanguageObject("str_name")
                          : "Name"}
                        :{" "}
                        {orderSummary.orderuser &&
                          orderSummary.orderuser.username}
                      </li>
                      <li
                        style={{
                          color: getColorCodeBasedOnKey(
                            "text_order_id_order_detail"
                          ),
                        }}
                      >
                        {getValueFromLanguageObject("mobile_number")
                          ? getValueFromLanguageObject("mobile_number")
                          : "Mobile Number"}
                        :{" "}
                        {orderSummary.orderuser &&
                          orderSummary.orderuser.cellnumber}
                      </li>

                      {orderSummary.orderuser &&
                        orderSummary.orderuser.cityname && (
                          <li>
                            {getValueFromLanguageObject("str_city")
                              ? getValueFromLanguageObject("str_city")
                              : "City"}
                            : {orderSummary.orderuser.cityname}
                          </li>
                        )}
                    </ul>
                  </div>
                  {!isInquiry &&
                    paymentDetails &&
                    paymentDetails.length > 0 &&
                    paymentDetails[0].status === 1 && (
                      <div className="col-sm-12 col-md-6 col-lg-4 mb-5">
                        <h4 className="f22">
                          {getValueFromLanguageObject("payment_details")
                            ? getValueFromLanguageObject("payment_details")
                            : "Payment Details"}
                        </h4>

                        <ul className="order-detail">
                          <li>
                            {getValueFromLanguageObject("pay_via")
                              ? getValueFromLanguageObject("pay_via")
                              : "Pay Via"}
                            :{" "}
                            {orderSummary.paymentDetail &&
                            JSON.parse(orderSummary.paymentDetail).pay_via !==
                              "COD"
                              ? JSON.parse(orderSummary.paymentDetail).pay_via
                              : "Cash On Delivery(COD)"}
                          </li>
                          <li>
                            {getValueFromLanguageObject("payment_id")
                              ? getValueFromLanguageObject("payment_id")
                              : "Payment ID"}
                            :{" "}
                            {orderSummary.paymentDetail &&
                            JSON.parse(orderSummary.paymentDetail)
                              .transaction_no !== ""
                              ? JSON.parse(orderSummary.paymentDetail)
                                  .transaction_no
                              : "N/A"}
                          </li>
                          {orderSummary.paymentstatus &&
                            this.renderPaymentStatus(
                              orderSummary.paymentstatus
                            )}
                        </ul>
                      </div>
                    )}
                  {!isInquiry && (
                    <div className="col-sm-12 col-md-6 col-lg-4 mb-5">
                      <h4 className="f22">
                        {getValueFromLanguageObject("shipping_address")
                          ? getValueFromLanguageObject("shipping_address")
                          : "shipping address"}
                      </h4>
                      {this.renderShippingAddress()}
                    </div>
                  )}

                  {!isInquiry && (
                    <div className="col-sm-12 col-md-6 col-lg-4 mb-5">
                      <h4 className="f22">
                        {getValueFromLanguageObject("billing_address")
                          ? getValueFromLanguageObject("billing_address")
                          : "billing address"}
                      </h4>
                      {this.renderBillingAddress()}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </section>
        {isLoading && <MainLoader />}
        {showErrorMessageModal && (
          <ErrorMessageAnimationModal
            show={showErrorMessageModal}
            message={errorMessage}
            onClose={() => {
              this.setState({ showErrorMessageModal: false });
            }}
          />
        )}
        {isFullScreenImage && (
          <Lightbox
            mainSrc={fullScreenImagePath}
            onCloseRequest={() =>
              this.setState({
                isFullScreenImage: false,
                fullScreenImagePath: "",
              })
            }
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  symbol: getCurrencySymbol(),
  isInquiry: state.settings.isInquiry,
});

export default connect(mapStateToProps, {
  counter: getCommonCounter,
  notificationCounter: getNotificationCounter,
  isInquiryOrCart: isInquiryOrCart,
})(withRouter(OrderDetails));
