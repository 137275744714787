import { Modal } from "antd";
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

class SadadPaymentForm extends Component {
  componentDidMount() {}

  render() {
    const {
      sadadPaymentChecksum,
      sadadPaymentConfig,
      isOpenPayWithSadad,
    } = this.props;
    const {
      merchant_id,
      ORDER_ID,
      WEBSITE,
      TXN_AMOUNT,
      CUST_ID,
      EMAIL,
      MOBILE_NO,
      SADAD_WEBCHECKOUT_PAGE_LANGUAGE,
      CALLBACK_URL,
      txnDate,
      productdetail,
      checksumhash,
      VERSION,
    } = sadadPaymentConfig;

    const [product] = productdetail || [];
    const { order_id, amount, quantity } = product || {};

    return (
      <>
        <div>
          <form
            action={process.env.SADAD_IFRAME_URL}
            method="post"
            id="paymentform"
            name="paymentform"
            data-link={process.env.SADAD_IFRAME_URL}
            target="_self"
          >
            <div className="form-group">
              <input
                type="hidden"
                name="merchant_id"
                id="merchant_id"
                value={merchant_id}
              />
              {/* <input
              type="hidden"
              name="secret_key"
              id="secret_key"
              value="S3DeKpSFip3G8Epl"
            /> */}

              <input
                type="hidden"
                name="ORDER_ID"
                id="ORDER_ID"
                value={ORDER_ID}
              />
              <input
                type="hidden"
                name="WEBSITE"
                id="WEBSITE"
                value={WEBSITE}
              />
              <input
                type="hidden"
                name="TXN_AMOUNT"
                id="TXN_AMOUNT"
                value={TXN_AMOUNT}
              />
              <input
                type="hidden"
                name="CUST_ID"
                id="CUST_ID"
                value={CUST_ID}
              />

              <input type="hidden" name="EMAIL" id="EMAIL" value={EMAIL} />
              <input
                type="hidden"
                name="MOBILE_NO"
                id="MOBILE_NO"
                value={MOBILE_NO}
              />
              <input
                type="hidden"
                name="SADAD_WEBCHECKOUT_PAGE_LANGUAGE"
                id="SADAD_WEBCHECKOUT_PAGE_LANGUAGE"
                value={SADAD_WEBCHECKOUT_PAGE_LANGUAGE}
              />
              <input
                type="hidden"
                name="CALLBACK_URL"
                id="CALLBACK_URL"
                value={CALLBACK_URL}
              />

              <input
                type="hidden"
                name="txnDate"
                id="txnDate"
                value={txnDate}
              />

              <input
                type="hidden"
                name="productdetail[0][order_id]"
                value={order_id}
              />

              <input
                type="hidden"
                name="productdetail[0][itemname]"
                value="Product"
              />

              <input
                type="hidden"
                name="productdetail[0][amount]"
                value={amount}
              />

              <input
                type="hidden"
                name="productdetail[0][quantity]"
                value={quantity}
              />

              {/* <input
                type="hidden"
                name="productdetail[0][type]"
                value="line_item"
              /> */}

              <input
                type="hidden"
                name="checksumhash"
                value={sadadPaymentChecksum}
              />

              <input type="hidden" name="VERSION" value={VERSION} />
            </div>
          </form>
          <Modal
            open={isOpenPayWithSadad}
            showCloseIcon={true}
            classNames={{
              overlay: "customOverlay",
              modal: "customSadadModal",
            }}
            closeOnOverlayClick={true}
            closeOnEsc={true}
            onClose={() => {
              this.setState({ isOpenPayWithSadad: false });
            }}
          >
            <div className="modal-dialog modal-lg">
              <iframe
                title="modal"
                name="myIframe"
                id="myIframe"
                border="0"
                class="not_hide_sadad"
                frameborder="0"
                scrolling="no"
                height="500px"
                width="500px"
                target="_self"
              />
            </div>
          </Modal>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({});

export default connect(
  mapStateToProps,
  {}
)(withRouter(SadadPaymentForm));
