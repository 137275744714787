import React, { Fragment } from "react";
import Select, { components } from "react-select";
const Menu = (props) => {
  return (
    <Fragment>
      <components.Menu {...props}>
        <div>
          <div>{props.children}</div>
        </div>
      </components.Menu>
    </Fragment>
  );
};
const Option = (props) => {
  return (
    <Fragment>
      <components.Option {...props}>{props.children}</components.Option>
    </Fragment>
  );
};
const CustomSelect = ({
  options,
  onSelect,
  placeholder,
  selected,
  disabled,
}) => {
  return (
    <div>
      <Select
        options={options}
        components={{ Menu, Option }}
        onChange={onSelect}
        placeholder={placeholder}
        value={selected}
        // defaultInputValue={selected}
        // defaultValue={"INR"}
        isDisabled={disabled}
        isSearchable={false}
      />
    </div>
  );
};
export default CustomSelect;
