import React, { Component } from "react";
import Slider from "react-slick";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { isInquiryOrCart } from "../../actions";
import EllipsisText from "react-ellipsis-text";
import {
  getImageUrl,
  getPlaceHolderImage,
  getMasterDetailsIdUrl,
} from "../../services";
import {
  getValueFromLanguageObject,
  getCurrencySymbol,
  getColorCodeBasedOnKey,
} from "../../store";
import { getCall } from "../../api/post";
import { clearLocalStorageReload } from "../../utils/utils";

class NewProduct extends Component {
  state = {
    masterDetailsObj: {},
  };

  componentDidMount() {
    this.initMasterDetailsId();
  }

  initMasterDetailsId = async () => {
    let masterDetailsObj = {};
    const publicUrl = window.location.pathname.split("/")[1];
    const masterDetailsIdUrl = getMasterDetailsIdUrl(publicUrl);
    const masterDetails = await getCall(masterDetailsIdUrl, []).then();
    if (masterDetails.error) {
      clearLocalStorageReload({ response: masterDetails });
    } else {
      if (masterDetails) {
        masterDetailsObj = masterDetails;
      }
    }
    this.setState({ masterDetailsObj: masterDetailsObj });
  };

  render() {
    const { newProducts, symbol, isInquiry } = this.props;
    const { masterDetailsObj } = this.state;
    var arrays = [];
    while (newProducts.length > 0) {
      arrays.push(newProducts.splice(0, 3));
    }
    return (
      <div className="theme-card">
        <h5 className="title-border">
          {getValueFromLanguageObject("str_new_product")
            ? getValueFromLanguageObject("str_new_product")
            : "new product"}
        </h5>

        <Slider className="offer-slider slide-1">
          {arrays.map((products, index) => (
            <div key={index}>
              {products.map((product, i) => (
                <div className="media" key={i}>
                  <Link to={`/singleproduct/${product.id}`}>
                    <div style={{ height: "120px", width: "120px" }}>
                      <img
                        style={{ height: "100%", width: "100%" }}
                        className="img-fluid"
                        src={
                          product.productmedia.length > 0
                            ? getImageUrl(
                                product.productmedia[0].productname,
                                masterDetailsObj
                              )
                            : getPlaceHolderImage()
                        }
                        alt=""
                        onError={(e) => {
                          e.target.onerror = null;
                          e.target.src = getPlaceHolderImage();
                        }}
                      />
                    </div>
                  </Link>
                  <div className="media-body align-self-center">
                    <Link to={`/singleproduct/${product.id}`}>
                      <h6>
                        <EllipsisText text={product.name} length={25} />
                      </h6>
                    </Link>
                    {!isInquiry && (
                      <h4>
                        {`${symbol}${parseFloat(product.price).toFixed(2)}`}
                      </h4>
                    )}
                  </div>
                </div>
              ))}
            </div>
          ))}
        </Slider>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  isInquiry: state.settings.isInquiry,
  symbol: getCurrencySymbol(),
});

export default connect(
  mapStateToProps,
  { isInquiryOrCart: isInquiryOrCart },
  null
)(NewProduct);
