// Get Products
export const FETCH_PRODUCTS_BEGIN = "FETCH_PRODUCTS_BEGIN";
export const RECEIVE_PRODUCTS = "RECEIVE_PRODUCTS";
export const FETCH_SINGLE_PRODUCT = "FETCH_SINGLE_PRODUCT";

// FILTERS
export const FILTER_BRAND = "FILTER_BRAND";
export const FILTER_COLOR = "FILTER_COLOR";
export const FILTER_PRICE = "FILTER_PRICE";
export const SORT_BY = "SORT_BY";

export const CHANGE_CURRENCY = "CHANGE_CURRENCY";

// Cart
export const ADD_TO_CART = "ADD_TO_CART";
export const REMOVE_FROM_CART = "REMOVE_FROM_CART";
export const INCREMENT_QTY = "INCREMENT_QTY";
export const DECREMENT_QTY = "DECREMENT_QTY";
export const COMMON_COUNTER = "COMMON_COUNTER";
export const UPDATE_QTY = "UPDATE_QTY";
export const NOTIFICATION_COUNTER = "NOTIFICATION_COUNTER";

// Settings
export const IS_REGISTER_ALLOW = "IS_REGISTER_ALLOW";
export const IS_INQUIRY = "IS_INQUIRY";
export const CURRENT_LANGUGAE = "CURRENT_LANGUGAE";
export const MASTER_DETAILS_ID = "MASTER_DETAILS_ID";
export const BASE_CURRENCY = "BASE_CURRENCY";

// WishList
export const ADD_TO_WISHLIST = "ADD_TO_WISHLIST";
export const REMOVE_FROM_WISHLIST = "REMOVE_FROM_WISHLIST";

// Compare
export const ADD_TO_COMPARE = "ADD_TO_COMPARE";
export const REMOVE_FROM_COMPARE = "REMOVE_FROM_COMPARE";

// CheckOut Process
export const CHECKOUT_REQUEST = "CHECKOUT_REQUEST";
export const CHECKOUT_SUCCESS = "CHECKOUT_SUCCESS";
export const CHECKOUT_FAILURE = "CHECKOUT_FAILURE";
